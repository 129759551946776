import { login, logout } from '../customer/actions';
import {
  openMenu,
  openDrawer,
  closeDrawer,
  closeMenu,
  addToast,
  removeToast,
  setLastShippingAddress,
  removeLastShippingAddress,
  dismissMessagePopup,
  addDismissableNotification,
  removeDismissableNotification,
  addLoginMessage,
  removeLoginMessage,
  setCartIsLoading,
  setHasSyncedOutdatedCart,
} from './actions';
import { createReducer } from 'typesafe-actions';
import { ToastPayload } from 'types/types';
import { CartAddressInterface, DismissableNotification } from 'types/types';

interface State {
  openDrawer: null | string;
  openMenu: null | string;
  dismissedMessagesBySection: string;
  toasts: ToastPayload[];
  lastShippingAddress: CartAddressInterface | null;
  notifications: DismissableNotification[];
  loginMessage: null | string;
  isCartLoading: boolean;
  hasSyncedOutdatedCart: boolean;
}

const initialState: State = {
  openDrawer: null,
  openMenu: null,
  dismissedMessagesBySection: '',
  toasts: [],
  lastShippingAddress: null,
  notifications: [],
  loginMessage: null,
  isCartLoading: false,
  hasSyncedOutdatedCart: false,
};

const globalReducer = createReducer(initialState)
  /**
   * Menu
   */
  .handleAction(openMenu, (state, { payload }) => ({
    ...state,
    openMenu: payload,
  }))
  .handleAction(closeMenu, state => ({
    ...state,
    openMenu: null,
  }))
  /**
   * Drawer
   */
  .handleAction(openDrawer, (state, { payload }) => ({
    ...state,
    openDrawer: payload,
  }))
  .handleAction(closeDrawer, state => ({
    ...state,
    openDrawer: null,
    loginMessage: null,
  }))
  /**
   * Toasts
   */
  .handleAction(addToast, (state, { payload }) => ({
    ...state,
    toasts: [...state.toasts, payload],
  }))
  .handleAction(removeToast, (state, { payload }) => ({
    ...state,
    toasts: state.toasts.filter((_t, idx) => idx !== payload),
  }))
  /**
   * Dismissable Notifications
   */
  .handleAction(addDismissableNotification, (state, { payload }) => {
    const unifier: Record<string, DismissableNotification> = {};
    [...state.notifications, payload].forEach(message => {
      unifier[message.id] = message;
    });

    const unified = Object.values(unifier);
    unified.sort((a, b) => (a.priority > b.priority ? 1 : -1));

    return {
      ...state,
      notifications: unified,
    };
  })
  .handleAction(removeDismissableNotification, (state, { payload }) => ({
    ...state,
    notifications: state.notifications.filter(
      notifier => notifier.id !== payload
    ),
  }))
  /**
   * Last shipping address
   */
  .handleAction(setLastShippingAddress, (state, { payload }) => ({
    ...state,
    lastShippingAddress: payload,
  }))
  .handleAction(removeLastShippingAddress, state => ({
    ...state,
    lastShippingAddress: null,
  }))
  /**
   * Misc
   */
  .handleAction(setCartIsLoading, (state, { payload }) => ({
    ...state,
    isCartLoading: payload,
  }))
  .handleAction(setHasSyncedOutdatedCart, state => ({
    ...state,
    hasSyncedOutdatedCart: true,
  }))
  .handleAction(addLoginMessage, (state, { payload }) => ({
    ...state,
    loginMessage: payload,
  }))
  .handleAction(removeLoginMessage, state => ({
    ...state,
    loginMessage: null,
  }))
  .handleAction(dismissMessagePopup, (state, { payload }) => ({
    ...state,
    dismissedMessagesBySection: payload,
  }))
  .handleAction(login, state => ({
    ...state,
    loginMessage: null,
  }))
  .handleAction(logout, state => ({
    ...state,
    openMenu: null,
  }));

export default globalReducer;
export type globalState = ReturnType<typeof globalReducer>;
