import { useEffect } from 'react';
import { RootState } from 'typesafe-actions';
import { forgetCart } from '../../../redux/cart/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';

const ClearOldCart = () => {
  const { pathname } = useRouter();
  const dispatch = useDispatch();

  const cartId = useSelector((state: RootState) => state.cart.cart.id);
  const orderLastCartId = useSelector(
    (state: RootState) => state.orderCart.lastCartId
  );

  useEffect(() => {
    // don't clear the cart on checkout coz it will re-render and show the empty cart message
    if (!pathname.match('/checkout') && cartId === orderLastCartId) {
      dispatch(forgetCart());
    }
  }, [dispatch, cartId, orderLastCartId, pathname]);

  return null;
};

const CartInspector = () => <ClearOldCart />;

export default CartInspector;
