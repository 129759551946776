import { Field, Form } from 'react-final-form';
import createDecorator from 'final-form-focus';
import { Grid } from 'components/box';
import Button from 'components/button';
import LabeledInput from 'components/form/input';
import isEmail from 'sane-email-validation';
import { useSubscribeUser } from 'hooks/newsletter';
import { useEffect } from 'react';
import { useCustomer } from 'hooks/customer';
import { Text } from 'components/typography';

const focusOnErrors = createDecorator();

interface FormInput {
  firstName: string;
  lastName: string;
  email: string;
  signupLocation: string;
}

interface GridColumns {
  desktop: string;
  mobile: string;
}

const SubscribeForm = ({
  gridColumnLayout,
  buttonColor = 'primary',
  buttonText = 'subscribe',
  subscriptionComplete,
  signupLocation,
}: {
  gridColumnLayout?: GridColumns;
  buttonColor?: string;
  buttonText?: string;
  subscriptionComplete?: () => void;
  signupLocation?: string;
}) => {
  const gridLayoutMobile = gridColumnLayout ? gridColumnLayout.mobile : 'auto';
  const gridLayoutNonMobile = gridColumnLayout
    ? gridColumnLayout.desktop
    : 'auto auto auto auto';

  const currentCustomer = useCustomer();

  const [
    subscribeUserRequest,
    { loading: subscribing, error: subscribeUserError, data, completed },
  ] = useSubscribeUser();
  useEffect(() => {
    if (completed) {
      subscriptionComplete && subscriptionComplete();
    }
  });

  return (
    <Form<FormInput>
      p={0}
      decorators={[focusOnErrors]}
      initialValues={{
        firstName: currentCustomer?.firstName || '',
        lastName: currentCustomer?.lastName || '',
        email: currentCustomer?.email || '',
        signupLocation: signupLocation || '',
      }}
      onSubmit={subscribeUserRequest}
    >
      {({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit} noValidate>
          <Grid
            gridTemplateColumns={[gridLayoutMobile, gridLayoutNonMobile]}
            gridColumnGap={2}
            py={signupLocation === 'HEADER' ? ['15px', '30%'] : undefined}
            px={signupLocation === 'HEADER' ? '20px' : undefined}
            height="100%"
          >
            <Field
              name="firstName"
              validate={value => {
                if (!value) {
                  return 'Enter your first name';
                }
                if (value.length < 3) {
                  return 'Please enter a name of at least 3 characters';
                }
              }}
            >
              {({ input, meta }) => (
                <LabeledInput
                  {...input}
                  type="text"
                  background="white"
                  invalid={meta.error && meta.touched}
                  error={meta.touched && meta.error}
                  placeholder="First Name"
                  labelColor={
                    signupLocation === 'HEADER' ? 'darkGrey' : 'white'
                  }
                  label="First Name"
                />
              )}
            </Field>

            <Field
              name="lastName"
              validate={value => {
                if (!value) {
                  return 'Enter your last name';
                }
                if (value.length < 3) {
                  return 'Please enter a name of at least 3 characters';
                }
              }}
            >
              {({ input, meta }) => (
                <LabeledInput
                  {...input}
                  type="text"
                  background="white"
                  invalid={meta.error && meta.touched}
                  error={meta.touched && meta.error}
                  placeholder="Last Name"
                  labelColor={
                    signupLocation === 'HEADER' ? 'darkGrey' : 'white'
                  }
                  label="Last Name"
                />
              )}
            </Field>

            <Field
              name="email"
              validate={value => {
                if (!value) {
                  return 'Please enter your email address';
                }
                if (!isEmail(value)) {
                  return 'Email address is invalid';
                }
              }}
            >
              {({ input, meta }) => (
                <LabeledInput
                  {...input}
                  background="white"
                  invalid={meta.error && meta.touched}
                  error={meta.error}
                  placeholder="Enter your email address"
                  type="email"
                  labelColor={
                    signupLocation === 'HEADER' ? 'darkGrey' : 'white'
                  }
                  label="Email"
                />
              )}
            </Field>

            <Button
              disabled={submitting}
              loading={submitting}
              type="submit"
              id={`signupButton${signupLocation}`}
              color={buttonColor}
              width={signupLocation === 'HEADER' ? 'fit-content' : '100%'}
              mx="auto"
              mt={signupLocation === 'HEADER' ? [2, 4] : 'auto'}
              height={signupLocation !== 'HEADER' ? [null, '39px'] : undefined}
            >
              <Text
                color="white"
                fontFamily="header"
                fontSize={
                  signupLocation === 'HEADER'
                    ? ['1.5rem', '1rem', '1.8rem']
                    : undefined
                }
                fontWeight={signupLocation === 'HEADER' ? 900 : undefined}
              >
                {buttonText}
              </Text>
            </Button>
          </Grid>
        </form>
      )}
    </Form>
  );
};

export default SubscribeForm;
