import { useId, createContext, useState, useContext, ReactNode } from 'react';
import { css } from '@emotion/react';
import { Flex } from 'components/box';
import { SmallHeader } from 'components/typography';
import { Minus as MinusIcon, Plus as PlusIcon } from 'react-feather';

interface AccordionContextType {
  isOpen: (id: string) => boolean;
  setOpen: (id: string, open: boolean) => void;
}

const AccordionContext = createContext<AccordionContextType>({
  isOpen: _id => false,
  setOpen: (_id, _open) => undefined,
});

export const Accordion = ({
  children,
  multipleOpen = false,
}: {
  children: ReactNode;
  multipleOpen?: boolean;
}) => {
  const [openItems, setOpenItems] = useState<{ [id: string]: boolean }>({});

  const isOpen = (id: string) => Boolean(openItems[id]);
  const setOpen = (id: string, isOpen: boolean) => {
    let nextOpenItems: Record<string, boolean> | undefined;

    if (isOpen && !multipleOpen) {
      nextOpenItems = Object.keys(openItems).reduce((openItems, id) => {
        openItems[id] = false;
        return openItems;
      }, {});
    } else {
      nextOpenItems = openItems;
    }

    setOpenItems({ ...nextOpenItems, [id]: isOpen });
  };

  return (
    <AccordionContext.Provider
      value={{
        isOpen,
        setOpen,
      }}
    >
      {children}
    </AccordionContext.Provider>
  );
};

export const RightIcon = ({ isOpen }: { isOpen: boolean }) => (
  <Flex
    alignItems="center"
    justifyContent="center"
    css={theme => css`
      color: ${theme.colors.darkGrey};
      font-size: ${theme.fontSizes.title2};
      font-weight: 500;

      &:active,
      &:focus {
        outline: 0;
      }

      svg line {
        stroke-width: 4;
        stroke-linecap: square;
      }
    `}
  >
    {isOpen ? <MinusIcon size={13} /> : <PlusIcon size={13} />}
  </Flex>
);

interface AccordionItemProps {
  children: ReactNode;
  renderButtonContent: (props: { isOpen: boolean }) => ReactNode;
  hideWhenClosed?: boolean;
}

export const AccordionItemBase = ({
  children,
  renderButtonContent,
  hideWhenClosed,
}: AccordionItemProps) => {
  const { isOpen, setOpen } = useContext(AccordionContext);

  const idRef = useId();

  const isCurrentlyOpen = isOpen(idRef);
  const toggle = () => setOpen(idRef, !isCurrentlyOpen);

  return (
    <>
      <button
        type="button"
        onClick={toggle}
        css={theme => css`
          background: none;
          border: 0;
          padding: 0;
          touch-action: manipulation;
          user-select: none;
          -webkit-appearance: none;

          width: 100%;
          cursor: pointer;
          outline: 0;
          background-color: white;
          border-radius: 8px;
          box-shadow: ${theme.shadows.cards};
        `}
      >
        {renderButtonContent({ isOpen: isCurrentlyOpen })}
      </button>
      {hideWhenClosed ? (
        <div
          css={css`
            display: none;
            ${isCurrentlyOpen &&
            css`
              display: initial;
            `}
          `}
        >
          {children}
        </div>
      ) : (
        <>{isCurrentlyOpen && children}</>
      )}
    </>
  );
};

export const AccordionItem = ({
  children,
  title,
  className = undefined,
  hideWhenClosed,
}: {
  children: ReactNode;
  title?: string;
  className?: string;
  hideWhenClosed?: boolean;
}) => (
  <AccordionItemBase
    hideWhenClosed={hideWhenClosed}
    renderButtonContent={({ isOpen }) => (
      <Flex
        p={3}
        width="100%"
        justifyContent="space-between"
        alignItems="center"
        css={css`
          line-height: 1.4em;
        `}
        className={className}
      >
        <SmallHeader textAlign="left" pr={1} marginBottom={0}>
          {title}
        </SmallHeader>

        <RightIcon isOpen={isOpen} />
      </Flex>
    )}
  >
    {children}
  </AccordionItemBase>
);
