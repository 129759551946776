import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  addDismissableNotification,
  removeDismissableNotification,
} from '../../../redux/global/actions';

const OnlineStatusListener = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const onNetworkDown = () =>
      dispatch(
        addDismissableNotification({
          id: 'onlineStatus',
          message: 'Your internet connection is down!',
          dismissable: true,
          priority: Infinity,
          variant: 'error',
        })
      );

    const onNetworkUp = () =>
      dispatch(removeDismissableNotification('onlineStatus'));

    window.addEventListener('offline', onNetworkDown);
    window.addEventListener('online', onNetworkUp);
    return () => {
      window.removeEventListener('online', onNetworkUp);
      window.removeEventListener('offline', onNetworkDown);
    };
  }, [dispatch]);

  return null;
};

export default OnlineStatusListener;
