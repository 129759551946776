/* eslint-disable no-magic-numbers */
import primary, {
  colors as baseColors,
  colorPalette as baseColorPalette,
  gradientConfettiPalette as baseGradientConfettiPalette,
} from './onedayonly';

const colorPalette = {
  ...baseColorPalette,
  purple: '#a11673',
  lightPurple: '#e533c3',
  middlePurple: '#c3269f',
  darkPurple: '#8c276f',
};

const colors = {
  ...baseColors,
  primary: colorPalette.purple,
  secondary: colorPalette.pink,
  link: colorPalette.purple,
  accent: colorPalette.pink,
  logo: colorPalette.purple,
};

const gradientConfettiPalette = {
  ...baseGradientConfettiPalette,
  default: {
    start: colorPalette.lightPurple,
    middle: colorPalette.middlePurple,
    end: colorPalette.darkPurple,
    confetti: colorPalette.mediumGrey,
  },
};

const theme = {
  ...primary,
  name: 'onenightonly',
  colors,
  gradientConfettiPalette,
};

Object.freeze(theme);

export default theme;
