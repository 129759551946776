import { useCallback } from 'react';
import { addDismissableNotification } from '../redux/global/actions';
import { forgetCart } from '../redux/cart/actions';
import { useDispatch } from 'react-redux';
import { GraphQLError } from 'graphql';
import { useReauthenticate } from './customer';
import { ApolloError } from '@apollo/client';
import { isAuthExpired, isNotFound } from '../util/graphql';

export const useErrorNotificationHandler = () => {
  const dispatch = useDispatch();
  const mountErrors = useCallback(
    (errors: Error[] | GraphQLError[], priority = 0) => {
      if (errors) {
        errors.forEach(error =>
          dispatch(
            addDismissableNotification({
              message: error.message,
              variant: 'error',
              dismissable: true,
              id: error.message,
              priority,
            })
          )
        );
      }
    },
    [dispatch]
  );
  return mountErrors;
};

export const useGraphQLErrorHandler = ({
  message,
  showLogin,
  logout,
  forgetCartIfNotFound,
  authExpiredCallback,
  notFoundCallback,
}: {
  message?: string;
  showLogin?: boolean;
  logout?: boolean;
  forgetCartIfNotFound?: boolean;
  authExpiredCallback?: (e: ApolloError) => void;
  notFoundCallback?: (e: ApolloError) => void;
} = {}) => {
  const dispatch = useDispatch();
  const reauthenticate = useReauthenticate();
  const mountErrors = useErrorNotificationHandler();

  const callback = useCallback(
    (
      e: ApolloError,
      config?: {
        disableErrorToast?: boolean;
      }
    ) => {
      if (isAuthExpired(e)) {
        if (authExpiredCallback) {
          authExpiredCallback(e);
        }

        const [{ message: gqlMessage }] = e.graphQLErrors;
        reauthenticate({ message: message || gqlMessage, showLogin, logout });
      } else if (notFoundCallback && isNotFound(e)) {
        notFoundCallback(e);
      } else if (forgetCartIfNotFound && isNotFound(e)) {
        dispatch(forgetCart());
      } else if (!config?.disableErrorToast) {
        mountErrors([...e.graphQLErrors]);
      }
    },
    [
      dispatch,
      message,
      showLogin,
      logout,
      forgetCartIfNotFound,
      notFoundCallback,
      authExpiredCallback,
      mountErrors,
      reauthenticate,
    ]
  );

  return callback;
};

export default useErrorNotificationHandler;
