import { useHasGlobalStatus, useAddGlobalStatus } from 'hooks/app';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { memo, useEffect, useRef } from 'react';
import config from '../../../util/load-config';

export const SAILTHRU_STATUS_LOADED = 'sailthru-loaded';
export const SAILTHRU_STATUS_INITIALIZED = 'sailthru-initialized';
export const SAILTHRU_STATUS_PERSONALIZED = 'sailthru-personalized';

/**
 * @see https://getstarted.sailthru.com/site/personalization-engine/pe-setup/
 */
interface SailthruInitFunction {
  (args: {
    customerId: string;
    isCustom?: boolean;
    autoTrackPageview?: boolean;
    useStoredTags?: boolean;
    excludeContent?: boolean;
  }): void;
}

/**
 * @see https://getstarted.sailthru.com/site/site-personalization-manager/implementation/
 */
interface SailthruTrackFunction {
  (
    track: 'pageview',
    args: {
      url: string;
      useStoredTags?: boolean;
      tags?: string[];
      onSuccess?: () => void;
      onError?: () => void;
    }
  ): void;
  (
    track: 'impression',
    args: {
      sectionId: string;
      urls: string[];
      onSuccess?: (res: unknown) => void;
      onError?: (err: unknown) => void;
    }
  ): void;
  (
    track: 'click',
    args: {
      sectionId: string;
      urls: string[];
      onSuccess?: (res: unknown) => void;
      onError?: (err: unknown) => void;
    }
  ): void;
}

/**
 * @see https://getstarted.sailthru.com/site/site-personalization-manager/implementation/
 */
interface SailthruPersonalizeFunction {
  (args: {
    vars: { context_key: string };
    sections: {
      id: string;
      onSuccess: (res: { json: string }) => void;
      onError: (err: unknown) => void;
    }[];
    timeout?: number;
  }): void;
}

/**
 * @see https://getstarted.sailthru.com/site/personalization-engine/pe-setup/
 */
interface SailthruApi {
  init: SailthruInitFunction;
  track: SailthruTrackFunction;
  personalize: SailthruPersonalizeFunction;
}

type WindowSailthru = unknown | SailthruApi;

declare global {
  interface Window {
    Sailthru?: WindowSailthru;
  }
}

export const isSailthruValid = (
  sailthru: WindowSailthru
): sailthru is SailthruApi =>
  typeof (sailthru as SailthruApi).init === 'function';

export const SailthruInit = memo(() => {
  const sailthruInitializedRef = useRef(false);
  const addGlobalStatus = useAddGlobalStatus();
  const isSailthruLoaded = useHasGlobalStatus(SAILTHRU_STATUS_LOADED);

  const { pathname } = useRouter();

  useEffect(() => {
    if (
      !sailthruInitializedRef.current &&
      isSailthruLoaded &&
      window.Sailthru &&
      isSailthruValid(window.Sailthru)
    ) {
      window.Sailthru.init({
        customerId: config.sailthru.customerId,
        // we need to set this wherever we use sailthru personalize, currently that's just the PDP
        isCustom: pathname.indexOf('/products/') === 0,
      });

      addGlobalStatus(SAILTHRU_STATUS_INITIALIZED);
      sailthruInitializedRef.current = true;
    }
  }, [isSailthruLoaded, pathname, addGlobalStatus]);

  return (
    <Script
      id="sailthru-sdk"
      strategy="afterInteractive"
      src="https://ak.sail-horizon.com/spm/spm.v1.min.js"
      onLoad={() => addGlobalStatus(SAILTHRU_STATUS_LOADED)}
    />
  );
});
