import primary, {
  colors as baseColors,
  colorPalette as baseColorPalette,
  gradientConfettiPalette as baseGradientConfettiPalette,
} from './onedayonly';

const colorPalette = {
  ...baseColorPalette,
  essentialGreen: '#00b1b0',
};

const colors = {
  ...baseColors,
  headerPrimary: colorPalette.essentialGreen,
};

const gradientConfettiPalette = {
  ...baseGradientConfettiPalette,
  default: {
    start: '#00B1B0',
    middle: '#00989A',
    end: '#00989A',
    confetti: '#00B1B0',
  },
};

const theme = {
  ...primary,
  name: 'everydayessentials',
  colors,
  colorPalette,
  gradientConfettiPalette,
};

Object.freeze(theme);

export default theme;
