import { useState } from 'react';
import {
  SearchBox,
  connectHits,
  connectStateResults,
} from 'react-instantsearch-dom';
import Button from 'components/button';
import SearchButtonSVG from 'shared/assets/icons/search.svg';
import { Container, Flex } from 'components/box';
import { css } from '@emotion/react';
import 'instantsearch.css/themes/reset.css';
import 'instantsearch.css/themes/satellite.css';
import { Box, Grid } from 'components/box';
import { X as XIcon } from 'react-feather';
import { AlgoliaSearchState, SearchResultItemInterface } from 'types/types';
import { Text, Heading } from 'components/typography';
import makeResizedUrl from '../../../../util/make-url';
import { Link } from 'components/configurable-routing';
import { productHoverStyle } from 'components/_shared/widgets/gallery/shared';
import { SearchFilters, FilterIcon, SearchFiltersMobile } from './filters';
import RenderControl from 'components/_shared/widgets/render-control';
import { soldOutWrapperStyle } from 'shared/style';
import SoldOutMask from 'components/sold-out-mask';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { RootState } from 'typesafe-actions';
import { useEffect, useMemo } from 'react';
import config from '../../../../util/load-config';
import postInsights from '../../../../util/post-algolia-insights';
import { HeaderDesignEnum } from 'types/interface';
import Loading from 'components/loading';
import CustomPagination from 'components/_shared/widgets/search/custom-pagination';

const MAX_PRODUCT_IMG_SIZE = 200;

export const SearchBar = ({
  toggleSearch,
  variant = HeaderDesignEnum.Sleek,
}: {
  toggleSearch?: () => void;
  variant?: HeaderDesignEnum;
}) => (
  <Flex
    position="relative"
    alignContent="center"
    width={
      variant === HeaderDesignEnum.Sleek
        ? ['100%', '500px']
        : ['100%', null, '475px']
    }
    maxWidth="100%"
    mb={[2, 0]}
    css={theme => css`
      .ais-SearchBox-form {
        background-color: transparent;
        ::before {
          display: none;
        }
      }
      .ais-SearchBox {
        width: 100%;
      }
      .ais-SearchBox-reset {
        width: auto;
      }
      .ais-SearchBox-reset:focus {
        background: none;
        fill: none;
      }
      .ais-SearchBox-submitIcon {
        display: none;
      }

      .ais-SearchBox-input {
        font-family: ${theme.fonts.body} !important;
        border-radius: 10px;
        color: ${theme.colors.darkestGrey};
        ::placeholder {
          opacity: 0;
        }
        &:focus {
          border: none;
        }
      }
    `}
  >
    <Flex position="absolute" mt={['10px', 2]} left={2}>
      <SearchButtonSVG
        css={theme => css`
          stroke: ${theme.colors.lightishGrey};
          width: 20px;
          height: 20px;
          stroke-width: 3px;
          z-index: 20;
        `}
      />
    </Flex>

    <Box width="100%">
      <SearchBox
        showLoadingIndicator
        /* Justification for using the eslint-disable that follows:
        autoFocus should only be used on components of which the sole
        functionality is receiving text, and where no descriptive text
        is present, as is the case with this search bar. In addition,
        using the autoFocus prop on this component causes no accessibility
        and usability issues such as unexpected scrolling.*/
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus
        reset={
          <Text
            fontFamily="header"
            fontWeight="semiBold"
            fontSize="r"
            color="primary"
          >
            Clear
          </Text>
        }
      />
    </Box>

    <Button variant="flat" onClick={toggleSearch} p={0} ml={2}>
      <XIcon color="white" />
    </Button>
  </Flex>
);

const SearchResultItem = ({
  hit,
  index,
}: {
  hit: SearchResultItemInterface;
  index: string;
}) => {
  const imageURL = makeResizedUrl(hit.image, {
    width: MAX_PRODUCT_IMG_SIZE,
    height: MAX_PRODUCT_IMG_SIZE,
    crop: 'faces,entropy',
    fit: 'clamp',
  });

  const queryParams: { name: string; value: string }[] = [
    { name: config.algolia.paramIndexName, value: index },
    { name: config.algolia.paramQueryIdName, value: hit.__queryID },
  ];

  return (
    <Grid
      gridRowGap={1}
      gridColumnGap={0}
      css={css`
        height: 100%;
        width: 100%;
        /* IOS 12is blank scroll mitigation*/
        -webkit-transform: translate3d(0, 0, 0);
        -webkit-perspective: 1000;
      `}
    >
      <Link
        dynamicUrl="/products/[id]"
        href={`/products/${hit.id}?${queryParams
          .map(
            ({ name, value }) =>
              `${encodeURIComponent(name)}=${encodeURIComponent(value)}`
          )
          .join('&')}`}
        css={theme => css`
          backface-visibility: hidden;

          ${productHoverStyle(theme)};
        `}
        onClick={() =>
          postInsights({
            queryID: hit.__queryID,
            objectID: hit.objectID,
            index,
            eventType: 'click',
            eventName: 'Product clicked on search overlay',
            position: hit.__position,
          })
        }
      >
        <Flex
          alignItems="flex-start"
          css={theme => css`
            border-radius: ${theme.radii[3]}px;
            box-shadow: ${theme.shadows.products};
            transition: all 0.2s ease;

            will-change: transform;

            &:after {
              content: '';
              display: block;
              padding-bottom: 100%;
            }
            &:hover {
              .soldOutWrapper {
                animation-name: fadeOut;
              }
            }

            .soldOutWrapper {
              ${soldOutWrapperStyle}
              animation-duration: 500ms;
              animation-fill-mode: both;
              animation-name: fadeIn;
            }
          `}
        >
          <>
            <img
              className="image"
              src={imageURL}
              alt={hit.name}
              title={hit.name}
              css={theme => css`
                border-radius: ${theme.radii[3]}px;
                user-drag: none;
                -webkit-user-drag: none;
                user-select: none;
                -moz-user-select: none;
                -webkit-user-select: none;
                -ms-user-select: none;
                display: block;
                width: 100%;
                height: 100%;
                opacity: 1;
                transition-duration: 512ms;
                transition-property: opacity, visibility;
                transition-timing-function: ease-out;
                visibility: visible;
              `}
            />
            {/*  Overlay to prevent the user from right-clicking and save the image */}
            <Box
              zIndex={10}
              position="absolute"
              top="0"
              left="0"
              minWidth="100%"
              minHeight="100%"
            />
            {hit.isSoldOut && (
              <Box className="soldOutWrapper">
                <SoldOutMask
                  isDark
                  text
                  headingFontSize={[3, 4]}
                  isOnSearch
                  isPromoDeal={hit.isPromotionalDeal}
                  price={hit.formattedPrice}
                />
              </Box>
            )}
          </>
        </Flex>

        <Grid
          gridTemplateRows="auto 1fr"
          gridColumnGap={0}
          gridRowGap={0}
          mt={3}
          css={css`
            overflow: hidden;
          `}
        >
          <Heading
            fontSize={['0.9rem', '1.1rem', 2]}
            fontWeight="bold"
            lineHeight="1.2em"
            color="black"
          >
            {hit.brand}
          </Heading>
          <Heading
            fontWeight="medium"
            fontSize={['0.8rem', '1.1rem', 2]}
            color="darkGrey"
            lineHeight="1.5em"
            css={css`
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            `}
          >
            {hit.shortName || hit.name}
          </Heading>
        </Grid>

        <Flex mb={2} alignItems="flex-end" flexWrap="wrap">
          <Heading
            fontWeight={600}
            mr={2}
            mt={2}
            color="black"
            className="highlightOnHover"
            css={css`
              position: relative;
              bottom: -0.05em;
            `}
          >
            {hit.formattedPrice}
          </Heading>

          {hit.retail > 0 && (
            <Heading
              fontSize={2}
              color="darkGrey"
              fontWeight="medium"
              mt={2}
              css={css`
                text-decoration: line-through;
              `}
            >
              {hit.formattedRetail}
            </Heading>
          )}
        </Flex>
      </Link>
    </Grid>
  );
};

const SearchResultsGallery = ({
  hits,
  showMobileFilters,
  index,
  showPagination,
  loading,
  numHits,
}: {
  hits: SearchResultItemInterface[];
  showMobileFilters: () => void;
  index: string;
  showPagination: boolean;
  loading: boolean;
  numHits: number;
}) => (
  <Box pt={3}>
    <Box width="100%">
      <Flex
        mb={3}
        pb={[2, 3]}
        width="100%"
        justifyContent="space-between"
        alignItems={[null, 'center']}
        css={theme => css`
          border-bottom: 1px solid ${theme.colors.lightishGrey};
        `}
      >
        <Heading fontSize={['0.9rem', 'm']}>
          {numHits === 1
            ? '1 Product found'
            : numHits === 0
            ? 'No Products found'
            : `${showPagination ? numHits : hits.length} Products found`}
        </Heading>
        {showPagination && (
          <RenderControl type="client" screen="tabletUp">
            <CustomPagination padding={2} />
          </RenderControl>
        )}

        <Button
          className="show-for-mobile-only"
          variant="flat"
          p={0}
          m={0}
          textTransform="none"
          onClick={showMobileFilters}
        >
          <FilterIcon />
          <Heading
            ml={2}
            fontSize="s"
            color="blue"
            fontFamily="header"
            fontWeight="medium"
          >
            Filter
          </Heading>
        </Button>
      </Flex>
    </Box>

    {hits.length > 0 ? (
      <Loading isLoading={loading} iconTop={100}>
        <Grid
          width="100%"
          pb={['160px', 3]}
          gridColumnGap={4}
          gridTemplateColumns={[
            'repeat(2, 1fr)',
            'repeat(3, 1fr)',
            'repeat(5, 1fr)',
          ]}
        >
          {hits.map((hit, idx) => (
            <SearchResultItem key={idx} hit={hit} index={index} />
          ))}
        </Grid>
      </Loading>
    ) : (
      <Text lineHeight={1.5}>
        No search results found, please try a different search or check out our
        other deals.
      </Text>
    )}
  </Box>
);

const SearchResults = connectHits(SearchResultsGallery);

const shouldShowPagination = (searchState: AlgoliaSearchState) =>
  // has search term
  searchState.searchState?.query?.length > 0 ||
  // has filters applied
  Object.keys(searchState.searchState?.refinementList || {}).length > 0 ||
  // has numeric list filters applied
  Object.keys(searchState.searchState?.multiRange || {}).length > 0;

let timeoutId: NodeJS.Timeout | undefined;

const SearchResultsOverlay = ({
  index,
  isMobileFiltersActive,
  showMobileFilters,
  hideMobileFilters,
  closeSearch,
  ...searchState
}: {
  index: string;
  isMobileFiltersActive: boolean;
  showMobileFilters: () => void;
  hideMobileFilters: () => void;
  closeSearch: () => void;
} & AlgoliaSearchState) => {
  const { events } = useRouter();
  const activeDrawer = !!useSelector(
    (state: RootState) => state.global.openDrawer
  );

  const showPagination = shouldShowPagination(searchState);
  const [loading, setLoading] = useState(false);

  // timeout to show loading spinner when search results change
  useEffect(() => {
    setLoading(true);
    timeoutId = setTimeout(() => {
      setLoading(false);
    }, 700);
    return () => timeoutId && clearTimeout(timeoutId);
  }, [searchState.searchState]);

  useEffect(() => {
    activeDrawer && closeSearch();
  }, [activeDrawer, closeSearch]);

  useEffect(() => {
    events.on('routeChangeComplete', () => closeSearch());
  }, [closeSearch, events]);

  return (
    <>
      <Box>
        <Box
          className="hide-for-mobile-only"
          height="100vh"
          width="100%"
          position="absolute"
          bg="overlayBackground"
          onClick={closeSearch}
          css={css`
            cursor: pointer;
          `}
        />
        <Flex
          justifyContent="center"
          backgroundColor="white"
          width="100vw"
          position="absolute"
          boxShadow="cards"
        >
          <Grid
            gridTemplateColumns={['1fr', '3fr 1fr', '4fr 1fr']}
            height={['90vh', '75vh']}
            width="100%"
            maxWidth="1200px"
            mx="auto"
            backgroundColor="white"
            css={theme => css`
              @media ${theme.mediaQueries.tabletDown} {
                ::-webkit-scrollbar {
                  /*Remove scrollbar on mobile devices*/
                  width: 0;
                  background: transparent;
                }
              }
            `}
          >
            <Flex
              overflowY="scroll"
              width="100%"
              height={['90vh', '75vh']}
              flexDirection="column"
              pb="20px"
            >
              <Container py={null} m={0} px={3}>
                <SearchResults
                  numHits={searchState.allSearchResults?.nbHits || 0}
                  loading={loading}
                  showPagination={showPagination}
                  index={index}
                  showMobileFilters={showMobileFilters}
                />
                {showPagination && (
                  <RenderControl type="client" screen="tabletUp">
                    <Flex width="100%" justifyContent="center" mb="20px">
                      <CustomPagination padding={2} />
                    </Flex>
                  </RenderControl>
                )}
              </Container>
              {showPagination && (
                <RenderControl type="client" screen="mobileDown">
                  <Flex
                    justifyContent="center"
                    position="fixed"
                    bottom={0}
                    left={0}
                    width="100vw"
                    p="14px"
                    backgroundColor="white"
                    boxShadow="cards"
                  >
                    <CustomPagination padding={1} />
                  </Flex>
                </RenderControl>
              )}
            </Flex>

            <RenderControl type="client" screen="tabletUp">
              <SearchFilters />
            </RenderControl>
          </Grid>
        </Flex>
      </Box>

      <RenderControl type="client" screen="mobileDown">
        <SearchFiltersMobile
          isMobileFiltersOpen={isMobileFiltersActive}
          hideMobileFilters={hideMobileFilters}
        />
      </RenderControl>
    </>
  );
};

export const ConnectedSearchResultsOverlay = connectStateResults<AlgoliaSearchState>(
  SearchResultsOverlay
);
