import { Component, ErrorInfo, ReactNode } from 'react';
import { logError } from '../../../util/error-logger';

interface Props {
  children: ReactNode;
  errorDisplay?: ReactNode;
  onErrorCallback?: (error: Error) => void;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.props.onErrorCallback ? this.props.onErrorCallback(error) : null;
    logError('Uncaught error', 'ErrorBoundary');
    console.error(error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      return this.props.errorDisplay ? this.props.errorDisplay : null;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
