import mem from 'mem';
import config from './load-config';
import { trimUrl } from './url';
// Tests if a URL begins with `http://` or `https://` or `data:`
// const absoluteUrl = /^(data|http|https)?:/i;

// Simple path joiner that guarantees one and only one slash between segments
// const joinUrls = (base, url) =>
//   `${base.endsWith('/') ? base.slice(0, -1) : base}/${
//     url.startsWith('/') ? url.slice(1) : url
//   }`;

// TODO: if we ever need this again, make it more dynamic, like an open registry
// const mediaPathPrefixes = {};

interface ResizeProps {
  width?: number;
  height?: number;
  bg?: string;
  fit?: string;
  isAssetUrl?: boolean;
  crop?: string;
  quality?: number;
}

// sends imgix specific resize (and other) parameters
function makeResizedUrl(
  url: string,
  { width, height, bg, fit, isAssetUrl, crop, quality }: ResizeProps
) {
  const baseUrl = trimUrl(config.assets.baseUrl);
  const trimmedUrl = trimUrl(url);
  let resizedUrl = isAssetUrl ? `${baseUrl}/${trimmedUrl}` : trimmedUrl;
  resizedUrl += '?auto=compress,format';

  if (quality) {
    resizedUrl += `&q=${quality}`;
  }

  if (width) {
    resizedUrl += `&w=${width}`;
  }
  if (height) {
    resizedUrl += `&h=${height}`;
  }
  if (bg) {
    resizedUrl += `&bg=${bg}`;
  }
  if (fit) {
    resizedUrl += `&fit=${fit}`;
  }
  if (crop) {
    resizedUrl += `&crop=${crop}`;
  }

  return resizedUrl;
}

type FormatProps = ResizeProps & {
  type?: string;
};

// Should fail silently on a null value, so default to the empty string
// so that string methods work
function formatUrl(url = '', { type, ...resizeProps }: FormatProps = {}) {
  if (!(type || Object.keys(resizeProps).length > 0)) {
    // Without a type or resize props, we don't know how to transform this url
    return trimUrl(url);
  }

  // we need to throw on an unrecognized `type`, so we may have to assign this
  // in another code branch, hence the "let"
  const formattedUrl = url;

  // TODO: re-enable if we ever decide to set resource domains from FE again
  // if (!absoluteUrl.test(url) && type) {
  //   if (!mediaPathPrefixes.hasOwnProperty(type)) {
  //     throw new Error(`Unrecognized media type ${type}`);
  //   }
  //   // "/path/to/jpeg" becomes e.g. "/media/catalog/product/path/to/jpeg"
  //   formattedUrl = joinUrls(mediaPathPrefixes[type], url);
  // }

  // if there are any resize props, make a resized URL!
  return Object.keys(resizeProps).length > 0
    ? makeResizedUrl(formattedUrl, resizeProps)
    : formattedUrl;
}

export default mem(formatUrl, {
  cacheKey: ([url, props]) => `${url}%%${JSON.stringify(props)}`,
});
