import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
  concat,
} from '@apollo/client';
import possibleTypes from '../graphql.schema.json';
import { useRef, useEffect } from 'react';
import config from '../util/load-config';
import { trimUrl } from '../util/url';
import { GoogleTagInfo } from 'types/third-party/gtm';

export const WEB_CLIENT_NAME = 'odo-web';

let apolloClient: ApolloClient<NormalizedCacheObject> | undefined;

let clientName = WEB_CLIENT_NAME;
let sailthruHid = '';
let gtmClientId: string | undefined;
let gtmSessionId: string | undefined;

export interface ApolloClientOptions {
  clientName?: string;
  sailthruHid?: string;
  gtm?: GoogleTagInfo;
}

const createApolloClient = () => {
  const cache = new InMemoryCache({ possibleTypes });
  const httpLink = new HttpLink({ uri: trimUrl(config.gateway.uri) });
  const clientNameLink = new ApolloLink((operation, forward) => {
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        ...(clientName ? { client: clientName } : {}),
        ...(sailthruHid ? { 'x-abon': sailthruHid } : {}),
        ...(gtmClientId ? { ['ga-client-id']: gtmClientId } : {}),
        ...(gtmSessionId ? { ['ga-session-id']: gtmSessionId } : {}),
      },
    }));
    return forward(operation);
  });

  return new ApolloClient<NormalizedCacheObject>({
    cache,
    connectToDevTools: process.browser,
    ssrMode: typeof window === 'undefined',
    link: concat(clientNameLink, httpLink),
    assumeImmutableResults: true,
  });
};

export const initializeApollo = () => {
  const _apolloClient = apolloClient ?? createApolloClient();

  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient;
  // Cache the Apollo Client on the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
};

export const useApollo = (options: ApolloClientOptions = {}) => {
  const client = useRef(initializeApollo());

  useEffect(() => {
    if (options.clientName) {
      clientName = options.clientName;
    }
    if (options.sailthruHid) {
      sailthruHid = options.sailthruHid;
    }
    if (options.gtm?.clientId) {
      gtmClientId = options.gtm.clientId;
    }
    if (options.gtm?.sessionId) {
      gtmSessionId = options.gtm.sessionId;
    }
    client.current = initializeApollo();
  }, [options]);

  return client.current;
};
