import { AnchorHTMLAttributes } from 'react';
import styled from 'components/styled';
import {
  fontFamily,
  fontSize,
  fontStyle,
  fontWeight,
  textAlign,
  lineHeight,
  letterSpacing,
  display,
  DisplayProps,
  opacity,
  OpacityProps,
  compose,
  border,
  color,
  layout,
  position,
  shadow,
  space,
  typography,
} from 'styled-system';
import { Box, BoxProps } from 'components/box';

type TextProps = BoxProps & DisplayProps & OpacityProps;

export const Text = styled(Box)<TextProps>(compose(opacity, display));

Text.defaultProps = {
  fontFamily: 'body',
};

export const ParagraphText = styled(Text)<TextProps>();

ParagraphText.defaultProps = {
  fontSize: ['0.95rem', '1.2rem', 2],
  lineHeight: '1.4',
};

export const AccentText = styled(Text)<TextProps>();

AccentText.defaultProps = {
  fontSize: [2, '1.4rem', 3],
  lineHeight: '1.4',
  fontWeight: 'bold',
  fontFamily: 'header',
  color: 'primary',
};

export const Heading = styled(Text)<TextProps & { as?: string }>();

Heading.defaultProps = {
  as: 'h2',
  fontSize: [2, '1.4rem', 3],
  fontWeight: 'bold',
  fontFamily: 'header',
  color: 'black',
};

export const SmallHeader = styled(Text)<TextProps & { as?: string }>();

SmallHeader.defaultProps = {
  as: 'h3',
  fontSize: ['0.95rem', '1.2rem', 2],
  fontWeight: 'bold',
  fontFamily: 'header',
  color: 'black',
  lineHeight: '1.2',
  marginBottom: [1, 2],
};

export const SectionHeading = styled(Text)<TextProps & { as?: string }>();

SectionHeading.defaultProps = {
  as: 'h2',
  fontSize: [2, '1.4rem', 3],
  fontWeight: 'bold',
  fontFamily: 'header',
  color: 'black',
  textAlign: ['center', 'center', 'left'],
  marginBottom: 4,
};

export const PageTitle = styled(Text)<TextProps & { as?: string }>();

PageTitle.defaultProps = {
  as: 'h1',
  fontSize: [3, 5, 4],
  fontWeight: 'bold',
  fontFamily: 'header',
  color: 'black',
  lineHeight: '1.2',
  textAlign: ['center', 'center', 'left'],
};

export type LinkProps = TextProps & AnchorHTMLAttributes<HTMLAnchorElement>;

export const styledLink = compose(
  space,
  layout,
  border,
  shadow,
  typography,
  color,
  position,
  fontFamily,
  fontSize,
  fontStyle,
  fontWeight,
  textAlign,
  lineHeight,
  letterSpacing
);

export const Link = styled('a')<LinkProps>(styledLink);

Link.defaultProps = {
  color: 'primary',
  display: 'inline',
  fontSize: 'inherit',
  fontFamily: 'inherit',
  fontWeight: 'bold',
};
