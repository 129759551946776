import config from './load-config';

async function postInsights({
  queryID,
  objectID,
  index,
  eventType,
  eventName,
  position,
}: {
  queryID: string | null;
  objectID: string | null;
  index: string | null;
  eventType: string;
  eventName: string;
  position?: string;
}) {
  const response = await fetch(config.algolia.insightsUrl, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'x-algolia-api-key': config.algolia.apiKey,
      'x-algolia-application-id': config.algolia.appId,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      events: [
        {
          eventType,
          eventName,
          index,
          queryID,
          userToken: 'anonymous-user',
          objectIDs: [objectID],
          ...(position ? { positions: [position] } : {}),
        },
      ],
    }),
  });
  return response.json();
}

export default postInsights;
