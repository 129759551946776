import { ReactNode } from 'react';
import { css, Theme } from '@emotion/react';
import { Box } from 'components/box';
import { Text } from 'components/typography';

export const loadingStyle = (
  theme: Theme,
  size = 'medium',
  iconTop: number | undefined = undefined
) => css`
  &::before {
    content: '';
    display: inline-block;
    position: absolute;
    border: 4px solid ${theme.colors.primary};
    border-left-color: white;
    border-radius: 100%;
    box-sizing: border-box;
    top: 50%;
    left: 50%;
    margin-top: -24px;
    margin-left: -24px;
    width: 48px;
    height: 48px;
    opacity: 1;

    ${typeof iconTop !== 'undefined' &&
    css`
      top: ${iconTop}px;
    `}

    ${size === 'small' &&
    css`
      border-width: 3px;
      margin-top: -15px;
      margin-left: -15px;
      width: 30px;
      height: 30px;
    `}

    ${size === 'extra-small' &&
    css`
      border-width: 2px;
      margin-top: -10px;
      margin-left: -10px;
      width: 20px;
      height: 20px;
    `}

    @keyframes loading {
      0% {
        transform: rotate(0deg) scale(1);
      }
      50% {
        transform: rotate(180deg) scale(1);
      }
      100% {
        transform: rotate(360deg) scale(1);
      }
    }

    animation: loading 1s linear infinite;
  }
`;

type Element = ReactNode | ReactNode[] | false | null | undefined;

const Loading = ({
  children,
  isLoading,
  size,
  className,
  backgroundColor,
  scaleUp,
  loadingText,
  hideLoadingIcon,
  iconTop,
}: {
  children: Element | Element[];
  isLoading: boolean;
  size?: 'small' | 'medium';
  className?: string;
  backgroundColor?: string;
  scaleUp?: boolean;
  loadingText?: string;
  hideLoadingIcon?: boolean;
  iconTop?: number;
}) => (
  <div
    className={className}
    css={css`
      position: relative;
      height: 100%;
    `}
  >
    {isLoading && (
      <div
        css={css`
          position: absolute;
          top: 0;
          bottom: 0;
          width: 100%;
          z-index: 180;
        `}
      >
        {/* Overlay */}
        <div
          css={theme => css`
            position: absolute;
            top: 0;
            bottom: 0;
            width: 100%;
            background: #fff;
            opacity: 0.8;

            ${scaleUp &&
            css`
              transform: scale3d(1.025, 1.025, 1.025);
            `}

            ${backgroundColor &&
            css`
              background: ${theme.colors[backgroundColor]};
              box-shadow: 0 0 15px ${theme.colors[backgroundColor]};
            `}
          `}
        />

        {/* Loading Icon */}
        {!hideLoadingIcon && (
          <Box css={theme => loadingStyle(theme, size, iconTop)} />
        )}

        {/* Loading Text */}
        {!!loadingText && (
          <Text
            fontSize={['r', 'l', 'xl']}
            css={css`
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
              height: 100vh;
              position: fixed;
              top: 0;
              left: 0;
            `}
          >
            {loadingText}
          </Text>
        )}
      </div>
    )}

    {children}
  </div>
);

export default Loading;
