import {
  BackgroundProps,
  BorderRadiusProps,
  DisplayProps,
  FontSizeProps,
  FontWeightProps,
  LineHeightProps,
  MinWidthProps,
  SpaceProps,
  background,
  borderRadius,
  display,
  fontSize,
  fontWeight,
  lineHeight,
  minWidth,
  space,
  width,
  compose,
} from 'styled-system';
import FormError from '../error';
import Label from 'components/_shared/elements/label';
import { forwardRef, useState, InputHTMLAttributes, Ref, useId } from 'react';
import { css } from '@emotion/react';
import styled from 'components/styled';
import { Box, Grid } from 'components/box';
import VisibilityIcon from 'shared/assets/icons/visibility-filled.svg';
import VisibilityOffIcon from 'shared/assets/icons/visibility-off-filled.svg';

interface InputProps {
  invalid?: boolean;
  reserveErrorSpace?: boolean;
}

type Props = InputProps &
  SpaceProps &
  FontSizeProps &
  FontWeightProps &
  LineHeightProps &
  BorderRadiusProps &
  BackgroundProps &
  MinWidthProps &
  DisplayProps;

const Input = styled.input<Props>`
  ${({ invalid = false, theme }) => {
    return css`
      outline: none;
      appearance: none;
      display: block;
      width: 100%;
      font-family: ${theme.fonts.body};
      font-size: ${theme.fontSizes[2]};
      color: inherit;
      background-color: transparent;
      border-width: 2px;
      border-style: solid;
      border-color: ${theme.colors.borderColor};
      padding: ${theme.space[2]}px 12px;
      margin: 0;

      ${invalid
        ? css`
            border-color: ${theme.colors.accent};
          `
        : ''}

      &:active,
      &:focus {
        border-color: ${theme.colors.primary};
      }

      ::placeholder {
        color: ${theme.colors.mediumGrey};
      }
      ::-ms-clear {
        display: none;
      }
    `;
  }}
  ${compose(
    space,
    fontSize,
    width,
    fontWeight,
    lineHeight,
    background,
    borderRadius,
    minWidth,
    display
  )}
`;

export type LabeledInputProps = {
  label?: string;
  error?: string;
  invalid?: boolean;
  togglePassword?: boolean;
  labelColor?: string;
} & Props &
  InputHTMLAttributes<HTMLInputElement>;

type RefHandler = (ref: HTMLInputElement | null) => void;

const LabeledInput = forwardRef(
  (
    {
      label,
      invalid,
      togglePassword,
      id,
      type,
      labelColor,
      ...rest
    }: LabeledInputProps,
    ref: Ref<HTMLInputElement> | RefHandler
  ) => {
    const [isFocused, setFocus] = useState(false);
    const [isShowPassword, setShowPassword] = useState(false);
    const idRef = useId();
    const htmlId = id || `${rest.name}-${idRef}`;

    return (
      <Box position="relative">
        {label && type !== 'hidden' && (
          <Label
            mb={2}
            htmlFor={htmlId}
            isFocused={isFocused}
            invalid={invalid}
            color={labelColor}
          >
            {label}
          </Label>
        )}

        <Input
          {...rest}
          id={htmlId}
          type={isShowPassword && type === 'password' ? 'text' : type}
          ref={ref}
          invalid={invalid}
          onFocus={e => {
            rest.onFocus && rest.onFocus(e);
            setFocus(true);
          }}
          onBlur={e => {
            rest.onBlur && rest.onBlur(e);
            setFocus(false);
          }}
        />
        {togglePassword && type === 'password' && (
          <button
            css={theme => css`
              border: none;
              color: ${theme.colors.lightGrey};
              outline: none;
              padding: ${theme.space[2]}px;
              position: absolute;
              right: 0;
              top: 35%;
              &:focus {
                stroke: ${theme.colors.primary};
              }
            `}
            type="button"
            tabIndex={0}
            onClick={() => setShowPassword(showPassword => !showPassword)}
          >
            {isShowPassword ? (
              <VisibilityOffIcon aria-label="Hide password" />
            ) : (
              <VisibilityIcon aria-label="Show password" />
            )}
          </button>
        )}
      </Box>
    );
  }
);

const LabeledInputWrapper = forwardRef(
  (
    { error, invalid, reserveErrorSpace, ...rest }: LabeledInputProps,
    ref: Ref<HTMLInputElement> | RefHandler
  ) => {
    if (reserveErrorSpace) {
      return (
        <Grid
          gridTemplateAreas="'field' 'error'"
          gridTemplateColumns="1fr"
          gridTemplateRows="3fr 1fr"
          gridRowGap={0}
          gridAutoFlow="column"
        >
          <Box gridArea="field">
            <LabeledInput ref={ref} invalid={invalid} {...rest} />
          </Box>
          <Box gridArea="error">
            {error && invalid && <FormError>{error}</FormError>}
          </Box>
        </Grid>
      );
    }

    return (
      <Box {...(rest.type === 'hidden' ? { display: 'contents' } : {})}>
        <LabeledInput ref={ref} invalid={invalid} {...rest} />
        {error && invalid && <FormError>{error}</FormError>}
      </Box>
    );
  }
);

LabeledInputWrapper.defaultProps = {
  borderRadius: 1,
  type: 'text',
};

export default LabeledInputWrapper;
