import {
  BorderRadiusProps,
  DisplayProps,
  FontSizeProps,
  FontWeightProps,
  LineHeightProps,
  MinWidthProps,
  SpaceProps,
  borderRadius,
  display,
  fontSize,
  fontWeight,
  lineHeight,
  minWidth,
  space,
  width,
  compose,
  border,
  BorderProps,
  WidthProps,
} from 'styled-system';
import { css } from '@emotion/react';
import styled from 'components/styled';

interface ExtraProps {
  invalid?: boolean;
  isFocused?: boolean;
  customBorderColor?: string;
}

// Disabled state missing
// Focus state missing

export type LabelProps = ExtraProps &
  SpaceProps &
  FontSizeProps &
  WidthProps &
  FontWeightProps &
  LineHeightProps &
  BorderRadiusProps &
  MinWidthProps &
  BorderProps &
  DisplayProps;

export const Label = styled.label<LabelProps>`
  ${({ invalid = false, isFocused, theme, customBorderColor, color }) => {
    return css`
      font-family: ${theme.fonts.header};
      font-weight: ${theme.fontWeights.bold};

      font-size: 0.95rem;
      @media ${theme.mediaQueries.tabletUp} {
        font-size: 1.2rem;
      }
      @media ${theme.mediaQueries.desktopUp} {
        font-size: ${theme.fontSizes[2]};
      }
      color: ${theme.colors[color || 'black']};
      display: block;
      margin: 0;
      ${invalid
        ? css`
            color: ${theme.colors.danger};
          `
        : null}
      ${isFocused
        ? css`
            color: ${theme.colors.primary};
          `
        : null}
      ${customBorderColor
        ? css`
            border: 1px solid ${theme.colors[customBorderColor]};
          `
        : null}
    `;
  }}
  ${compose(
    space,
    fontSize,
    width,
    fontWeight,
    lineHeight,
    borderRadius,
    minWidth,
    display,
    border
  )}
`;

Label.defaultProps = {};

export default Label;
