import Label from 'components/_shared/elements/label';
import { useId, InputHTMLAttributes, ReactElement } from 'react';
import { css, Theme } from '@emotion/react';

const componentStyle = (
  theme: Theme,
  color = 'primary',
  withMarginRight = false,
  shouldFocus: boolean
) => css`
  display: inline-block;
  ${withMarginRight ? 'margin-right: 0.75rem;' : ''}

  label {
    line-height: 1;
  }

  input[type='checkbox'] {
    position: absolute;
    opacity: 0;
  }
  input[type='checkbox'] + label {
    position: relative;
    cursor: pointer;
    padding: 0;
  }
  input[type='checkbox'] + label:before {
    content: '';
    display: inline-block;
    vertical-align: text-top;
    width: 20px;
    height: 20px;
    background: ${theme.colors.mediumGrey};
    border-radius: 4px;
    margin-top: 0px;
  }
  input[type='checkbox'] + label:after {
    content: '';
    position: absolute;
    left: 3px;
    top: 3px;
    background: white;
    width: 14px;
    height: 14px;
    border-radius: 1px;
    margin-top: 0px;
  }
  input[type='checkbox']:hover + label:before {
    background: ${theme.colors[color]};
  }
  ${shouldFocus &&
  css`
    input[type='checkbox']:focus + label:before {
      background: ${theme.colors[color]};
    }
  `}

  input[type='checkbox']:checked + label:before {
    background: ${theme.colors[color]};
  }
  input[type='checkbox']:disabled + label {
    color: #b8b8b8;
    cursor: auto;
  }
  input[type='checkbox']:disabled + label:before {
    box-shadow: none;
    background: #ddd;
  }
  input[type='checkbox']:checked + label:after {
    content: '';
    position: absolute;
    left: 5px;
    top: 9px;
    background: white;
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white,
      4px -6px 0 white, 4px -8px 0 white;
    transform: rotate(45deg);
    margin-top: 0px;
    border-radius: 0px;
  }
`;

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label?: ReactElement | string;
  shouldFocus?: boolean;
}

const Checkbox = ({
  label,
  shouldFocus = true,
  color = 'primary',
  onChange = () => null,
  ...restProps
}: Props) => {
  const id = useId();

  return (
    <Label
      css={theme => css`
        font-weight: ${theme.fontWeights.regular};
        color: ${theme.colors.black};
        display: inline-flex;
        align-items: center;
        cursor: pointer;
      `}
    >
      <div css={theme => componentStyle(theme, color, !!label, shouldFocus)}>
        <input onChange={onChange} {...restProps} type="checkbox" id={id} />
        {/* eslint-disable-next-line */}
        <label htmlFor={id} />
      </div>{' '}
      {!!label && label}
    </Label>
  );
};

export default Checkbox;
