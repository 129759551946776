import {
  CustomerOrder,
  CustomerOrderStateEnum,
  StoreCredit,
} from 'types/types';
import { createReducer } from 'typesafe-actions';
import { setOrder, clearOrder, setStoreCreditRemaining } from './actions';

type InitialStateType = {
  order: CustomerOrder;
  storeCreditRemaining?: StoreCredit;
};

const emptyOrder: CustomerOrder = {
  id: '',
  date: '',
  items: [],
  paymentMethods: [],
  state: {
    code: CustomerOrderStateEnum.Completed,
    label: 'COMPLETED',
  },
  status: {
    code: 'Completed',
    label: 'COMPLETED',
  },
  totals: {
    shipping: {
      value: 0,
      formattedValue: '',
    },
    tax: {
      total: {
        value: 0,
        formattedValue: '',
      },
      items: [],
    },
    subtotal: {
      value: 0,
      formattedValue: '',
    },
    totalDue: {
      value: 0,
      formattedValue: '',
    },
    grandTotal: {
      value: 0,
      formattedValue: '',
    },
  },
  isVirtual: false,
  email: '',
  billingAddress: {
    id: '',
    customerAddressId: '',
    firstName: '',
    middleName: '',
    lastName: '',
    addressLine1: '',
    suburb: {
      id: '',
      code: '',
      name: '',
    },
    postalCode: '',
    city: '',
    province: {
      id: '',
      code: '',
      name: '',
    },
    country: {
      id: '',
      code: '',
      name: '',
    },
  },
  quantity: 0,
  canChangeShippingAddress: false,
  canChangeBillingAddress: false,
};

const emptyStoreCredit: StoreCredit = {
  balance: {
    value: 0,
    formattedValue: '',
  },
};

const initialState: InitialStateType = {
  order: emptyOrder,
  storeCreditRemaining: emptyStoreCredit,
};

const orderReducer = createReducer(initialState)
  .handleAction(setOrder, (state, { payload }) => ({
    ...state,
    order: payload,
  }))
  .handleAction(setStoreCreditRemaining, (state, { payload }) => ({
    ...state,
    storeCreditRemaining: payload,
  }))
  .handleAction(clearOrder, () => initialState);

export default orderReducer;
export type orderState = ReturnType<typeof orderReducer>;
