import { ElementType } from 'react';
import styled from 'components/styled';

import {
  space,
  SpaceProps,
  typography,
  TypographyProps,
  layout,
  LayoutProps,
  grid,
  GridProps as StyledGridProps,
  gridColumn,
  GridColumnProps,
  gridRow,
  GridRowProps,
  gridArea,
  GridAreaProps,
  color,
  ColorProps,
  flexbox,
  FlexboxProps,
  alignItems,
  AlignItemsProps,
  alignContent,
  AlignContentProps,
  justifyContent,
  JustifyContentProps,
  flex,
  FlexProps as StyledFlexProps,
  flexGrow,
  FlexGrowProps,
  flexShrink,
  FlexShrinkProps,
  flexBasis,
  FlexBasisProps,
  alignSelf,
  AlignSelfProps,
  order,
  OrderProps,
  border,
  BorderProps,
  position,
  PositionProps,
  shadow,
  ShadowProps,
  compose,
} from 'styled-system';
import { css, Theme } from '@emotion/react';

const flexElement = compose(
  flex,
  flexGrow,
  flexShrink,
  flexBasis,
  //justifySelf,    /* "justify-self" is ignored in CSS Flexbox layouts  */
  alignSelf,
  order
);

const gridElement = compose(gridColumn, gridRow, gridArea);

const styledBox = compose(
  space,
  layout,
  border,
  shadow,
  typography,
  flexElement,
  gridElement,
  color,
  position
);

// For elements inside Flexboxes
type FlexElementProps = StyledFlexProps &
  FlexGrowProps &
  FlexShrinkProps &
  FlexBasisProps &
  //JustifySelfProps &    /* "justify-self" is ignored in CSS Flexbox layouts  */
  AlignSelfProps &
  OrderProps;

// For elements inside Grids
type GridElementProps = StyledGridProps &
  GridColumnProps &
  GridRowProps &
  GridAreaProps;

export type BoxProps = SpaceProps &
  LayoutProps &
  BorderProps &
  ShadowProps &
  TypographyProps &
  FlexElementProps &
  GridElementProps &
  AlignContentProps &
  AlignItemsProps &
  JustifyContentProps &
  ColorProps &
  PositionProps & { color?: string };

export const Box = styled('div')<BoxProps & { as?: string }>(
  {
    boxSizing: 'border-box',
  },
  styledBox
);

export type FlexProps = BoxProps & FlexboxProps;

export const Flex = styled(Box)<FlexProps & { as?: string }>(
  {
    display: 'flex',
  },
  flexbox
);

const styledGrid = compose(grid, alignContent, alignItems, justifyContent);

export type GridProps = BoxProps;

export const Grid = styled(Box)<GridProps & { as?: string }>(
  {
    display: 'grid',
  },
  styledGrid
);

Grid.defaultProps = {
  gridColumnGap: [3],
  gridRowGap: [3],
};

export const Container = styled(Box)<BoxProps & { theme?: Theme; as?: string }>(
  ({ theme }: { theme: Theme }) => {
    return css`
      max-width: ${theme.maxGridWidth}px;
      @media ${theme.mediaQueries.tinyScreenOnly} {
        padding: ${theme.space[2]}px;
      }
    `;
  }
);

Container.defaultProps = {
  mx: 'auto',
  width: 1,
  flex: '1 1 0',
  px: 3,
  py: [3, null, 5],
};

export const Panel = styled(Box)<BoxProps & { theme?: Theme; as?: string }>(
  ({ theme }: { theme: Theme }) => ({
    padding: theme.space[3],
  }),
  styledBox
);

Panel.defaultProps = {
  bg: 'lightestGrey',
  border: '1px solid',
  borderColor: 'lightishGrey',
  borderRadius: [2],
};

export const Card = styled(Box)<BoxProps & { as?: string }>(styledBox);

Card.defaultProps = {
  bg: 'white',
  boxShadow: 'cards',
  borderColor: 'lightestGrey',
  borderRadius: [1],
  p: 3,
};
