import {
  ConfigurableMessage,
  ConfigurableMessagePlatform,
  ConfigurableMessageSection,
} from 'types/types';

export const getConfigurableMessage = (
  isVodapayActive: boolean,
  messages?: ConfigurableMessage[],
  section?: ConfigurableMessageSection | string
) => {
  const activeMessages = (messages || []).filter(
    message =>
      // is the section we're looking for
      (!section || message.section === section) &&
      // is for the active platform
      message.platform.includes(
        isVodapayActive
          ? ConfigurableMessagePlatform.vodapayMini
          : ConfigurableMessagePlatform.website
      )
  );

  return activeMessages.length > 0 ? activeMessages.pop() : undefined;
};
