import {
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
  useCallback,
} from 'react';
import Script from 'next/script';
import { VodapayContext, VodapayEventListener } from 'contexts/app';
import ErrorBoundary from 'components/_global/error-boundary';

export const VODAPAY_CLIENT_NAME = 'odo-vodapay-mini';

const AppEvent = {
  HandshakeInit: 'handshake-init',
  SetCustomerData: 'set-customer-data',
  AuthInit: 'auth-init',
  PaymentInit: 'payment-init',
  Debug: 'debug',
};

export const VodapayEvent = {
  HandshakeConfirmed: 'handshake-confirmed',
  StoredCustomerData: 'stored-customer-data',
  AuthSuccess: 'auth-success',
  AuthFailure: 'auth-failure',
  PaymentSuccess: 'payment-success',
  PaymentFailure: 'payment-failure',
};

// TODO: need the value not the key
// type OutgoingMessageEvent = keyof typeof AppEvent;
// type IncomingMessageEvent = keyof typeof VodapayEvent;

// TODO: full message objects with the exact data structure and event name

interface OutgoingMessage {
  event: string;
  data?: unknown;
}

interface IncomingMessage {
  event: string;
  data?: any; // TODO: unknowns and safe type checks
}

// TODO: full API type declaration
export interface VodapayApi {
  postMessage: (message: OutgoingMessage) => void;
  onMessage?: (message: IncomingMessage) => void;
  setStorage: (data: any) => void;
  getStorage: (data: any) => void;
  alert: (data: any) => void;
}

declare global {
  interface Window {
    my?: VodapayApi;
  }
}

type SetVodapay = Dispatch<SetStateAction<VodapayContext>>;

const useVodapayListener = ({
  setVodapay,
  eventListeners,
}: {
  setVodapay: SetVodapay;
  eventListeners: VodapayEventListener[];
}) => {
  const onMessage = useCallback(
    (e: IncomingMessage) => {
      if (!e.event) {
        return;
      }

      // TODO: identify the event type with some type guards (abort if event is invalid)

      switch (e.event) {
        case VodapayEvent.HandshakeConfirmed:
          setVodapay(vodapay => ({ ...vodapay, isActive: true }));

          // TODO: see if the user is already authorized, if so setCustomer in redux
          break;
        case VodapayEvent.StoredCustomerData:
          if (e.data) {
            // dispatch(setCustomer(e.data as Customer));
          }
          break;
      }

      // loop through event listeners, filter and run them
      eventListeners
        .filter(({ event }) => event === e.event)
        .map(({ callback }) => callback(e.data));
    },
    [setVodapay, eventListeners]
  );

  return onMessage;
};

export const VodapayListener = ({
  api,
  setVodapay,
  vodapayEventListeners,
}: {
  api: VodapayApi;
  setVodapay: SetVodapay;
  vodapayEventListeners: VodapayEventListener[];
}) => {
  const [initialized, setInitialized] = useState(false);

  const listener = useVodapayListener({
    setVodapay,
    eventListeners: vodapayEventListeners,
  });

  /**
   * Reset our onMessage function when needed.
   */
  useEffect(() => {
    api.onMessage = listener;

    if (!initialized) {
      // initialize handshake (specifically after we set up our onMessage listener for the first time)
      api.postMessage({ event: AppEvent.HandshakeInit });
      setInitialized(true);
    }
  }, [listener, api, initialized]);

  return null;
};

interface VodapayLoaderProps {
  setVodapay: SetVodapay;
  setVodapayApi: (api: VodapayApi) => void;
}

const VodapayLoaderInternal = ({
  setVodapay,
  setVodapayApi,
}: VodapayLoaderProps) => {
  const [retryWidgetLoad, setRetryWidgetLoad] = useState(30);

  /**
   * Get API object.
   */
  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (typeof window.my !== 'undefined') {
        const api = window.my;

        setVodapayApi(api);
        setVodapay(vodapay => ({
          ...vodapay,
          api,
          debug: data => api.postMessage({ event: AppEvent.Debug, data }),
          authInit: () => api.postMessage({ event: AppEvent.AuthInit }),
          paymentInit: (url: string) =>
            api.postMessage({ event: AppEvent.PaymentInit, data: url }),
        }));
      } else if (retryWidgetLoad > 0) {
        const timeoutId = setTimeout(
          () => setRetryWidgetLoad(retries => retries - 1),
          250
        );
        return () => clearTimeout(timeoutId);
      }
    }
  }, [retryWidgetLoad, setVodapay, setVodapayApi]);

  /**
   * Unfortunately their script url is completely invalid.
   * It works within the vodapay mini-program (presumably by intercepting the request and honoring it).
   * But will always fail when loaded outside of the mini-program shell.
   *
   * Even worse, this script is necessary in order to detect whether we're in the mini-program,
   * so we can't put checks in front of it to avoid the error.
   */
  return (
    <Script
      id="vodapay-sdk"
      src="https://appx/web-view.min.js"
      strategy="lazyOnload"
    />
  );
};

export const VodapayLoader = (props: VodapayLoaderProps) => (
  <ErrorBoundary>
    <VodapayLoaderInternal {...props} />
  </ErrorBoundary>
);
