import { css, Theme } from '@emotion/react';

export const productHoverStyle = (theme: Theme) => css`
  &:hover {
    .image {
      transform: scale(1.04);
    }
    .highlightOnHover {
      color: ${theme.colors.accent} !important;
    }
  }
  .image {
    transition: all 0.3s ease;
  }
`;

export enum ItemSizeEnum {
  extraSmall = 'extraSmall',
  small = 'small',
  medium = 'medium',
  large = 'large',
  extraLarge = 'extraLarge',
}

export type Sizes = ItemSizeEnum[];
