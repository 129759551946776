import { Customer, CustomerAddress, StoreCredit } from 'types/types';
import { createAction } from 'typesafe-actions';

export const login = createAction('@customer/LOGIN')<string>();
export const loginFromBroadcast = createAction(
  '@customer/LOGIN_FROM_BROADCAST'
)<string>();
export const logout = createAction('@customer/LOGOUT')();
export const logoutFromBroadcast = createAction(
  '@customer/LOGOUT_FROM_BROADCAST'
)();
export const setCustomer = createAction('@customer/SET_CUSTOMER')<Customer>();
export const setCustomerFromBroadcast = createAction(
  '@customer/SET_CUSTOMER_FROM_BROADCAST'
)<Customer>();
export const setSessionExpiresAt = createAction(
  '@customer/SET_SESSION_EXPIRES_AT'
)<number>();
export const setSessionExpired = createAction(
  '@customer/SET_SESSION_EXPIRED'
)();

export const addCustomerAddress = createAction(
  '@customer/ADD_CUSTOMER_ADDRESS'
)<CustomerAddress>();

export const updateCustomerAddress = createAction(
  '@customer/UPDATE_CUSTOMER_ADDRESS'
)<CustomerAddress>();

export const updateStoreCredit = createAction(
  '@customer/UPDATE_STORE_CREDIT'
)<StoreCredit>();

export const removeCustomerAddress = createAction(
  '@customer/REMOVE_CUSTOMER_ADDRESS'
)<string>();

export const updateCustomerSubscription = createAction(
  '@customer/UPDATE_CUSTOMER_SUBSCRIPTION'
)<boolean>();

export const setScrollPosition = createAction('@customer/SET_SCROLL_POSITION')<
  string | null
>();
