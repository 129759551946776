/**
 * We've added an eslint error to disallow usage of the `color` package directly
 * as it throws runtime errors when given invalid color codes. It's mostly used
 * within css which isn't an appropriate place for try/catch, and even if it were
 * most people wouldn't think to use one.
 */

// eslint-disable-next-line no-restricted-imports
import Color from 'color';

// note: direct usage of the theme is discouraged in most circumstances.
// DO NOT emulate the following without understanding what you're doing.
import { colors } from 'themes/onedayonly';

type ColorParam = string | ArrayLike<number> | number;

const color = (code?: ColorParam) => {
  try {
    return Color(code);
  } catch {
    return Color(colors.primary);
  }
};

export default color;
