import {
  CartShippingAddressInput,
  CustomerAddress,
  MutationRemoveStoreCreditFromCartArgs,
  MutationRemoveStoreCreditFromCartOutput,
  MutationSetStoreCreditOnCartArgs,
  MutationSetStoreCreditOnCartOutput,
  MutationSetShippingAddressOnCartArgs,
  MutationSetShippingAddressOnCartOutput,
  MutationSetGiftMessageOnCartOutput,
  MutationSetGiftMessageOnCartArgs,
  GiftMessageInput,
  MutationPlaceOrderOutput,
  MutationPlaceOrderArgs,
  MutationRemoveGiftMessageFromCartOutput,
  MutationRemoveGiftMessageFromCartArgs,
  MutationSetNewShippingAddressOnCartArgs,
  MutationSetNewShippingAddressOnCartOutput,
  MutationSetVoucherOnCartArgs,
  MutationSetVoucherOnCartOutput,
  MutationRemoveVoucherFromCartArgs,
  MutationRemoveVoucherFromCartOutput,
  MutationSetPaymentMethodOnCartOutput,
  MutationSetPaymentMethodOnCartArgs,
  QueryCheckoutSyncOutput,
  QueryCheckoutSyncArgs,
} from 'types/types';
import { useCallback, useEffect, useState } from 'react';
import { useSetCartIsLoading } from './cart';
import { useCustomerContext, useIsLoggedIn } from './customer';
import {
  useGraphQLErrorHandler,
  useErrorNotificationHandler,
} from './handle-errors';
import { RootState } from 'typesafe-actions';
import { ApolloError, useLazyQuery, useMutation } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { setCart } from '../redux/cart/actions';
import { setOrder, setStoreCreditRemaining } from '../redux/order/actions';
import { setLastCartId } from '../redux/order-cart/actions';
import {
  setLastShippingAddress,
  setCartIsLoading,
} from '../redux/global/actions';
import {
  addCustomerAddress,
  updateStoreCredit,
} from '../redux/customer/actions';
import checkoutSyncQuery from 'queries/checkoutSync.graphql';
import setStoreCreditOnCartMutation from 'mutations/setStoreCreditOnCart.graphql';
import removeStoreCreditFromCartMutation from 'mutations/removeStoreCreditFromCart.graphql';
import setNewShippingAddressOnCartMutation from 'mutations/setNewShippingAddressOnCart.graphql';
import setShippingAddressOnCartMutation from 'mutations/setShippingAddressOnCart.graphql';
import setGiftMessageOnCartMutation from 'mutations/setGiftMessageOnCart.graphql';
import placeOrderMutation from 'mutations/placeOrder.graphql';
import removeGiftMessageFromCartMutation from 'mutations/removeGiftMessageFromCart.graphql';
import removeVoucherFromCartMutation from 'mutations/removeVoucherFromCart.graphql';
import setVoucherOnCartMutation from 'mutations/setVoucherOnCart.graphql';
import setPaymentMethodOnCartMutation from 'mutations/setPaymentMethodOnCart.graphql';
import removeTypename from '../util/remove-typename';
import { getCookie } from '../util/cookies';

export const useCheckoutSync = (): [
  () => void,
  { loading: boolean; error: string; completed: boolean }
] => {
  const dispatch = useDispatch();
  const context = useCustomerContext();
  const isLoggedIn = useIsLoggedIn();
  const setCartIsLoading = useSetCartIsLoading();
  const gqlError = useGraphQLErrorHandler({
    showLogin: true,
    forgetCartIfNotFound: true,
  });

  const [error, setError] = useState('');
  const [completed, setCompleted] = useState(false);

  const cartId = useSelector((state: RootState) => state.cart.cart.id);

  const [
    checkoutSync,
    { called, loading, error: queryError, data },
  ] = useLazyQuery<QueryCheckoutSyncOutput, QueryCheckoutSyncArgs>(
    checkoutSyncQuery,
    { context, fetchPolicy: 'network-only', nextFetchPolicy: 'standby' }
  );

  const callback = useCallback(() => {
    if (isLoggedIn && cartId) {
      checkoutSync({ variables: { cartId } });
      setCompleted(false);
    }
  }, [checkoutSync, isLoggedIn, cartId]);

  useEffect(() => {
    if (called) {
      setCartIsLoading(loading);
    }
  }, [called, loading, setCartIsLoading]);

  useEffect(() => {
    if (queryError) {
      gqlError(queryError);

      const [firstError] = queryError.graphQLErrors;
      if (firstError) {
        setError(firstError.message);
      }
    }
  }, [gqlError, queryError]);

  useEffect(() => {
    if (data?.cart && !completed) {
      dispatch(setCart(data.cart));

      if (data.storeCredit) {
        dispatch(updateStoreCredit(data.storeCredit));
      }

      setCompleted(true);
    }
  }, [completed, data, data?.cart, dispatch]);

  return [callback, { loading, error, completed }];
};

export const useSetNewShippingAddress = (): [
  (values: CartShippingAddressInput) => void,
  {
    loading: boolean;
    error?: ApolloError;
    completed: boolean;
  }
] => {
  const dispatch = useDispatch();
  const context = useCustomerContext();
  const gqlError = useGraphQLErrorHandler({
    showLogin: true,
    forgetCartIfNotFound: true,
  });

  const [completed, setCompleted] = useState(false);
  const [savingInAddressBook, setSavingInAddressBook] = useState(false);
  const [addressNickname, setAddressNickname] = useState('');

  const cartId = useSelector((state: RootState) => state.cart.cart.id);

  const [setNewShippingAddress, { called, data, loading, error }] = useMutation<
    MutationSetNewShippingAddressOnCartOutput,
    MutationSetNewShippingAddressOnCartArgs
  >(setNewShippingAddressOnCartMutation, { context });

  const callback = useCallback(
    (input: CartShippingAddressInput) => {
      if (cartId) {
        setCompleted(false);
        setSavingInAddressBook(input.saveInAddressBook || false);
        setAddressNickname(input.address.label || '');

        setNewShippingAddress({
          variables: {
            cartId,
            input,
          },
        }).catch(gqlError);
      }
    },
    [cartId, setNewShippingAddress, setSavingInAddressBook, gqlError]
  );

  useEffect(() => {
    if (called) {
      dispatch(setCartIsLoading(loading));
    }
  }, [called, loading, dispatch]);

  useEffect(() => {
    if (data?.setNewShippingAddressOnCart) {
      dispatch(setCart(data.setNewShippingAddressOnCart));

      if (savingInAddressBook) {
        if (data.setNewShippingAddressOnCart.shippingAddress) {
          const { customerAddressId, ...address } = removeTypename(
            data.setNewShippingAddressOnCart.shippingAddress
          );
          dispatch(
            addCustomerAddress({
              ...address,
              id: customerAddressId,
              isDefaultBilling: false,
              isDefaultShipping: false,
              ...(addressNickname && { label: addressNickname }),
            })
          );
        }
      } else if (data.setNewShippingAddressOnCart.shippingAddress) {
        dispatch(
          setLastShippingAddress(
            data.setNewShippingAddressOnCart.shippingAddress
          )
        );
      }

      setCompleted(true);
    }
  }, [
    data,
    data?.setNewShippingAddressOnCart,
    data?.setNewShippingAddressOnCart.shippingAddress,
    dispatch,
    savingInAddressBook,
    addressNickname,
  ]);

  return [callback, { loading, error, completed }];
};

export const useSetShippingAddress = (): [
  (address: CustomerAddress, useForBilling: boolean) => void,
  {
    loading: boolean;
    error?: ApolloError;
  }
] => {
  const dispatch = useDispatch();
  const context = useCustomerContext();
  const gqlError = useGraphQLErrorHandler({
    showLogin: true,
    forgetCartIfNotFound: true,
  });
  const cartId = useSelector((state: RootState) => state.cart.cart.id);
  const [setShippingAddress, { called, data, loading, error }] = useMutation<
    MutationSetShippingAddressOnCartOutput,
    MutationSetShippingAddressOnCartArgs
  >(setShippingAddressOnCartMutation, { context });

  useEffect(() => {
    if (called) {
      dispatch(setCartIsLoading(loading));
    }
  }, [called, loading, dispatch]);

  const callback = useCallback(
    (address: CustomerAddress, useForBilling: boolean) => {
      if (cartId && address) {
        setShippingAddress({
          variables: {
            cartId,
            addressId: address.id,
            useForBilling,
          },
        })
          .then()
          .catch(gqlError);
      }
    },
    [cartId, setShippingAddress, gqlError]
  );

  useEffect(() => {
    if (data?.setShippingAddressOnCart) {
      dispatch(setCart(data.setShippingAddressOnCart));
    }
  }, [data, data?.setShippingAddressOnCart, dispatch]);

  return [callback, { loading, error }];
};

export const useSetPaymentMethod = (): [
  (method: string) => void,
  {
    loading: boolean;
    error?: ApolloError;
  }
] => {
  const dispatch = useDispatch();
  const context = useCustomerContext();
  const gqlError = useGraphQLErrorHandler({
    showLogin: true,
    forgetCartIfNotFound: true,
  });
  const cartId = useSelector((state: RootState) => state.cart.cart.id);
  const [
    setPaymentMethodOnCart,
    { data, loading, error, called },
  ] = useMutation<
    MutationSetPaymentMethodOnCartOutput,
    MutationSetPaymentMethodOnCartArgs
  >(setPaymentMethodOnCartMutation, { context });

  useEffect(() => {
    if (called) {
      dispatch(setCartIsLoading(loading));
    }
  }, [called, loading, dispatch]);

  const callback = useCallback(
    (method: string) => {
      if (cartId && method) {
        setPaymentMethodOnCart({
          variables: {
            cartId,
            method,
          },
        })
          .then()
          .catch(gqlError);
      }
    },
    [cartId, setPaymentMethodOnCart, gqlError]
  );

  useEffect(() => {
    if (data?.setPaymentMethodOnCart) {
      dispatch(setCart(data.setPaymentMethodOnCart));
    }
  }, [data, data?.setPaymentMethodOnCart, dispatch]);

  return [callback, { loading, error }];
};

export const useSetStoreCredit = (): [
  (amount: number) => void,
  {
    loading: boolean;
    error?: ApolloError;
  }
] => {
  const dispatch = useDispatch();
  const context = useCustomerContext();
  const gqlError = useGraphQLErrorHandler({
    showLogin: true,
    forgetCartIfNotFound: true,
  });
  const cartId = useSelector((state: RootState) => state.cart.cart.id);

  const [setStoreCredit, { called, loading, error, data }] = useMutation<
    MutationSetStoreCreditOnCartOutput,
    MutationSetStoreCreditOnCartArgs
  >(setStoreCreditOnCartMutation, { context });

  useEffect(() => {
    if (called) {
      dispatch(setCartIsLoading(loading));
    }
  }, [called, loading, dispatch]);

  const callback = useCallback(
    (amount: number) => {
      if (cartId && amount) {
        setStoreCredit({
          variables: {
            cartId,
            amount,
          },
        })
          .then()
          .catch(gqlError);
      }
    },
    [cartId, setStoreCredit, gqlError]
  );

  useEffect(() => {
    if (data?.setStoreCreditOnCart) {
      dispatch(setCart(data.setStoreCreditOnCart));
    }
  }, [data, data?.setStoreCreditOnCart, dispatch]);

  return [callback, { loading, error }];
};

export const useRemoveStoreCredit = (): [
  () => void,
  {
    loading: boolean;
    error?: ApolloError;
  }
] => {
  const dispatch = useDispatch();
  const context = useCustomerContext();
  const gqlError = useGraphQLErrorHandler({
    showLogin: true,
    forgetCartIfNotFound: true,
  });
  const cartId = useSelector((state: RootState) => state.cart.cart.id);

  const [removeStoreCredit, { called, loading, error, data }] = useMutation<
    MutationRemoveStoreCreditFromCartOutput,
    MutationRemoveStoreCreditFromCartArgs
  >(removeStoreCreditFromCartMutation, { context });

  useEffect(() => {
    if (called) {
      dispatch(setCartIsLoading(loading));
    }
  }, [called, loading, dispatch]);

  const callback = useCallback(() => {
    if (cartId) {
      removeStoreCredit({ variables: { cartId } }).then().catch(gqlError);
    }
  }, [cartId, removeStoreCredit, gqlError]);

  useEffect(() => {
    if (data?.removeStoreCreditFromCart) {
      dispatch(setCart(data.removeStoreCreditFromCart));
    }
  }, [data, data?.removeStoreCreditFromCart, dispatch]);

  return [callback, { loading, error }];
};

export const useSetVoucherOnCart = (): [
  (voucher: string) => void,
  {
    loading: boolean;
    error?: ApolloError;
  }
] => {
  const dispatch = useDispatch();
  const context = useCustomerContext();
  const gqlError = useGraphQLErrorHandler({
    showLogin: true,
    forgetCartIfNotFound: true,
  });
  const cartId = useSelector((state: RootState) => state.cart.cart.id);

  const [setVoucher, { called, loading, error, data }] = useMutation<
    MutationSetVoucherOnCartOutput,
    MutationSetVoucherOnCartArgs
  >(setVoucherOnCartMutation, { context });

  useEffect(() => {
    if (called) {
      dispatch(setCartIsLoading(loading));
    }
  }, [called, loading, dispatch]);

  const callback = useCallback(
    (voucher: string) => {
      if (cartId && voucher) {
        setVoucher({
          variables: {
            cartId,
            voucher,
          },
        })
          .then()
          .catch(gqlError);
      }
    },
    [cartId, setVoucher, gqlError]
  );

  useEffect(() => {
    if (data?.setVoucherOnCart) {
      dispatch(setCart(data.setVoucherOnCart));
    }
  }, [data, data?.setVoucherOnCart, dispatch]);

  return [callback, { loading, error }];
};

export const useRemoveVoucherFromCart = (): [
  () => void,
  {
    loading: boolean;
    error?: ApolloError;
  }
] => {
  const dispatch = useDispatch();
  const context = useCustomerContext();
  const gqlError = useGraphQLErrorHandler({
    showLogin: true,
    forgetCartIfNotFound: true,
  });
  const cartId = useSelector((state: RootState) => state.cart.cart.id);

  const [removeVoucher, { called, loading, error, data }] = useMutation<
    MutationRemoveVoucherFromCartOutput,
    MutationRemoveVoucherFromCartArgs
  >(removeVoucherFromCartMutation, { context });

  useEffect(() => {
    if (called) {
      dispatch(setCartIsLoading(loading));
    }
  }, [called, loading, dispatch]);

  const callback = useCallback(() => {
    if (cartId) {
      removeVoucher({
        variables: {
          cartId,
        },
      })
        .then()
        .catch(gqlError);
    }
  }, [cartId, removeVoucher, gqlError]);

  useEffect(() => {
    if (data?.removeVoucherFromCart) {
      dispatch(setCart(data.removeVoucherFromCart));
    }
  }, [data, data?.removeVoucherFromCart, dispatch]);

  return [callback, { loading, error }];
};

export const useRemoveGiftMessageFromCart = (): [
  () => void,
  {
    loading: boolean;
    error?: ApolloError;
  }
] => {
  const dispatch = useDispatch();
  const context = useCustomerContext();
  const gqlError = useGraphQLErrorHandler({
    showLogin: true,
    forgetCartIfNotFound: true,
  });
  const cartId = useSelector((state: RootState) => state.cart.cart.id);

  const [removeStoreCredit, { called, loading, error, data }] = useMutation<
    MutationRemoveGiftMessageFromCartOutput,
    MutationRemoveGiftMessageFromCartArgs
  >(removeGiftMessageFromCartMutation, { context });

  useEffect(() => {
    if (called) {
      dispatch(setCartIsLoading(loading));
    }
  }, [called, loading, dispatch]);

  const callback = useCallback(() => {
    if (cartId) {
      removeStoreCredit({
        variables: {
          cartId,
        },
      })
        .then()
        .catch(gqlError);
    }
  }, [cartId, removeStoreCredit, gqlError]);

  useEffect(() => {
    if (data?.removeGiftMessageFromCart) {
      dispatch(setCart(data.removeGiftMessageFromCart));
    }
  }, [data, data?.removeGiftMessageFromCart, dispatch]);

  return [callback, { loading, error }];
};

export const useSetGiftMessageOnCart = (): [
  (input: GiftMessageInput) => void,
  { complete: boolean; loading: boolean; error?: ApolloError }
] => {
  const dispatch = useDispatch();
  const context = useCustomerContext();
  const gqlError = useGraphQLErrorHandler({
    showLogin: true,
    forgetCartIfNotFound: true,
  });
  const cartId = useSelector((state: RootState) => state.cart.cart.id);
  const [complete, setComplete] = useState(false);

  const [setGiftMessage, { called, loading, error, data }] = useMutation<
    MutationSetGiftMessageOnCartOutput,
    MutationSetGiftMessageOnCartArgs
  >(setGiftMessageOnCartMutation, { context });

  useEffect(() => {
    if (called) {
      dispatch(setCartIsLoading(loading));
    }
  }, [called, loading, dispatch]);

  const callback = useCallback(
    (input: GiftMessageInput) => {
      if (cartId) {
        setComplete(false);
        setGiftMessage({
          variables: {
            cartId,
            input,
          },
        })
          .then()
          .catch(gqlError);
      }
    },
    [cartId, setGiftMessage, gqlError]
  );

  useEffect(() => {
    if (data?.setGiftMessageOnCart) {
      dispatch(setCart(data.setGiftMessageOnCart));
      setComplete(true);
    }
  }, [data, data?.setGiftMessageOnCart, dispatch]);

  return [callback, { complete, loading, error }];
};

export const usePlaceOrder = (): [
  (subscribe?: boolean) => void,
  {
    loading: boolean;
    hasCartError: boolean;
    error?: ApolloError;
    data?: MutationPlaceOrderOutput | null;
  }
] => {
  const dispatch = useDispatch();
  const context = useCustomerContext();
  const mountError = useErrorNotificationHandler();
  const gqlError = useGraphQLErrorHandler({
    showLogin: true,
    forgetCartIfNotFound: true,
  });
  const cartId = useSelector((state: RootState) => state.cart.cart.id);
  const sailthruHid = getCookie('sailthru_hid');
  const sailthruBid = getCookie('sailthru_bid');
  const [hasCartError, setHasCartError] = useState(false);

  const [placeOrder, { loading, error, data }] = useMutation<
    MutationPlaceOrderOutput,
    MutationPlaceOrderArgs
  >(placeOrderMutation, { context });

  const callback = useCallback(
    (subscribe?: boolean) => {
      if (cartId) {
        setHasCartError(false);
        placeOrder({
          variables: {
            cartId,
            ...(sailthruHid || sailthruBid
              ? {
                  orderData: {
                    sailthruHid,
                    sailthruBid,
                  },
                }
              : {}),
            ...(subscribe ? { subscribe } : {}),
          },
        })
          .then()
          .catch(gqlError);
      }
    },
    [cartId, placeOrder, sailthruHid, sailthruBid, gqlError]
  );

  useEffect(() => {
    if (data?.placeOrder.cart) {
      setHasCartError(true);
      dispatch(setCart(data.placeOrder.cart));

      mountError([
        new Error(
          data.placeOrder.cart.errors
            ? data.placeOrder.cart.errors[0]
            : 'There was an issue with items in your cart, please fix it and try again'
        ),
      ]);
    }
  }, [data, dispatch, mountError]);

  useEffect(() => {
    if (data?.placeOrder.order?.id) {
      dispatch(setLastCartId(cartId));
      dispatch(setOrder(data.placeOrder.order));
      dispatch(setStoreCreditRemaining(data.placeOrder.storeCreditRemaining));
    }
  }, [data, data?.placeOrder, cartId, dispatch]);

  return [callback, { data, loading, error, hasCartError }];
};

export const useOnlyGiftVouchers = () => {
  const cartItems = useSelector((state: RootState) => state.cart.cart.items);
  return cartItems.every(item => !!item.isGiftVoucher);
};
