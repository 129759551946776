import { InputHTMLAttributes } from 'react';
import {
  BorderRadiusProps,
  DisplayProps,
  FontSizeProps,
  FontWeightProps,
  LineHeightProps,
  MinWidthProps,
  SpaceProps,
  WidthProps,
  borderRadius,
  display,
  fontSize,
  fontWeight,
  lineHeight,
  minWidth,
  space,
  width,
  compose,
} from 'styled-system';
import styled from 'components/styled';

interface StyledDivProps {
  color?: string;
  fontColor?: string;
}

type Props = StyledDivProps &
  SpaceProps &
  FontSizeProps &
  WidthProps &
  FontWeightProps &
  LineHeightProps &
  BorderRadiusProps &
  MinWidthProps &
  DisplayProps;

const toHTML = str => ({ __html: str });

type RichTextProps = {
  content?: string;
  unstyled?: boolean;
} & InputHTMLAttributes<HTMLDivElement> &
  Props;

const StyledDiv = styled.div<Props>`
  ${compose(
    space,
    fontSize,
    width,
    fontWeight,
    lineHeight,
    borderRadius,
    minWidth,
    display
  )}
`;

StyledDiv.defaultProps = {
  fontSize: ['0.95rem', '1.2rem', 2],
  lineHeight: 1.4,
};

const Wrapper = ({ content, unstyled, ...restProps }: RichTextProps) => {
  if (unstyled) {
    return <div {...restProps} dangerouslySetInnerHTML={toHTML(content)} />;
  }

  return <StyledDiv {...restProps} dangerouslySetInnerHTML={toHTML(content)} />;
};

export default Wrapper;
