import {
  BorderRadiusProps,
  DisplayProps,
  FontSizeProps,
  FontWeightProps,
  LineHeightProps,
  MinWidthProps,
  SpaceProps,
  WidthProps,
  HeightProps,
  borderRadius,
  display,
  fontSize,
  fontWeight,
  lineHeight,
  minWidth,
  space,
  width,
  height,
  minHeight,
  compose,
  position,
  PositionProps,
} from 'styled-system';
import Color from '../../util/color';
import { css } from '@emotion/react';
import styled from 'components/styled';
import shouldForwardProp from '@styled-system/should-forward-prop';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const withoutSizeProp = fn => ({ size, ...rest }: any) => {
  void size;
  return fn(rest);
};

export interface ButtonProps {
  color?: string;
  size?: 'small' | 'medium' | 'large';
  variant?: 'solid' | 'outlined' | 'flat' | 'minimal';
  type?: 'button' | 'submit';
  fontColor?: string;
  hoverColor?: string;
  loading?: boolean;
  shadow?: boolean;
  stretch?: boolean;
  chonky?: boolean;
  shakeX?: boolean;
  shouldFocus?: boolean;
  loadingColor?: string;
  textTransform?:
    | 'none'
    | 'uppercase'
    | 'lowercase'
    | 'capitalize'
    | 'full-width'
    | 'full-size-kana';
}

type Props = ButtonProps &
  SpaceProps &
  FontSizeProps &
  WidthProps &
  HeightProps &
  FontWeightProps &
  LineHeightProps &
  BorderRadiusProps &
  MinWidthProps &
  DisplayProps &
  PositionProps;

const loadingStyle = (color: string) => css`
  position: relative;
  &::before {
    content: '';
    display: inline-block;
    position: absolute;
    border: 2px solid transparent;
    border-left-color: ${color};
    border-radius: 50%;
    box-sizing: border-box;
    top: 50%;
    left: 50%;
    margin-top: -12px;
    margin-left: -12px;
    width: 24px;
    height: 24px;

    @keyframes ld {
      0% {
        transform: rotate(0deg) scale(1);
      }
      50% {
        transform: rotate(180deg) scale(1);
      }
      100% {
        transform: rotate(360deg) scale(1);
      }
    }

    animation: ld 0.8s linear infinite;
  }
`;

export enum ButtonVariants {
  solid = 'solid',
  outlined = 'outlined',
  flat = 'flat',
  minimal = 'minimal',
}

export const Button = styled('button', {
  shouldForwardProp: prop => prop !== 'loading' && shouldForwardProp(prop),
})<Props>`
  ${({
    size = 'medium',
    color = 'primary',
    variant = 'solid',
    loading = false,
    stretch = false,
    shadow = false,
    shakeX = false,
    chonky = false,
    shouldFocus = true,
    loadingColor = 'white',
    textTransform = 'uppercase',
    fontColor: overrideFontColor,
    hoverColor: overrideHoverColor,
    theme,
  }) => {
    let backgroundColor;
    let fontColor;
    let border;
    let fontSize;
    let px;
    let py;
    let meta;

    let borderWidth = 1;

    switch (size) {
      case 'small':
        fontSize = theme.fontSizes[1];
        px = theme.space[3];
        py = theme.space[2];
        break;
      default:
      case 'medium':
        fontSize = theme.fontSizes[2];
        px = theme.space[3];
        py = theme.space[2];
        borderWidth = 2;
        break;
      case 'large':
        fontSize = theme.fontSizes[3];
        px = theme.space[4];
        py = theme.space[3];
        borderWidth = 2;
        break;
    }

    if (stretch) {
      px *= 3;
    }

    if (chonky) {
      py *= 1.5;
    }

    if (overrideHoverColor) {
      overrideHoverColor =
        theme.colors[overrideHoverColor] || overrideHoverColor;
    }

    switch (variant) {
      default:
      case 'solid':
        backgroundColor = theme.colors[color] || color;
        fontColor = Color(backgroundColor).isLight() ? 'black' : 'white';
        border = `${borderWidth}px solid ${backgroundColor}`;
        meta = css`
          &:active,
          &:focus,
          &:hover {
            background-color: ${overrideHoverColor
              ? overrideHoverColor
              : Color(backgroundColor).darken(0.1).string()};
          }
        `;
        break;
      case 'outlined':
        backgroundColor = 'transparent';
        fontColor = theme.colors[color] || color;
        border = `${borderWidth}px solid ${fontColor}`;
        meta = shouldFocus
          ? css`
              &:active,
              &:focus,
              &:hover {
                background-color: ${overrideHoverColor
                  ? overrideHoverColor
                  : Color(fontColor).fade(0.8).string()};
              }
            `
          : css`
              &:active,
              &:hover {
                background-color: ${overrideHoverColor
                  ? overrideHoverColor
                  : Color(fontColor).fade(0.8).string()};
              }
            `;
        break;
      case 'flat':
        backgroundColor = 'transparent';
        fontColor = theme.colors[color] || color;
        border = `${borderWidth}px solid transparent`;
        meta = css`
          &:active,
          &:focus,
          &:hover {
            color: ${overrideHoverColor
              ? overrideHoverColor
              : Color(fontColor).darken(0.2).string()};
          }
        `;
        break;
      case 'minimal':
        backgroundColor = 'transparent';
        fontColor = theme.colors[color] || color;
        border = 'none';
        meta = css`
          align-items: flex-start;
          padding: 0px;
          margin: 0px;
          &:active,
          &:focus,
          &:hover {
            color: ${overrideHoverColor
              ? overrideHoverColor
              : Color(fontColor).darken(0.2).string()};
          }
        `;
        break;
    }

    if (overrideFontColor) {
      fontColor = theme.colors[overrideFontColor] || overrideFontColor;
    }

    return css`
      transition: all 0.5s ease;
      &:hover {
        transition: all 0.3s ease;
      }
      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
      font-family: ${theme.fonts.header};
      letter-spacing: 0.8px;
      background-color: ${backgroundColor};
      color: ${fontColor};
      font-size: ${fontSize};
      padding: ${py}px ${px}px;
      border: ${border};
      font-weight: 700;
      outline: none;
      align-items: center;
      justify-content: center;
      display: flex;
      ${loading ? loadingStyle(theme.colors[loadingColor]) : ''}
      ${meta}
      ${textTransform !== 'none' &&
      css`
        text-transform: ${textTransform};
      `}
      ${shadow &&
      css`
        box-shadow: ${theme.shadows.buttons};
      `}
      ${shakeX &&
      css`
        animation: shakeX 0.75s ease-in-out;

        @keyframes shakeX {
          from,
          to {
            transform: translate3d(0, 0, 0);
          }

          10%,
          30% {
            transform: translate3d(-2px, 0, 0);
          }

          20%,
          40% {
            transform: translate3d(2px, 0, 0);
          }

          50%,
          70%,
          90% {
            transform: translate3d(-4px, 0, 0);
          }

          60%,
          80% {
            transform: translate3d(4px, 0, 0);
          }
        }
      `}
    `;
  }}

  ${withoutSizeProp(width)}
  ${withoutSizeProp(minWidth)}
  ${withoutSizeProp(height)}
  ${withoutSizeProp(minHeight)}
  ${compose(
    space,
    fontSize,
    fontWeight,
    lineHeight,
    borderRadius,
    display,
    position
  )}
`;

Button.defaultProps = {
  size: 'medium',
  color: 'primary',
  variant: 'solid',
  borderRadius: 1,
  type: 'button',
};

export default Button;
