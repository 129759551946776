import { logError } from './error-logger';
import config from './load-config';
import mem from 'mem';

export const trimTrailingSlash = (url: string) => url.replace(/\/$/, '');

export const trimUrl = (url: string) =>
  trimTrailingSlash(url.replace(/\s/g, ''));

export const canonicalUrl = mem((path: string) => {
  try {
    const url = new URL(path, config.site.url);

    url.search = '';
    url.hash = '';

    return trimTrailingSlash(url.toString());
  } catch (e) {
    logError(e, 'canonicalUrl');
  }

  return undefined;
});

export const isValidUrl = (url: string) => {
  const pattern = /^https?:\/\/([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
  return pattern.test(url);
};
