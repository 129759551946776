import { useState, useEffect } from 'react';
import { Box, Flex, Grid } from 'components/box';
import { css } from '@emotion/react';
import logo from 'public/app-logo-512.png';
import Button from 'components/button';
import { X as XIcon } from 'react-feather';
import { Text } from 'components/typography';
import { getCookie, setCookie } from '../../util/cookies';
import config from '../../util/load-config';
import { Link } from 'components/configurable-routing';

const COOKIE_SHOW_APP_INSTALL_VALUE = '1';
const COOKIE_SHOW_APP_INSTALL_NAME = 'appInstallBannerShown';
const COOKIE_SHOW_APP_INSTALL_AGE = 15;

const AppDownloadBanner = () => {
  const [installPrompt, setInstallPrompt] = useState(false);

  const viewButtonCallback = () => {
    setInstallPrompt(false);
    setCookie(
      COOKIE_SHOW_APP_INSTALL_NAME,
      COOKIE_SHOW_APP_INSTALL_VALUE,
      COOKIE_SHOW_APP_INSTALL_AGE,
      '/',
      config.security.cookies.domain,
      false,
      ''
    );
  };

  useEffect(() => {
    const appInstallBannerShown = getCookie(COOKIE_SHOW_APP_INSTALL_NAME);

    const preventInfoMiniBar = e => {
      e.preventDefault();
      setInstallPrompt(true);
    };

    if (appInstallBannerShown !== COOKIE_SHOW_APP_INSTALL_VALUE) {
      window.addEventListener('beforeinstallprompt', preventInfoMiniBar);
      return () =>
        window.removeEventListener('beforeinstallprompt', preventInfoMiniBar);
    }
  }, []);

  if (!installPrompt) {
    return null;
  }

  return (
    <Grid
      className="hide-for-desktop"
      backgroundColor="lightGrey"
      alignItems="center"
      gridTemplateColumns="auto 1fr auto"
      gridColumnGap={0}
    >
      <Button
        variant="flat"
        color="darkGrey"
        onClick={viewButtonCallback}
        p={3}
      >
        <XIcon size={16} />
      </Button>
      <Flex py={3}>
        <img
          src={logo.src}
          alt="App Icon"
          css={css`
            height: 40px;
            border-radius: 15%;
          `}
        />
        <Box
          css={css`
            height: 40px;
          `}
          ml="10px"
        >
          <Text fontSize="0.75rem" p="1px">
            OneDayOnly.co.za
          </Text>
          <Text fontSize="0.5rem" p="1px" mt="1px">
            OneDayOnly Offers (Pty) Ltd
          </Text>
          <Text fontSize="0.5rem" p="1px" mt="4px">
            FREE - on Google Play
          </Text>
        </Box>
      </Flex>
      <Link
        p={3}
        fontWeight="normal"
        href={config.mobileAppLinks.android.popup}
      >
        View
      </Link>
    </Grid>
  );
};

export default AppDownloadBanner;
