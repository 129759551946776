/**
 * Massively simplified version of:
 * https://github.com/jonschlinkert/omit-deep
 *
 * Dropped support for omitting properties of parent/class
 * objects, as it added unnecessary overhead.
 */

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isObject = (val: any) =>
  val != null && typeof val === 'object' && Array.isArray(val) === false;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const unset = (obj: any, prop: string) => {
  if (!isObject(obj)) {
    throw new TypeError('expected an object.');
  }
  if (obj.hasOwnProperty(prop)) {
    delete obj[prop];
    return true;
  }
  return true;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const omitDeep = (value: any, keys: string | string[]) => {
  if (typeof value === 'undefined') {
    return {};
  }

  if (Array.isArray(value)) {
    for (let i = 0; i < value.length; i++) {
      value[i] = omitDeep(value[i], keys);
    }
    return value;
  }

  if (!isObject(value)) {
    return value;
  }

  if (typeof keys === 'string') {
    keys = [keys];
  }

  if (!Array.isArray(keys)) {
    return value;
  }

  for (let j = 0; j < keys.length; j++) {
    unset(value, keys[j]);
  }

  for (const key in value) {
    if (value.hasOwnProperty(key)) {
      value[key] = omitDeep(value[key], keys);
    }
  }

  return value;
};

export default omitDeep;
