import { Theme, ThemeContext } from '@emotion/react';
import { ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import config from '../util/load-config';
import {
  dateDiff,
  dateIsoToObject as dateParse,
  secBefore,
} from '../util/date';
import { SET_TIMEOUT_MAX } from '../constants';
import {
  AddToCartCustomOption,
  CustomizableOptionValueInterface,
  ProductInterface,
} from 'types/types';
import { AppNotificationType } from 'contexts/app';
import { useRouterPush } from 'components/configurable-routing';
import { ButtonVariants } from 'components/button';
import { useAddNotification } from './notifications';
import { catalogPageAnchorLink } from '../util/products';
import { mapProductToPayload, trackCartEvent } from '../util/tag-manager';
import { EventNamesEnum, actionToStepMap } from 'types/third-party/gtm';

export const useCustomOptions = () => {
  const [areCustomOptionsValid, setAreCustomOptionsValid] = useState(false);
  const [addToCartOptions, setAddToCartOptions] = useState<
    AddToCartCustomOption[]
  >([]);
  const [selectedOption, setSelectedOption] = useState<
    CustomizableOptionValueInterface | undefined
  >(undefined);

  const setCustomOptionsState = useCallback(
    (customOptionsState: {
      areCustomOptionsValid: boolean;
      addToCartOptions: AddToCartCustomOption[];
      activeValue?: CustomizableOptionValueInterface;
    }) => {
      setAreCustomOptionsValid(customOptionsState.areCustomOptionsValid);
      setAddToCartOptions(customOptionsState.addToCartOptions);
      setSelectedOption(customOptionsState.activeValue);
    },
    [setSelectedOption]
  );

  return {
    areCustomOptionsValid,
    addToCartOptions,
    selectedOption,
    setCustomOptionsState,
  };
};

export const useAddedToCartNotification = () => {
  const push = useRouterPush();
  const addNotification = useAddNotification();

  const callback = useCallback(
    ({
      product,
      body,
      withContinueShoppingAction = true,
    }: {
      product: ProductInterface;
      body: ReactNode;
      withContinueShoppingAction?: boolean;
    }) => {
      const { linkProps } = catalogPageAnchorLink(product);

      addNotification(
        {
          type: AppNotificationType.ProductAddedToCart,
          actions: [
            {
              disableAllOtherActions: true,
              loadDismissOnClick: true,
              actionLabel: 'Take me to checkout',
              actionCallback: () => {
                trackCartEvent({
                  product: mapProductToPayload(product),
                  event: EventNamesEnum.beginCheckout,
                  stepNumber: actionToStepMap.checkout,
                  callback: () => push({ url: '/checkout' }),
                });
              },
            },
            ...(withContinueShoppingAction
              ? [
                  {
                    disableAllOtherActions: true,
                    loadDismissOnClick: true,
                    actionLabel: 'Continue Shopping',
                    actionCallback: () => push({ url: linkProps.href }),
                    variant: ButtonVariants.outlined,
                  },
                ]
              : []),
          ],
          body,
        },
        15000
      );
    },
    [addNotification, push]
  );

  return callback;
};

export const useCountdown = (
  from: string,
  to: string
): {
  arcDeg: number;
  clockColor: string;
  secRemaining: number;
  percentage: number;
} => {
  const theme = useContext(ThemeContext) as Theme;
  const [secRemaining, setSecRemaining] = useState(secBefore(to));
  const [total] = useState(
    Math.max(dateDiff(dateParse(to), dateParse(from)), 0)
  );

  const tick = useCallback(() => {
    const sec = secBefore(to);
    if (sec <= 0) {
      return false;
    }
    setSecRemaining(sec);
    return true;
  }, [setSecRemaining, to]);

  const percentage = Math.min((secRemaining / total) * 100, 100);
  const minRemaining = secRemaining / 60;
  const arcDeg = (360 * secRemaining) / total;

  let clockColor;
  if (minRemaining < 10) {
    clockColor = theme.colors.accent;
  } else if (minRemaining < 30) {
    clockColor = theme.colors.warning;
  } else {
    clockColor = theme.colors.darkLime;
  }

  useEffect(() => {
    if (to) {
      let intervalId;
      const timeoutId = setTimeout(() => {
        tick();
        intervalId = setInterval(
          () => !tick() && clearInterval(intervalId),
          1000
        );
      }, 1000 - new Date().getMilliseconds());

      return () => {
        clearInterval(intervalId);
        clearTimeout(timeoutId);
      };
    }
  }, [tick, to]);

  return { arcDeg, clockColor, secRemaining, percentage };
};

/**
 * NB: this is separate from countdown in order to avoid unnecessary re-renders.
 */
export const useIsTimedOut = (to: string, setInitial = true): boolean => {
  const [isTimedOut, setIsTimedOut] = useState(
    !setInitial || !to ? false : secBefore(to) <= 0
  );

  useEffect(() => {
    if (to) {
      const timeUntil = secBefore(to) * 1000;
      if (timeUntil > 0 && timeUntil < SET_TIMEOUT_MAX) {
        const timeoutId = setTimeout(() => {
          setIsTimedOut(true);
        }, timeUntil);
        return () => {
          clearTimeout(timeoutId);
        };
      }
    }
  }, [to]);

  return isTimedOut;
};

export const useIsEarly = (from: string): boolean => {
  const [isEarly, setIsEarly] = useState(!from ? false : secBefore(from) > 0);

  useEffect(() => {
    if (from) {
      const timeoutId = setTimeout(() => {
        setIsEarly(false);
      }, Math.min(secBefore(from) * 1000, SET_TIMEOUT_MAX));
      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [from]);

  return isEarly;
};

export const useCanCheckout = (to: string): boolean => {
  const grace = config.checkout.timedOutProductGracePeriodMinutes;
  const expiry = new Date(dateParse(to).getTime() + grace * 60000);
  return !useIsTimedOut(expiry.toString());
};

/**
 * Start out true as long as it isClearanceSale or not isTimedOut.
 * As those values won't change after getting to the client-side.
 *
 * Then update it inside of a useEffect based on the other values which could
 * have changed on the client-side (isStaffMember or isEarly).
 *
 * This should prevent render conflicts that can mess up the DOM and styling.
 */
export const useCanShowProduct = ({
  isEarly,
  isTimedOut,
  isStaffMember,
  isClearanceSale,
}: {
  isEarly: boolean;
  isTimedOut: boolean;
  isStaffMember: boolean;
  isClearanceSale: boolean;
}) => {
  const [canShowProduct, setCanShowProduct] = useState(
    isClearanceSale || !isTimedOut
  );

  useEffect(() => {
    if (!isClearanceSale && !isStaffMember && (isTimedOut || isEarly)) {
      setCanShowProduct(false);
    } else {
      setCanShowProduct(true);
    }
  }, [isClearanceSale, isStaffMember, isTimedOut, isEarly]);

  return canShowProduct;
};
