import { ApolloError } from '@apollo/client';
import { GraphQLErrorCategoryEnum } from 'types/types';
import config from './load-config';
import { trimUrl } from './url';
import { logError } from './error-logger';

const DEFAULT_REQUEST_TIMEOUT_MS = 10000;

interface GraphQlError {
  message: string;
  extensions?: {
    category?: string;
  };
}

type Output = unknown | undefined;
type Args = Record<string, any> | undefined;

/**
 * NOTE: not recommended for mutations.
 * @throws Error
 */
export const fetchGraphQl: <TData = Output, TVariables = Args>(args: {
  url?: string;
  operationName: string;
  query: string;
  variables?: TVariables;
  headers?: Record<string, string>;
  timeout?: number;
  logInvalidJSON?: (res: Response) => Promise<void>;
}) => Promise<{ data?: TData | null; errors?: GraphQlError[] }> = async ({
  url = trimUrl(config.gateway.uri),
  operationName,
  query,
  variables,
  headers: headersArg,
  timeout,
  logInvalidJSON,
}) => {
  const controller = new AbortController();
  const timeoutId = setTimeout(
    () => controller.abort(),
    timeout || DEFAULT_REQUEST_TIMEOUT_MS
  );

  let headers = { 'Content-Type': 'application/json' };
  if (headersArg) {
    headers = { ...headers, ...headersArg };
  }

  const res = await fetch(url, {
    method: 'POST',
    headers,
    signal: controller.signal,
    body: JSON.stringify({
      operationName,
      query,
      ...(variables ? { variables } : {}),
    }),
  });

  clearTimeout(timeoutId);

  /**
   * short-circuit if we want custom handling for HTML responses.
   * NOTE: importantly, this must be done before trying to parse the JSON,
   * else we won't be able to access the HTML from the response.
   */
  if (
    logInvalidJSON &&
    res.headers.get('content-type')?.includes('text/html')
  ) {
    logInvalidJSON(res);

    // throw a copy of the invalid JSON error we would normally get so that behaviour is consistent
    throw new Error(
      `invalid json response body at ${url} reason: Unexpected token < in JSON at position 0`
    );
  }

  return await res.json();
};

interface GraphQlDefinition {
  kind: 'OperationDefinition' | 'FragmentDefinition' | string;
  name: { value: string };
}

interface GraphQlAST {
  // query body
  loc: { source: { body: string } };
  // definition (including operation name)
  definitions: GraphQlDefinition[];
}

type QueryBody = string;
type QueryOperationName = string;

export const findOperationName = (query: GraphQlAST, fallback = 'query') =>
  query.definitions.find(def => def.kind === 'OperationDefinition')?.name
    .value || fallback;

// any number of directories, and a valid graphql filename (no numbers)
const graphQlImportRegex = new RegExp(/(\w+\/)*\w+\.graphql/);

/**
 * NOTE: expecting root imports to be in the following format:
 * #import "../fragments/path/to/file.graphql"
 * any nested imports must use relative paths, but cannot move up a directory only adjacent or further down
 */
const recursiveInlineFragment = async ({
  imports,
  queryBody,
  relativePath,
}: {
  imports: RegExpMatchArray | null;
  queryBody: string;
  relativePath: string;
}) => {
  if (!imports) return queryBody;

  // using a for loop instead of map/forEach so that we can use await
  for (const fullImport of imports) {
    // get the bit in quotes
    const quoted = fullImport.match(/"(.*?)"/);
    if (!quoted) continue;
    const [importPath] = quoted;

    // remove quotes, split by forward slash, and remove the first piece (as that's the reference we don't need anymore)
    const [_, ...pieces] = importPath.replace(/"/g, '').split('/');

    // we need the reconnected pieces for importing and then we'll test that it matches our expected format
    const path = pieces.join('/');
    if (!graphQlImportRegex.test(path)) continue;

    /**
     * NOTE: interestingly it appears that the `'../graphql/'` part needs to be hardcoded
     * as webpack doesn't like if the entire import is a variable.
     * The `'../graphql/'` is relative to this utility.
     */
    const fragment: GraphQlAST = await import(
      `../graphql/${relativePath}${path}`
    );
    queryBody += `\n${fragment.loc.source.body}`;

    // let's see how deep this rabbit hole goes
    const nestedImports = fragment.loc.source.body.match(/\#import .+/g);
    if (nestedImports) {
      // we need everything but the last piece (filename) for the sub path of any nested imports (with trailing slash)
      pieces.pop();
      const subPath = pieces.length > 0 ? `${[...pieces, ''].join('/')}` : '';

      queryBody = await recursiveInlineFragment({
        imports: nestedImports,
        queryBody,
        relativePath: `${relativePath}${subPath}`,
      });
    }
  }

  return queryBody;
};

export const parseQueryAST: (
  query: GraphQlAST,
  operationNameFallback?: string
) => Promise<[QueryBody, QueryOperationName]> = async (
  query,
  operationNameFallback = 'query'
) => {
  let queryBody = query.loc.source.body;

  if (query.definitions.some(def => def.kind === 'FragmentDefinition')) {
    try {
      const imports = query.loc.source.body.match(/\#import .+/g);

      queryBody = await recursiveInlineFragment({
        imports,
        queryBody,
        relativePath: '',
      });
    } catch (e) {
      logError(e, 'Error loading graphql fragment(s)');
    }
  }

  return [queryBody, findOperationName(query, operationNameFallback)];
};

export const isAbortError = (e: unknown) =>
  e instanceof Error && e.name === 'AbortError';

export const isApolloErrorType = (
  error: ApolloError | GraphQlError
): error is ApolloError => (error as ApolloError).graphQLErrors !== undefined;

export const errorCategory = (error: ApolloError | GraphQlError) => {
  if (isApolloErrorType(error)) {
    return error.graphQLErrors.reduce((cat, e) => {
      if (
        typeof cat === 'undefined' &&
        e.extensions &&
        e.extensions.hasOwnProperty('category')
      ) {
        cat = e.extensions.category;
      }
      return cat;
    }, undefined);
  }

  return error?.extensions?.category;
};

export const isAuthExpired = (error: ApolloError | GraphQlError) =>
  errorCategory(error) === GraphQLErrorCategoryEnum.AuthorizationExpired;

export const isNotFound = (error: ApolloError | GraphQlError) =>
  errorCategory(error) === GraphQLErrorCategoryEnum.NoSuchEntity;
