import { Cart } from 'types/types';
import { createAction } from 'typesafe-actions';

export const setCart = createAction('@cart/SET_CART')<Cart>();
export const setCartFromBroadcast = createAction(
  '@cart/SET_CART_FROM_BROADCAST'
)<Cart>();
export const setCartIsLoading = createAction(
  '@cart/SET_LOADING_CART'
)<boolean>();
export const forgetCart = createAction('@cart/FORGET_CART')();
