/**
 * TODO: much of this utility will become obsolete once the gateway is complete,
 * but for now it's incredibly useful if not life-saving ;)
 * especially the snake/camel case conversion.
 */
import { isPargoAddress, isPudoLocation } from 'types/guards/address';
import {
  AddressInput,
  CartAddressInput,
  CartAddressInterface,
  CustomerAddress,
  CustomerAddressInput,
  ShippingAddress,
  ExternalAddress,
  ExternalAddressFormInput,
  BaseAddressInput,
} from 'types/types';

export const makeCartAddress = ({
  firstName = '',
  lastName = '',
  phone = '',
  addressLine1 = '',
  addressLine2 = '',
  organisation = '',
  label = '',
  suburb = '',
  suburbId = '',
  postalCode = '',
  city = '',
  provinceId = '',
  provinceCode = '',
  province = '',
  countryId = '',
  country = '',
  specialInstructions,
  isDefaultBilling,
  isDefaultShipping,
}: AddressInput): CartAddressInput => ({
  firstName,
  lastName,
  phone,
  addressLine1,
  addressLine2,
  organisation,
  label,
  suburb: { id: suburbId, code: '', name: suburb },
  postalCode,
  city,
  province: { id: provinceId, code: provinceCode, name: province },
  country: { id: countryId, code: country, name: '' },
  deliveryInstructions: specialInstructions,
  ...(typeof isDefaultBilling !== 'undefined' ? { isDefaultBilling } : {}),
  ...(typeof isDefaultShipping !== 'undefined' ? { isDefaultShipping } : {}),
});

export const cartAddressAsInput = ({
  firstName,
  lastName,
  phone,
  addressLine1,
  addressLine2,
  organisation,
  label,
  suburb: { id: suburbId, code: suburbCode, name: suburbName },
  postalCode,
  city,
  province: { id: provinceId, code: provinceCode, name: provinceName },
  country: { id: countryId, code: countryCode, name: countryName },
  deliveryInstructions,
}: ShippingAddress): CartAddressInput => ({
  firstName,
  lastName,
  phone,
  addressLine1,
  addressLine2,
  organisation,
  label,
  suburb: { id: suburbId, code: suburbCode, name: suburbName },
  postalCode,
  city,
  province: { id: provinceId, code: provinceCode, name: provinceName },
  country: { id: countryId, code: countryCode, name: countryName },
  deliveryInstructions,
});

export const isValidAddress = (
  address: CartAddressInterface | CustomerAddress | ShippingAddress | undefined
): boolean | undefined => !address?.isInvalid;

export const externalToBaseAddress = (
  values: ExternalAddressFormInput,
  address: ExternalAddress
): BaseAddressInput | undefined => {
  if (isPargoAddress(address)) {
    return {
      ...values,
      addressLine1: address.storeName ?? '',
      addressLine2: address.address1,
      addressLine3: address.address2 ?? '',
      organisation: 'Pargo',
      postalCode: address.postalcode.toString(),
      city: address.city,
      pargoPupNumber: address.pargoPointCode,
      suburb: {
        id: '',
        code: address.suburb,
        name: address.suburb,
      },
      province: {
        id: address.province,
        code: address.province,
        name: address.province,
      },
      country: {
        id: 'ZA',
        code: 'ZA',
        name: 'South Africa',
      },
    };
  }
  if (isPudoLocation(address)) {
    return {
      ...values,
      addressLine1: address.addressLine1,
      addressLine2: address.addressLine2 ?? '',
      addressLine3: address.addressLine3 ?? '',
      organisation: 'Pudo',
      postalCode: address.postalCode,
      city: address.city,
      pudoLocationId: address.id,
      suburb: {
        id: '',
        code: address.suburb,
        name: address.suburb,
      },
      province: {
        id: address.province,
        code: address.province,
        name: address.province,
      },
      country: {
        id: 'ZA',
        code: 'ZA',
        name: 'South Africa',
      },
      latitude: address.latitude,
      longitude: address.longitude,
    };
  }
};
