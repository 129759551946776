import ReactGa from 'react-ga';
import config from './load-config';

export enum ReactGaTrackCategory {
  footer = 'Footer',
  faq = 'FAQ',
  informational = 'Informational',
}

export const track = (
  label?: string,
  category?: ReactGaTrackCategory,
  type: 'pageView' | 'event' = 'event'
) => {
  if (!window) return;
  ReactGa.initialize(config.analytics.trackingId);
  switch (type) {
    case 'pageView':
      try {
        ReactGa.pageview(
          `${window.location.pathname}${window.location.search}`
        );
      } catch (error) {
        console.error(error);
      }
      break;
    case 'event':
      if (category && label) {
        try {
          ReactGa.event({
            category,
            action: label,
            label,
          });
        } catch (error) {
          console.error(error);
        }
      }
      break;
  }
};
