/**
 * Shamelessly stolen from streamich https://github.com/streamich/use-media
 * because the util does not seem to be well maintained anymore.
 * Changed the deprecated event handlers.
 */
import { useState, useEffect, useLayoutEffect } from 'react';
import noop from '../util/noop';
import camelToHyphen from '../util/camel-to-hyphen';
import { LayoutEffect, MediaQueryObject } from 'types/types';

const QUERY_COMBINATOR = ' and ';

const queryObjectToString = (query: string | MediaQueryObject) => {
  if (typeof query === 'string') {
    return query;
  }

  return Object.entries(query)
    .map(([feature, value]) => {
      const convertedFeature = camelToHyphen(feature);
      let convertedValue = value;

      if (typeof convertedValue === 'boolean') {
        return convertedValue ? convertedFeature : `not ${convertedFeature}`;
      }

      if (
        typeof convertedValue === 'number' &&
        /[height|width]$/.test(convertedFeature)
      ) {
        convertedValue = `${convertedValue}px`;
      }

      return `(${convertedFeature}: ${convertedValue})`;
    })
    .join(QUERY_COMBINATOR);
};

const mockMediaQueryList: MediaQueryList = {
  media: '',
  matches: false,
  onchange: noop,
  addListener: noop,
  removeListener: noop,
  addEventListener: noop,
  removeEventListener: noop,
  dispatchEvent: (_: Event) => true,
};

const createUseMedia = (layoutEffect: LayoutEffect) => (
  rawQuery: string | MediaQueryObject,
  defaultState = false
) => {
  const [isMediaActive, setIsMediaActive] = useState(defaultState);
  const query = queryObjectToString(rawQuery);

  layoutEffect(() => {
    const mediaQueryList: MediaQueryList =
      typeof window === 'undefined'
        ? mockMediaQueryList
        : window.matchMedia(query);

    const onChange = () => setIsMediaActive(Boolean(mediaQueryList.matches));

    if ('addEventListener' in mediaQueryList) {
      mediaQueryList.addEventListener('change', onChange);
    } else if ('addListener' in mediaQueryList) {
      // Deprecated feature added to support Safari 12.1 and lower.
      mediaQueryList.addListener(onChange);
    }
    setIsMediaActive(mediaQueryList.matches);

    return () => {
      mediaQueryList.removeEventListener('change', onChange);
    };
  }, [query]);

  return isMediaActive;
};

export const useMedia = createUseMedia(useEffect);
export const useMediaLayout = createUseMedia(useLayoutEffect);

export default useMedia;
