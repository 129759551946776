import { css } from '@emotion/react';
import { Pagination } from 'react-instantsearch-dom';

const CustomPagination = ({ padding }: { padding: number }) => (
  <div
    css={theme => css`
      .ais-Pagination-item--selected,
      .ais-Pagination-link--selected,
      .ais-Pagination-link,
      .ais-Pagination-link:active,
      .ais-Pagination-link:hover {
        vertical-align: middle;
        font-family: 'Montserrat', sans-serif;
        box-shadow: none;
        font-weight: 500;
        background-image: none;
        font-size: 1rem;
        line-height: 1;
      }
      .ais-Pagination-link--selected {
        color: ${theme.colors.primary};
        font-weight: bold;
      }
    `}
  >
    <Pagination padding={padding} />
  </div>
);

export default CustomPagination;
