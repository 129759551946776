import { getCookie, setCookie } from '../../../util/cookies';
import config from '../../../util/load-config';
import { useCallback, useEffect, useState } from 'react';
import { ClientOnly } from 'components/_shared/widgets/render-control';
import dynamic from 'next/dynamic';

const Popup = dynamic(() => import('./popup'));

const COOKIE_NAME = 'isCookieNotificationSet';
const COOKIE_VALUE = 'yes';
const COOKIE_AGE = 90;
const TIMEOUT = 3000;

const CookieConsent = () => {
  const [show, setShow] = useState(false);

  const dismiss = useCallback(() => {
    setCookie(
      COOKIE_NAME,
      COOKIE_VALUE,
      COOKIE_AGE,
      '/',
      config.security.cookies.domain,
      true
    );
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      const isCookieNotificationSet = getCookie(COOKIE_NAME);
      if (!isCookieNotificationSet) setShow(true);
    }, TIMEOUT);
    return () => clearTimeout(timeoutId);
  }, []);

  if (!show) {
    return null;
  }

  return <Popup dismiss={dismiss} />;
};

const Wrapper = () => (
  <ClientOnly>
    <CookieConsent />
  </ClientOnly>
);

export default Wrapper;
