import { useRef, ReactElement } from 'react';
import { useState, useEffect } from 'react';
import { ConnectedSearchResultsOverlay } from 'components/_shared/widgets/search';
import {
  InstantSearch,
  Configure as SearchConfig,
} from 'react-instantsearch-dom';
import algoliasearch from 'algoliasearch/lite';
import config from '../../../../util/load-config';

const SearchWrapper = ({
  searchIndex,
  isSearchActive,
  close,
  children,
}: {
  searchIndex: string;
  isSearchActive: boolean;
  close: () => void;
  children: ReactElement;
}) => {
  const [isMobileFiltersActive, setIsMobileFiltersActive] = useState(false);

  const searchClient = useRef(
    algoliasearch(config.algolia.appId, config.algolia.apiKey)
  ).current;

  useEffect(() => {
    if (isSearchActive) {
      document.body.classList.add('noscroll');
    }

    return () => {
      document.body.classList.remove('noscroll');
    };
  }, [isSearchActive]);

  return (
    <InstantSearch indexName={searchIndex} searchClient={searchClient}>
      <SearchConfig clickAnalytics />

      {children}

      {isSearchActive && (
        <ConnectedSearchResultsOverlay
          index={searchIndex}
          isMobileFiltersActive={isMobileFiltersActive}
          showMobileFilters={() => setIsMobileFiltersActive(true)}
          hideMobileFilters={() => setIsMobileFiltersActive(false)}
          closeSearch={close}
        />
      )}
    </InstantSearch>
  );
};

export default SearchWrapper;
