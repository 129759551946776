import {
  AddCartItemInput,
  QueryCartArgs,
  QueryCartOutput,
  CartItemInterface,
  MutationAddCartItemsArgs,
  MutationUpdateCartItemsArgs,
  MutationRemoveCartItemsArgs,
  MutationAddCartItemsOutput,
  MutationUpdateCartItemsOutput,
  MutationRemoveCartItemsOutput,
  MutationMergeCartsArgs,
  MutationMergeCartsOutput,
  ExternalAddressFormInput,
  CartAddressInput,
  Cart,
  ConfigurableMessageSection,
  ExternalAddress,
} from 'types/types';
import { RootState } from 'typesafe-actions';
import { setCart } from '../redux/cart/actions';
import {
  addToast,
  setCartIsLoading as setCartIsLoadingAction,
  setHasSyncedOutdatedCart,
} from '../redux/global/actions';
import { useCustomer, useCustomerContext } from './customer';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIsTimedOut } from './product';
import { useGraphQLErrorHandler } from './handle-errors';
import { ApolloError, useLazyQuery, useMutation } from '@apollo/client';
import { dateIsoToObject, dateObjectToIso, isCurrentDate } from '../util/date';
import config from '../util/load-config';
import cartQuery from 'queries/cart.graphql';
import addCartItemsMutation from 'mutations/addCartItems.graphql';
import updateCartItemsMutation from 'mutations/updateCartItems.graphql';
import removeCartItemsMutation from 'mutations/removeCartItems.graphql';
import mergeCartsMutation from 'mutations/mergeCarts.graphql';
import { useSetNewShippingAddress } from './checkout';
import { trackCartEvent, mapCartItemToPayload } from '../util/tag-manager';
import { EventNamesEnum } from 'types/third-party/gtm';
import { useVodapayActive } from './app';
import { getConfigurableMessage } from '../util/configurable-messages';
import { externalToBaseAddress } from '../util/addresses';

export const useIsCartLoading = () =>
  useSelector((state: RootState) => state.global.isCartLoading);

export const useSetCartIsLoading = () => {
  const dispatch = useDispatch();

  return useCallback(
    (isLoading: boolean) => void dispatch(setCartIsLoadingAction(isLoading)),
    [dispatch]
  );
};

export const useCartConfigMessage = (section?: ConfigurableMessageSection) => {
  const isVodapayActive = useVodapayActive();
  const messages = useSelector((state: RootState) => state.cart.cart?.messages);

  return getConfigurableMessage(isVodapayActive, messages, section);
};

export const useSyncCart = (): [
  () => void,
  { loading: boolean; error: string; completed: boolean }
] => {
  const dispatch = useDispatch();
  const context = useCustomerContext();
  const setCartIsLoading = useSetCartIsLoading();
  const gqlError = useGraphQLErrorHandler({
    showLogin: true,
    forgetCartIfNotFound: true,
  });

  const [error, setError] = useState('');
  const [completed, setCompleted] = useState(false);

  const cartId = useSelector((state: RootState) => state.cart.cart.id);

  const [cart, { called, loading, error: queryError, data }] = useLazyQuery<
    QueryCartOutput,
    QueryCartArgs
  >(cartQuery, {
    context,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'standby',
  });

  const callback = useCallback(() => {
    if (cartId) {
      cart({ variables: { cartId } });
      setCompleted(false);
    }
  }, [cart, cartId]);

  useEffect(() => {
    if (called) {
      setCartIsLoading(loading);
    }
  }, [called, loading, setCartIsLoading]);

  useEffect(() => {
    if (queryError) {
      gqlError(queryError);

      const [firstError] = queryError.graphQLErrors;
      if (firstError) {
        setError(firstError.message);
      }
    }
  }, [gqlError, queryError]);

  useEffect(() => {
    if (data?.cart && !completed) {
      dispatch(setCart(data.cart));
      setCompleted(true);
    }
  }, [completed, data, data?.cart, dispatch]);

  return [callback, { loading, error, completed }];
};

export const useSyncOutdatedCart = () => {
  const dispatch = useDispatch();
  const [syncCart] = useSyncCart();

  const lastSyncedAt = useSelector(
    (state: RootState) => state.cart.lastSyncedAt
  );
  const hasSyncedOutdatedCart = useSelector(
    (state: RootState) => state.global.hasSyncedOutdatedCart
  );

  useEffect(() => {
    if (
      lastSyncedAt &&
      !hasSyncedOutdatedCart &&
      !isCurrentDate([lastSyncedAt])
    ) {
      dispatch(setHasSyncedOutdatedCart());
      syncCart();
    }
  }, [lastSyncedAt, hasSyncedOutdatedCart, syncCart, dispatch]);
};

export const useAddItemToCart = ({
  onComplete,
  disableErrorToast,
}: {
  onComplete?: (args: { cart: Cart; message?: string }) => void;
  disableErrorToast?: boolean;
}): [
  (input: AddCartItemInput) => void,
  {
    loading: boolean;
    completed: boolean;
    error?: ApolloError | undefined;
    message: string | undefined;
  }
] => {
  const isVodapayActive = useVodapayActive();

  const dispatch = useDispatch();
  const context = useCustomerContext();
  const setCartIsLoading = useSetCartIsLoading();
  const gqlError = useGraphQLErrorHandler({ logout: true, showLogin: true });

  const completedRef = useRef(false);
  const [completed, setCompleted] = useState(false);
  const [message, setMessage] = useState<string | undefined>(undefined);

  const cartId = useSelector((state: RootState) => state.cart.cart.id);

  const [addToCart, { called, loading, data, error }] = useMutation<
    MutationAddCartItemsOutput,
    MutationAddCartItemsArgs
  >(addCartItemsMutation, { context });

  const callback = useCallback(
    (input: AddCartItemInput) => {
      if (completed) {
        setCompleted(false);
        setMessage(undefined);
      }

      completedRef.current = false;

      input.quantity = parseFloat(input.quantity.toString());

      addToCart({
        variables: {
          cartId: cartId || undefined,
          input: [input],
          recaptcha: '',
        },
      })
        .then()
        .catch(e => {
          gqlError(e, { disableErrorToast });
        });
    },
    [completed, addToCart, cartId, disableErrorToast, gqlError]
  );

  useEffect(() => {
    if (called) {
      setCartIsLoading(loading);
    }
  }, [called, loading, setCartIsLoading]);

  useEffect(() => {
    if (completedRef.current) return;

    if (data?.addCartItems) {
      dispatch(setCart(data.addCartItems));

      const message = getConfigurableMessage(
        isVodapayActive,
        data.addCartItems?.messages,
        ConfigurableMessageSection.addedToCart
      )?.message;

      setMessage(message);
      setCompleted(true);

      completedRef.current = true;

      if (onComplete) onComplete({ cart: data.addCartItems, message });
    }
  }, [data?.addCartItems, dispatch, isVodapayActive, onComplete]);

  return [callback, { loading, completed, error, message }];
};

export const useUpdateCartItem = (): [
  (id: string, quantity: number) => void,
  {
    loading: boolean;
    error?: ApolloError;
  }
] => {
  const dispatch = useDispatch();
  const context = useCustomerContext();
  const setCartIsLoading = useSetCartIsLoading();
  const gqlError = useGraphQLErrorHandler({
    showLogin: true,
    forgetCartIfNotFound: true,
  });

  const cartId = useSelector((state: RootState) => state.cart.cart.id);

  const [updateCartItem, { called, loading, error, data }] = useMutation<
    MutationUpdateCartItemsOutput,
    MutationUpdateCartItemsArgs
  >(updateCartItemsMutation, { context });

  useEffect(() => {
    if (called) {
      setCartIsLoading(loading);
    }
  }, [called, loading, dispatch, setCartIsLoading]);

  const callback = useCallback(
    (id: string, quantity: number) => {
      if (cartId && id && quantity) {
        updateCartItem({
          variables: {
            cartId,
            input: [{ id, quantity: parseFloat(quantity.toString()) }],
          },
        })
          .then()
          .catch(gqlError);
      }
    },
    [cartId, updateCartItem, gqlError]
  );

  useEffect(() => {
    if (data?.updateCartItems) {
      dispatch(setCart(data.updateCartItems));
    }
  }, [data, data?.updateCartItems, dispatch]);

  return [callback, { loading, error }];
};

export const useRemoveCartItem = (): [
  (id: string) => void,
  {
    loading: boolean;
    error?: ApolloError;
  }
] => {
  const dispatch = useDispatch();
  const context = useCustomerContext();
  const setCartIsLoading = useSetCartIsLoading();
  const gqlError = useGraphQLErrorHandler({
    showLogin: true,
    forgetCartIfNotFound: true,
  });

  const cartId = useSelector((state: RootState) => state.cart.cart.id);
  const items = useSelector((state: RootState) => state.cart.cart.items);

  const user = useCustomer();

  const [removeCartItem, { called, loading, error, data }] = useMutation<
    MutationRemoveCartItemsOutput,
    MutationRemoveCartItemsArgs
  >(removeCartItemsMutation, { context });

  useEffect(() => {
    if (called) {
      setCartIsLoading(loading);
    }
  }, [called, loading, dispatch, setCartIsLoading]);

  const callback = useCallback(
    (id: string) => {
      if (cartId && id) {
        const removedCartItem = items.find(item => item.id === id);
        // This is done before on purpose.
        if (removedCartItem) {
          trackCartEvent({
            product: mapCartItemToPayload(removedCartItem),
            event: EventNamesEnum.removeFromCart,
            user,
          });
        }

        removeCartItem({ variables: { cartId, input: [id] } })
          .then()
          .catch(gqlError);
      }
    },
    [cartId, removeCartItem, gqlError, items, user]
  );

  useEffect(() => {
    if (data?.removeCartItems) {
      dispatch(setCart(data.removeCartItems));
    }
  }, [data, data?.removeCartItems, dispatch]);

  return [callback, { loading, error }];
};

interface MergeCartsInput {
  sourceCartId: string;
  destinationCartId: string | null;
}

// TODO: confirm if we'll ever need this again, and if not, remove it
const useMergeCarts = (): [
  (input: MergeCartsInput) => void,
  {
    loading: boolean;
    error?: ApolloError;
    completed: boolean;
  }
] => {
  const dispatch = useDispatch();
  const context = useCustomerContext();

  const [completed, setCompleted] = useState(false);
  const [input, setInput] = useState<MergeCartsInput | null>(null);

  const gqlError = useGraphQLErrorHandler({
    showLogin: true,
    notFoundCallback: () => {
      setInput(null);
      setCompleted(true);
    },
  });

  const cartId = useSelector((state: RootState) => state.cart.cart.id);

  const [mergeCarts, { called, loading, error, data }] = useMutation<
    MutationMergeCartsOutput,
    MutationMergeCartsArgs
  >(mergeCartsMutation, { context });

  const callback = useCallback((input: MergeCartsInput) => setInput(input), []);

  useEffect(() => {
    if (input && context.headers && !called) {
      mergeCarts({
        variables: {
          sourceCartId: input.sourceCartId,
          destinationCartId: input.destinationCartId || cartId,
        },
      })
        .then()
        .catch(gqlError);
    }
  }, [called, cartId, context, context.headers, input, mergeCarts, gqlError]);

  useEffect(() => {
    if (data?.mergeCarts) {
      dispatch(setCart(data.mergeCarts));
      setCompleted(true);
    }
  }, [data, data?.mergeCarts, dispatch]);

  return [callback, { loading, error, completed }];
};

export const useCartItemInspector = (
  item: CartItemInterface
): {
  loweredQuantity: number | null;
  isExpiringSoon: boolean;
  isExpired: boolean;
  showSellingFastPill: boolean;
} => {
  const grace = config.checkout.timedOutProductGracePeriodMinutes;
  const graceMilliseconds = grace * 60000;
  const expiry = item.product.activeToDate
    ? dateObjectToIso(
        new Date(
          dateIsoToObject(item.product.activeToDate).getTime() +
            graceMilliseconds
        )
      )
    : '';

  const isExpired = useIsTimedOut(item.isExpired ? '' : expiry);
  const isExpiringSoon = useIsTimedOut(item.product.activeToDate || '', false);

  return {
    loweredQuantity:
      !!item.quantityLeft && item.quantityLeft < item.quantity
        ? item.quantityLeft
        : null,

    isExpiringSoon,
    isExpired: item.isExpired || isExpired,

    showSellingFastPill:
      !!item.quantityLeft &&
      item.quantityLeft < config.notifications.lowStockWarningThreshold,
  };
};

export const useAddExternalAddress = (): [
  (values: ExternalAddressFormInput, address: ExternalAddress) => void,
  { loading?: boolean; completed?: boolean; error?: ApolloError }
] => {
  const dispatch = useDispatch();
  const [
    setNewShippingAddress,
    { loading, completed, error },
  ] = useSetNewShippingAddress();

  const callback = useCallback(
    (values: ExternalAddressFormInput, address: ExternalAddress) => {
      if (!address) return;

      const transformedAddress = externalToBaseAddress(values, address);

      if (transformedAddress) {
        const externalAsCartAddress: CartAddressInput = {
          ...transformedAddress,
          deliveryInstructions: '',
        };
        setNewShippingAddress({
          address: externalAsCartAddress,
          // TODO: allow customers to decide if they want to useForBilling & saveInAddressBook
          useForBilling: true,
          saveInAddressBook: true,
        });
      } else {
        dispatch(
          addToast({
            body: 'Address update failed. Please try again.',
            variant: 'error',
            timeout: 5000,
          })
        );
      }
    },
    [dispatch, setNewShippingAddress]
  );

  return [callback, { loading, completed, error }];
};
