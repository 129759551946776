import { ReturnItem } from 'types/types';
import { setReturnItems, clearReturns } from './actions';
import { createReducer } from 'typesafe-actions';

type InitialStateType = {
  returnItems: ReturnItem[];
};

const initialState: InitialStateType = {
  returnItems: [],
};

const returnReducer = createReducer(initialState)
  .handleAction(setReturnItems, (state, { payload }) => ({
    ...state,
    returnItems: payload,
  }))
  .handleAction(clearReturns, state => ({
    ...state,
    returnItems: [],
  }));

export default returnReducer;
export type returnState = ReturnType<typeof returnReducer>;
