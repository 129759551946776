import primary, {
  colors as baseColors,
  colorPalette as baseColorPalette,
  gradientConfettiPalette as baseGradientConfettiPalette,
} from './onedayonly';

const colorPalette = {
  ...baseColorPalette,
  clearanceYellow: '#D75401',
};

const colors = {
  ...baseColors,
  headerPrimary: colorPalette.clearanceYellow,
  headerSecondary: colorPalette.blue,
  secondary: colorPalette.blue,
};

const gradientConfettiPalette = {
  ...baseGradientConfettiPalette,
  default: {
    start: '#F36E21',
    middle: '#F36E21',
    end: '#D75401',
    confetti: '#C3044F',
  },
};

const theme = {
  ...primary,
  name: 'clearancesale',
  colors,
  gradientConfettiPalette,
};

Object.freeze(theme);

export default theme;

// TODO: Clean up the gradients
