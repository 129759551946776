import { combineReducers } from 'redux';
import { persistReducer, createMigrate } from 'redux-persist';
import cart from './cart/reducer';
import order from './order/reducer';
import orderCart from './order-cart/reducer';
import global from './global/reducer';
import localforage from 'localforage';
import customer from './customer/reducer';
import returns from './return/reducer';

const isServer = typeof window === 'undefined';

export const storage = !isServer
  ? localforage.createInstance({
      name: 'redux',
    })
  : null;

const persistReducerFn = (config, reducer): typeof reducer => {
  if (isServer) {
    return reducer;
  }
  config.timeout = 20000;
  return persistReducer(config, reducer);
};

// NOTE: The state is not typed, we might want to build a typing system using something like
// https://gist.github.com/lafiosca/b7bbb569ae3fe5c1ce110bf71d7ee153

// How to write a migration: Here we will add a new key-value pair to the orderCart (as an example)
// <version as number>: state => {
//     return {
//       ...state,
//       orderCart: {
//         ...state.orderCart,
//         newValue: 'test',
//       },
//     };
//   },

const migrations = {
  // migration to version 0 (no changes to redux state structure)
  0: state => state,
};

// also remember to update the current version
const migrationConfig = {
  version: 0,
  migrate: createMigrate(migrations, { debug: false }),
};

export default combineReducers({
  customer: persistReducerFn(
    { key: 'customer', storage, ...migrationConfig },
    customer
  ) as typeof customer,
  order: persistReducerFn(
    { key: 'order', storage, ...migrationConfig },
    order
  ) as typeof order,
  orderCart: persistReducerFn(
    { key: 'orderCart', storage, ...migrationConfig },
    orderCart
  ) as typeof orderCart,
  cart: persistReducerFn(
    { key: 'cart', storage, blacklist: ['loading'], ...migrationConfig },
    cart
  ) as typeof cart,
  return: persistReducerFn(
    { key: 'return', storage, blacklist: ['loading'], ...migrationConfig },
    returns
  ) as typeof returns,
  global,
});
