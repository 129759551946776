import { createReducer } from 'typesafe-actions';
import { setLastCartId } from './actions';

interface State {
  lastCartId: null | string;
}

const initialState: State = {
  lastCartId: null,
};

const orderCartReducer = createReducer(initialState).handleAction(
  setLastCartId,
  (state, { payload }) => ({
    ...state,
    lastCartId: payload,
  })
);

export default orderCartReducer;
export type orderCartState = ReturnType<typeof orderCartReducer>;
