import { css, Theme } from '@emotion/react';
import Label, { LabelProps } from 'components/_shared/elements/label';
import { useId, InputHTMLAttributes } from 'react';

const componentStyle = (theme: Theme) => css`
  display: inline-block;
  margin-right: 0.5rem;

  label {
    line-height: 1.5;
  }

  input[type='radio'] {
    position: absolute;
    opacity: 0;
  }
  input[type='radio'] + label {
    position: relative;
    cursor: pointer;
    padding: 0;
  }
  input[type='radio'] + label:before {
    content: '';
    display: inline-block;
    vertical-align: text-top;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: ${theme.colors.mediumGrey};
    margin-top: 0px;
  }
  input[type='radio'] + label:after {
    content: '';
    position: absolute;
    left: 3px;
    top: 3px;
    background: white;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    margin-top: 0px;
  }
  input[type='radio']:hover + label:before {
    background: ${theme.colors.primary};
  }
  input[type='radio']:focus + label:before {
    background: ${theme.colors.primary};
  }
  input[type='radio']:checked + label:before,
  input[type='radio']:focus + label:before {
    background: ${theme.colors.primary};
    border-radius: 50%;
  }
  input[type='radio']:disabled + label {
    color: #b8b8b8;
    cursor: auto;
  }
  input[type='radio']:disabled + label:before {
    box-shadow: none;
    background: ${theme.colors.mediumGrey};
  }
  input[type='radio']:disabled + label:after {
    content: '';
    position: absolute;
    left: 3px;
    top: 3px;
    background: ${theme.colors.lightGrey};
    width: 14px;
    height: 14px;
    border-radius: 50%;
    margin-top: 0px;
  }
  input[type='radio']:checked + label:after,
  input[type='radio']:focus + label:after {
    content: '';
    position: absolute;
    left: 3px;
    top: 3px;
    background: ${theme.colors.primary};
    width: 14px;
    height: 14px;
    border-radius: 50%;
    box-shadow: inset 0px 0px 0px 2px #fff;
    margin-top: 0px;
  }
`;

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  labelProps?: LabelProps;
}

const RadioButton = ({ label, labelProps, ...restProps }: Props) => {
  const id = useId();

  // Radio button with label prop
  if (label) {
    return (
      <Label px={2} fontWeight={400} color="black" {...labelProps}>
        <div css={theme => componentStyle(theme)}>
          <input {...restProps} type="radio" id={id} />
          {/* eslint-disable-next-line */}
          <label htmlFor={id} />
        </div>{' '}
        {label}
      </Label>
    );
  }

  // Radio button without a label prop
  return (
    <div css={theme => componentStyle(theme)}>
      <input {...restProps} type="radio" id={id} />
      {/* eslint-disable-next-line */}
      <label htmlFor={id} />
    </div>
  );
};

export default RadioButton;
