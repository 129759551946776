import { BROADCAST_CHANNEL_ID, tabId } from './constants';
import { BroadcastActionsEnum } from './types';

const BROADCAST_CHANNEL_TIMEOUT = 10000;

export function generateUniqueTabId() {
  const date = new Date();
  const timestamp = `${date.getUTCSeconds()}-${date.getUTCMilliseconds()}`;
  const randNum = Math.floor(Math.random() * 10000);
  return `${timestamp}-${randNum}`;
}

let sendingChannel;
let timeoutId;

export const broadcast = (
  actionType: BroadcastActionsEnum,
  payload: unknown
) => {
  if (typeof BroadcastChannel !== 'undefined') {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    if (!sendingChannel) {
      sendingChannel = new BroadcastChannel(BROADCAST_CHANNEL_ID);
    }
    sendingChannel.postMessage({ actionType, payload, tabId });
    timeoutId = setTimeout(() => {
      sendingChannel.close();
      sendingChannel = null;
    }, BROADCAST_CHANNEL_TIMEOUT);
  }
};
