/* eslint-disable react/display-name, sort-imports */
import { FunctionComponent, ReactNode } from 'react';

import config from '../../util/load-config';

interface Props {
  children: ReactNode[] | ReactNode;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const withConfig = function (WrappedComponent): FunctionComponent<any> {
  return function (props: Props) {
    const { children } = props;
    return (
      <WrappedComponent config={config} {...props}>
        {children}
      </WrappedComponent>
    );
  };
};

export default withConfig;
