import { useContext } from 'react';
import AppContext from 'contexts/app';
import { useQuery } from '@apollo/client';
import {
  ConfigurableMessageSection,
  QueryConfigMessagesOutput,
} from 'types/types';
import configMessagesQuery from 'queries/configMessages.graphql';
import { getConfigurableMessage } from '../util/configurable-messages';

export const useVodapay = () => {
  const { vodapay } = useContext(AppContext);
  return vodapay;
};

export const useVodapayActive = () => {
  const {
    vodapay: { isActive },
  } = useContext(AppContext);
  return isActive;
};

export const useSetVodapayEventListeners = () => {
  const { setVodapayEventListeners } = useContext(AppContext);
  return setVodapayEventListeners;
};

export const useNavigationBarActive = () => {
  const { isNavigationBarActive } = useContext(AppContext);
  return isNavigationBarActive;
};

export const useHasGlobalStatus = (status: string) => {
  const { globalStatuses } = useContext(AppContext);
  return globalStatuses.includes(status);
};

export const useSetGlobalStatuses = () => {
  const { setGlobalStatuses } = useContext(AppContext);
  return setGlobalStatuses;
};

export const useAddGlobalStatus = () => {
  const setGlobalStatuses = useSetGlobalStatuses();
  return (status: string) =>
    setGlobalStatuses(statuses => [
      ...statuses.filter(s => s !== status),
      status,
    ]);
};

export const useRemoveGlobalStatus = () => {
  const setGlobalStatuses = useSetGlobalStatuses();
  return (status: string) =>
    setGlobalStatuses(statuses => statuses.filter(s => s !== status));
};

const useConfigMessagesQuery = () => {
  const { data } = useQuery<QueryConfigMessagesOutput>(configMessagesQuery);
  return data;
};

export const useGlobalConfigMessage = (
  section?: ConfigurableMessageSection
) => {
  const isVodapayActive = useVodapayActive();
  const data = useConfigMessagesQuery();

  return getConfigurableMessage(
    isVodapayActive,
    data?.configs.messages,
    section
  );
};
