import { useCallback, ReactNode } from 'react';
import NextLink from 'next/link';
import { RoutingConfig } from 'types/interface';
import {
  Link as Anchor,
  LinkProps as AnchorProps,
  styledLink,
} from 'components/typography';
import { useRouter } from 'next/router';
import { format, UrlObject } from 'url';
import config from '../../util/load-config';
import mem from 'mem';
import { useVodapayActive } from 'hooks/app';
import styled from 'components/styled';

// TODO: move this to config maybe
const vodapayHrefMap = {
  '/': '/vodapay',
  '/clearance-sale': '/vodapay-clearance-sale',
};

const absoluteUrl = /^(http|https|mailto)?:/i;

const findType = mem(
  (config: RoutingConfig, href: string) => {
    if (absoluteUrl.test(href)) {
      return 'server';
    }
    return (
      (config.overrides || []).find(({ regex }) => {
        return new RegExp(regex, 'g').test(href);
      })?.type || config.default
    );
  },
  { cacheKey: ([_, href]) => href }
);

interface RouterPushCallback {
  url: UrlObject | string;
  replace?: boolean;
  dynamicUrl?: string;
}

export const useRouterPush = (): ((params: RouterPushCallback) => void) => {
  const vodapayIsActive = useVodapayActive();
  const { push, replace } = useRouter();

  const callback = useCallback(
    ({ url, replace: isReplace, dynamicUrl }: RouterPushCallback) => {
      const formattedUrl = typeof url === 'object' ? format(url) : url;
      const type = findType(config.routing, formattedUrl);

      // url objects require a domain, but we just want it to split the pathname, search, and hash
      const urlObject = new URL(formattedUrl, 'https://example.com');

      const finalUrl =
        vodapayIsActive && urlObject.pathname in vodapayHrefMap
          ? `${absoluteUrl.test(formattedUrl) ? urlObject.origin : ''}${
              vodapayHrefMap[urlObject.pathname]
            }${urlObject.search}${urlObject.hash}`
          : formattedUrl;

      if (type === 'server' && typeof window !== 'undefined') {
        if (isReplace) {
          window.location.replace(finalUrl);
        } else {
          window.location.href = finalUrl;
        }
      } else if (dynamicUrl) {
        if (isReplace) {
          replace(dynamicUrl, finalUrl);
        } else {
          push(dynamicUrl, finalUrl);
        }
      } else if (isReplace) {
        replace(finalUrl);
      } else {
        push(finalUrl);
      }
    },
    [push, replace, vodapayIsActive]
  );

  return callback;
};

export type LinkProps = AnchorProps & {
  children: ReactNode | ReactNode[] | string | string[];
  href: string;
  dynamicUrl?: string;
  prefetch?: boolean;
};

const StyledNextLink = styled(NextLink)<LinkProps & { prefetch?: boolean }>(
  styledLink
);
StyledNextLink.defaultProps = {
  color: 'primary',
  display: 'inline',
  fontSize: 'inherit',
  fontFamily: 'inherit',
  fontWeight: 'bold',
};

type LinkTypeProps = LinkProps & { renderType: 'server' | 'client' };

const BasicLink = ({
  children,
  href,
  dynamicUrl,
  prefetch,
  renderType,
  ...rest
}: LinkTypeProps) => {
  if (renderType === 'server') {
    return (
      <Anchor href={href} {...rest}>
        {children}
      </Anchor>
    );
  }

  if (dynamicUrl) {
    return (
      <StyledNextLink
        href={dynamicUrl}
        as={href}
        prefetch={prefetch}
        passHref
        {...rest}
      >
        {children}
      </StyledNextLink>
    );
  }

  return (
    <StyledNextLink href={href} prefetch={prefetch} passHref {...rest}>
      {children}
    </StyledNextLink>
  );
};

/**
 * Will update certain links to point to a vodapay alternative/equivalent
 */
const DynamicVodapayLink = ({ href: baseHref, ...rest }: LinkTypeProps) => {
  const vodapayIsActive = useVodapayActive();
  const href =
    vodapayIsActive && baseHref in vodapayHrefMap
      ? vodapayHrefMap[baseHref]
      : baseHref;

  return <BasicLink href={href} {...rest} />;
};

export const Link = ({
  href,
  target,
  ...rest
}: LinkProps & { prefetch?: boolean }) => {
  const type = findType(config.routing, href);

  if (href in vodapayHrefMap) {
    return <DynamicVodapayLink href={href} renderType={type} {...rest} />;
  }

  return (
    <BasicLink
      href={href}
      renderType={type}
      target={target}
      rel={target === '_blank' ? 'noopener noreferrer' : undefined}
      {...rest}
    />
  );
};
