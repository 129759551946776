import { Customer } from 'types/types';
import {
  login,
  logout,
  setSessionExpiresAt,
  setSessionExpired,
  setCustomer,
  addCustomerAddress,
  updateCustomerAddress,
  removeCustomerAddress,
  updateStoreCredit,
  setScrollPosition,
  updateCustomerSubscription,
  logoutFromBroadcast,
  loginFromBroadcast,
  setCustomerFromBroadcast,
} from './actions';
import { createReducer } from 'typesafe-actions';
import { broadcast } from 'components/_global/broadcast-channel/util';
import { BroadcastActionsEnum } from 'components/_global/broadcast-channel/types';

// TODO: better structure. customer.currentCustomer is kinda dumb
interface State {
  currentCustomer: Customer | null;
  // TODO: move to the global redux... but it must be persisted
  // (maybe have global & "app" redux, one persisted, the other not)
  token: string;
  sessionExpiresAt: number | null;
  sessionExpired: boolean;
  // TODO: also move off of the customer redux, but ensure persistance (and call it anchor instead)
  scrollPosition: string | null;
}

const initialState: State = {
  currentCustomer: null,
  token: '',
  sessionExpiresAt: null,
  sessionExpired: false,
  scrollPosition: null,
};

const customerReducer = createReducer(initialState)
  .handleAction(login, (state, { payload }) => {
    broadcast(BroadcastActionsEnum.Login, payload);
    return {
      ...state,
      token: payload,
    };
  })
  .handleAction(loginFromBroadcast, (state, { payload }) => ({
    ...state,
    token: payload,
  }))
  .handleAction(logout, state => {
    broadcast(BroadcastActionsEnum.Logout, null);
    return {
      ...state,
      ...initialState,
    };
  })
  .handleAction(logoutFromBroadcast, state => ({
    ...state,
    ...initialState,
  }))
  .handleAction(setSessionExpiresAt, (state, { payload }) => ({
    ...state,
    sessionExpiresAt: payload,
  }))
  .handleAction(setScrollPosition, (state, { payload }) => ({
    ...state,
    scrollPosition: payload,
  }))
  .handleAction(setSessionExpired, state => ({
    ...state,
    sessionExpired: true,
  }))
  .handleAction(setCustomer, (state, { payload }) => {
    broadcast(BroadcastActionsEnum.SetCustomer, payload);
    return {
      ...state,
      currentCustomer: payload,
    };
  })
  .handleAction(setCustomerFromBroadcast, (state, { payload }) => ({
    ...state,
    currentCustomer: payload,
  }))
  .handleAction(updateCustomerAddress, (state, { payload }) => ({
    ...state,
    currentCustomer: state.currentCustomer
      ? {
          ...state.currentCustomer,
          addresses: state.currentCustomer.addresses?.map(addr => {
            if (addr.id === payload.id) {
              return payload;
            }

            const newAddr = { ...addr };
            if (payload.isDefaultBilling && newAddr.isDefaultBilling) {
              newAddr.isDefaultBilling = false;
            }
            if (payload.isDefaultShipping && newAddr.isDefaultShipping) {
              newAddr.isDefaultShipping = false;
            }
            return newAddr;
          }),
        }
      : null,
  }))
  .handleAction(addCustomerAddress, (state, { payload }) => ({
    ...state,
    currentCustomer: state.currentCustomer
      ? {
          ...state.currentCustomer,
          addresses: [
            ...(state.currentCustomer.addresses || []).map(addr => {
              const newAddr = { ...addr };
              if (payload.isDefaultBilling && newAddr.isDefaultBilling) {
                newAddr.isDefaultBilling = false;
              }
              if (payload.isDefaultShipping && newAddr.isDefaultShipping) {
                newAddr.isDefaultShipping = false;
              }
              return newAddr;
            }),
            payload,
          ],
        }
      : null,
  }))
  .handleAction(updateStoreCredit, (state, { payload }) => ({
    ...state,
    currentCustomer: state.currentCustomer
      ? {
          ...state.currentCustomer,
          storeCredit: payload,
        }
      : null,
  }))
  .handleAction(removeCustomerAddress, (state, { payload }) => ({
    ...state,
    currentCustomer: state.currentCustomer
      ? {
          ...state.currentCustomer,
          addresses: state.currentCustomer.addresses?.filter(
            addr => addr.id !== payload
          ),
        }
      : null,
  }))
  .handleAction(updateCustomerSubscription, (state, { payload }) => ({
    ...state,
    currentCustomer: state.currentCustomer
      ? {
          ...state.currentCustomer,
          isSubscribed: payload,
        }
      : null,
  }));

export default customerReducer;
export type CustomerState = ReturnType<typeof customerReducer>;
