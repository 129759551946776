import { useContext, useEffect, useState, ReactElement } from 'react';
import { Theme, ThemeContext } from '@emotion/react';
import useMedia from 'hooks/use-media';

export type ScreenType = keyof Theme['mediaQueries'];

export const useHasMounted = () => {
  const [hasMounted, setHasMounted] = useState(false);
  useEffect(() => {
    setHasMounted(true);
  }, []);
  return hasMounted;
};

/**
 * Will only render the child component on the provided `screen`-size.
 * - The screen check is run both on SSR & CSR but is always `false` on SSR
 * - Thus it is recommended to use `RenderControl` in most cases as its useEffect is cheaper on SSR
 * - The best/only time to use this component is if you're already preventing SSR from a parent component
 * - NB: *DON'T* use to show/hide simple elements. Especially when a media query will do the trick
 *
 * @see RenderControl
 */
export const ClientScreen = ({
  children,
  screen,
}: {
  children: ReactElement;
  screen: ScreenType;
}) => {
  const theme = useContext(ThemeContext) as Theme;
  const isScreen = useMedia(theme.mediaQueries[screen]);
  if (!isScreen) {
    return null;
  }
  return children;
};

/**
 * Control when to render a child component.
 * - Intended only to prevent unnecessary rendering of complex components
 * - NB: *DON'T* use to show/hide simple elements. Especially when a media query will do the trick
 *
 * Usage examples:
 * - Always render on SSR, but then remove from all but mobile on CSR: `type="server" screen="mobileOnly"`
 * - Only render on CSR from tablet up: `type="client" screen="tabletUp"`
 *
 * @see ClientScreen
 */
export const RenderControl = ({
  type,
  children,
  screen,
}: {
  type: 'server' | 'client' | 'both';
  children: ReactElement;
  screen?: ScreenType;
}) => {
  if (type === 'server' && !screen) {
    console.warn('Using "RenderControl" for SSR with no screen is redundant.');
  }

  const hasMounted = useHasMounted();
  if (!hasMounted) {
    // we don't use ClientScreen on SSR because the media checks always return false
    return type !== 'client' ? children : null;
  }

  if (screen) {
    return <ClientScreen screen={screen}>{children}</ClientScreen>;
  }

  return children;
};

/**
 * Feature parity with `components/client-only`. Added (optional) `screen` prop allows for greater control.
 */
export const ClientOnly = ({
  children,
  screen,
}: {
  children: ReactElement;
  screen?: ScreenType;
}) => (
  <RenderControl type="client" screen={screen}>
    {children}
  </RenderControl>
);

/**
 * Always render on SSR, then de-render on `screen`-sizes that don't match.
 */
export const ServerAlways = ({
  children,
  screen,
}: {
  children: ReactElement;
  screen: ScreenType;
}) => (
  <RenderControl type="server" screen={screen}>
    {children}
  </RenderControl>
);

export default RenderControl;
