import { Flex } from 'components/box';
import { Heading } from 'components/typography';
import { trackBackToHomePageClick } from '../../../util/tag-manager';
import Button from 'components/button';
import { Link } from 'components/configurable-routing';

const MoreProductsFooter = ({ message }: { message: string }) => (
  <Flex
    aria-label={message}
    py={4}
    flexDirection="column"
    alignItems="center"
    backgroundColor="lightestGrey"
    boxShadow="medium"
  >
    <Heading fontSize={[5, 6]} textAlign="center">
      {message}
    </Heading>
    <Link href="/" onClick={() => trackBackToHomePageClick()}>
      <Button width="auto" mt={[3, 4]}>
        Take me to today&apos;s deals
      </Button>
    </Link>
  </Flex>
);

export default MoreProductsFooter;
