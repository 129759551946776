import AppContext, { AppNotification } from 'contexts/app';
import { useCallback, useContext } from 'react';

export const useAddNotification = () => {
  const { setNotifications } = useContext(AppContext);

  return useCallback(
    (notification: AppNotification, timeout?: number) => {
      setNotifications(notifications => {
        if (notification.isSingular) {
          return [
            ...notifications.filter(v => v.type !== notification.type),
            notification,
          ];
        }
        return [...notifications, notification];
      });

      if (typeof timeout !== 'undefined' && timeout > 0) {
        setTimeout(() => {
          setNotifications(notifications =>
            notifications.filter(v => v !== notification)
          );
        }, timeout);
      }
    },
    [setNotifications]
  );
};
