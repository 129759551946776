import {
  CustomerAddress,
  PargoAddressType,
  PargoMessage,
  PudoLocation,
  PudoMessage,
  ShippingAddress,
  ExternalAddress,
} from 'types/types';

export const isCustomerAddress = (
  address: CustomerAddress | ShippingAddress
): address is CustomerAddress =>
  typeof (address as CustomerAddress).isDefaultBilling === 'boolean' ||
  typeof (address as CustomerAddress).isDefaultShipping === 'boolean' ||
  typeof (address as CustomerAddress).deliveryInstructions === 'boolean';

export const isPargoMessage = (
  message: PargoMessage | PudoMessage
): message is PargoMessage =>
  typeof (message as PargoMessage).data?.pargoPointCode === 'string';

export const isPudoMessage = (
  message: PargoMessage | PudoMessage
): message is PudoMessage =>
  typeof (message as PudoMessage).data?.selectedLocker === 'object' &&
  typeof (message as PudoMessage).data?.selectedLocker.openingHours ===
    'string';

export const isPargoAddress = (
  address: ExternalAddress
): address is PargoAddressType => {
  const pargoAddress = address as PargoAddressType;
  return (
    typeof pargoAddress.addressSms === 'string' &&
    typeof pargoAddress.businessHours === 'string' &&
    typeof pargoAddress.pargoPointCode === 'string' &&
    typeof pargoAddress.storeOwner === 'string'
  );
};

export const isPudoLocation = (
  address: ExternalAddress
): address is PudoLocation => {
  const pargoAddress = address as PudoLocation;
  return (
    typeof pargoAddress.id === 'string' &&
    typeof pargoAddress.name === 'string' &&
    typeof pargoAddress.openingHours === 'string' &&
    typeof pargoAddress.address === 'string' &&
    typeof pargoAddress.addressLine1 === 'string'
  );
};

export const isODOAddress = (
  address: ShippingAddress | CustomerAddress
): address is CustomerAddress | ShippingAddress =>
  !address.pargoPupNumber && !address.pudoLocationId;
