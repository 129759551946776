import { css } from '@emotion/react';
import styled from 'components/styled';
import {
  borderRadius,
  BorderRadiusProps,
  display,
  DisplayProps,
  fontSize,
  FontSizeProps,
  fontWeight,
  FontWeightProps,
  lineHeight,
  LineHeightProps,
  minWidth,
  MinWidthProps,
  space,
  SpaceProps,
  width,
  WidthProps,
  textAlign,
  TextAlignProps,
  compose,
} from 'styled-system';

type Props = SpaceProps &
  FontSizeProps &
  WidthProps &
  FontWeightProps &
  LineHeightProps &
  BorderRadiusProps &
  MinWidthProps &
  DisplayProps &
  TextAlignProps;

export const FormError = styled.div<Props>`
  ${({ theme }) => {
    return css`
      font-family: ${theme.fonts.body};
      font-size: ${theme.fontSizes[1]};
      display: block;
      width: 100%;
      margin: 0;
      color: ${theme.colors.pink};
    `;
  }}
  ${compose(
    space,
    fontSize,
    width,
    fontWeight,
    lineHeight,
    borderRadius,
    minWidth,
    display,
    textAlign
  )}
`;

FormError.defaultProps = {
  mt: 1,
};

export default FormError;
