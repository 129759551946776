import { NextPageContext } from 'next';

// TODO: fix samesite. Valid values are Strict, Lax or None, not ''
type SameSite = 'strict' | 'lax' | 'none' | '';

export const setCookie = (
  name: string,
  value: string,
  days = 7,
  path = '/',
  domain = '',
  secure = true,
  sameSite: SameSite = 'strict'
) => {
  if (typeof document === 'undefined') {
    return;
  }
  const expires = new Date(Date.now() + days * 86400000).toUTCString();

  let cookie = `${encodeURIComponent(name)}=${encodeURIComponent(
    value
  )}; expires=${expires}`;

  if (0 > days) {
    cookie += `; Max-Age=-99999999`;
  }

  if (domain) {
    cookie += `; domain=${domain}`;
  }
  if (path) {
    cookie += `; path=${path}`;
  }
  if (secure) {
    cookie += '; secure';
  }
  if (sameSite) {
    cookie += `; samesite=${sameSite}`;
  }
  document.cookie = cookie;
};

const getCookieValue = (cookie: string, name: string) => {
  if (!cookie) {
    return '';
  }
  return cookie.split('; ').reduce((r, v) => {
    const parts = v.split('=');
    return parts[0] === name ? decodeURIComponent(parts[1]) : r;
  }, '');
};

export const getCookie = (name: string) => {
  if (
    typeof document === 'undefined' ||
    typeof document.cookie === 'undefined'
  ) {
    return '';
  }
  return getCookieValue(document.cookie, name);
};

export const getCookieFromContext = (ctx: NextPageContext, name: string) =>
  getCookieValue(ctx.req?.headers.cookie || '', name);

export const deleteCookie = (
  name: string,
  path = '/',
  domain = '',
  secure = true,
  sameSite: SameSite = 'strict'
) => {
  setCookie(name, '', -100, path, domain, secure, sameSite);
};
