import { createContext } from 'react';

export interface LayoutContextType {
  newsletterModalIsOpen: boolean;
  setNewsletterModalIsOpen: (flag: boolean) => void;
}

export const LayoutContext = createContext<LayoutContextType>({
  newsletterModalIsOpen: false,
  setNewsletterModalIsOpen: () => null,
});
