export const PAYMENT_METHOD_WIRECARD_CREDIT_CARD = 'wirecardCreditCard';
export const PAYMENT_METHOD_WIRECARD_MOBICRED = 'wirecardMobicred';
export const PAYMENT_METHOD_OZOW = 'ozow';
export const PAYMENT_METHOD_ZAPPER = 'zapper';
export const PAYMENT_METHOD_SNAPSCAN = 'snapScan';
export const PAYMENT_METHOD_EBUCKS = 'eBucks';
export const PAYMENT_METHOD_PAYU_CREDIT_CARD = 'payuCreditCard';
export const PAYMENT_METHOD_PAYU_DISCOVERY_MILES = 'payuDiscoveryMiles';
export const PAYMENT_METHOD_PEACH_CREDIT_CARD = 'peachCreditCard';
export const PAYMENT_METHOD_PEACH_MOBICRED = 'peachMobicred';
export const PAYMENT_METHOD_PEACH_1VOUCHER = 'peach1Voucher';
export const PAYMENT_METHOD_BANK_TRANSFER = 'eft';
export const PAYMENT_METHOD_STORE_CREDIT = 'storeCredit';
export const PAYMENT_METHOD_VODAPAY = 'vodaPay';
export const PAYMENT_METHOD_FREE = 'free';
export const PAYMENT_METHOD_PAYFLEX = 'payFlex';
export const PAYMENT_METHOD_STITCH = 'stitchPayByBank';

export const PAYMENT_RESPONSE_SUCCESSFUL = 'successful';
export const PAYMENT_RESPONSE_FAILED = 'failed';

export const SET_TIMEOUT_MAX = 2147483647;

// NOTE: making this a constant instead of config because it should never change
export const CURRENCY_CODE = 'ZAR';

export const ORDER_COMPLETE_COOKIE_TTL_DAYS = 60;
export const ORDER_COMPLETE_COOKIE = 'orderComplete';
export const ORDER_COMPLETE_COOKIE_VALUE = 'yes';
