import { Cart } from 'types/types';
import { setCart, setCartFromBroadcast, forgetCart } from './actions';
import { logout } from '../customer/actions';
import { createReducer } from 'typesafe-actions';
import { currentDateInSast, dateObjectToIso } from '../../util/date';
import { broadcast } from 'components/_global/broadcast-channel/util';
import { BroadcastActionsEnum } from 'components/_global/broadcast-channel/types';

const emptyCart: Cart = {
  __typename: 'Cart',
  id: '',
  items: [],
  paymentMethods: [],
  shippingMethods: [],
  voucher: '',
  totals: {
    tax: {
      items: [],
      total: {
        value: 0,
        formattedValue: '',
      },
    },
    discount: {
      items: [],
      total: {
        value: 0,
        formattedValue: '',
      },
    },
    storeCreditUsed: {
      label: '',
      amount: {
        value: 0,
        formattedValue: '',
      },
    },
    grandTotal: {
      value: 0,
      formattedValue: '',
    },
    subtotal: {
      value: 0,
      formattedValue: '',
    },
    shipping: null,
  },
  upsells: [],
  quantity: 0,
  weight: 0,
  isVirtual: false,
  hasAlcohol: false,
  pargo: {
    isAvailable: false,
    isFree: false,
  },
  pudo: {
    isAvailable: false,
    isFree: false,
  },
};

// TODO: better structure. cart.cart is kinda dumb -ref: https://www.youtube.com/watch?v=O9TNz1-dI-Q
interface State {
  cart: Cart;
  lastSyncedAt?: string | null;
}

const initialState: State = { cart: emptyCart, lastSyncedAt: null };

const cartReducer = createReducer(initialState)
  .handleAction(forgetCart, state => ({ ...state, ...initialState }))
  .handleAction(logout, state => ({ ...state, ...initialState }))
  .handleAction(setCart, (state, { payload }) => {
    broadcast(BroadcastActionsEnum.SetCart, payload);
    return {
      ...state,
      cart: payload,
      lastSyncedAt: dateObjectToIso(currentDateInSast(), false),
    };
  })
  .handleAction(setCartFromBroadcast, (state, { payload }) => ({
    ...state,
    cart: payload,
    lastSyncedAt: dateObjectToIso(currentDateInSast(), false),
  }));

export default cartReducer;
export type cartState = ReturnType<typeof cartReducer>;
