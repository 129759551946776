const logErrorMessage = (errorMessage: string) =>
  console.error(`[${new Date().toISOString()}] ERROR: ${errorMessage}`);

export const logError = (
  e: unknown,
  action: string | undefined = undefined
) => {
  const actionString =
    typeof action !== 'undefined' ? `"${action}"` : undefined;

  if (e instanceof Error) {
    logErrorMessage([actionString, e.message].join(' | '));
  } else if (typeof e === 'string') {
    logErrorMessage([actionString, e].join(' | '));
  } else if (typeof actionString !== 'undefined') {
    logErrorMessage(actionString);
  }
};
