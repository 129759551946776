import {
  BundleProduct,
  ConfigurableProductAttributeOption,
  ConfigurableProduct,
  ConfigurableProductVariant,
  GroupedProduct,
  PhysicalProductInterface,
  ProductInterface,
  SimpleProduct,
  StockConfig,
  VirtualProduct,
} from 'types/types';
import mem from 'mem';
import { LinkProps } from 'components/configurable-routing';
import asTitleCase from './as-title-case';

const DEFAULT_MAX_QUANTITY = 10;

type Product =
  | BundleProduct
  | ConfigurableProduct
  | GroupedProduct
  | PhysicalProductInterface
  | ProductInterface
  | SimpleProduct
  | VirtualProduct;

export const isLimitedTimeDeal = (product: Product) => !!product?.campaign;

export const flattenVariantAttributes: (
  variant: ConfigurableProductVariant
) => Record<string, string> = mem(
  variant => {
    return variant.options.reduce(
      (attributes, attr: ConfigurableProductAttributeOption) => {
        attributes[attr.code] = attr.value;
        return attributes;
      },
      {}
    );
  },
  {
    cacheKey: ([variant]) =>
      `${variant.product.realId}-${JSON.stringify(variant.options)}`,
  }
);

export const minQuantity = (stockConfig: StockConfig | null) => {
  return stockConfig &&
    stockConfig.quantityIncrement.isEnabled &&
    stockConfig.quantityIncrement.value > stockConfig.minimumSaleQuantity
    ? stockConfig.quantityIncrement.value
    : (stockConfig && stockConfig.minimumSaleQuantity) || 1;
};

export const maxQuantity = (stockConfig: StockConfig | null) => {
  return (
    (stockConfig && Math.min(stockConfig.maximumSaleQuantity, 100)) ||
    DEFAULT_MAX_QUANTITY
  );
};

export const incrementQuantity = (stockConfig: StockConfig | null) => {
  return stockConfig && stockConfig.quantityIncrement.isEnabled
    ? stockConfig.quantityIncrement.value
    : 1;
};

const catalogPageBreadcrumbs = (
  product: ProductInterface,
  date?: string
): [LinkProps, LinkProps] | undefined => {
  const category = (product?.topLevelCategories || []).find(c => !!c.id);
  if (!category) return undefined;
  return [
    { href: '/', children: "Today's Deals", fontWeight: 'normal' },
    {
      href: `/category/${category.id}${date ? `/?when=${date}` : ''}`,
      dynamicUrl: '/category/[id]',
      children: asTitleCase(category.name),
      fontWeight: 'normal',
    },
  ];
};

export const catalogPageAnchorLink = (
  product: ProductInterface,
  withBreadcrumbs = false
): {
  linkProps: { href: string; dynamicUrl?: string };
  label: string;
  breadcrumbs?: [LinkProps, LinkProps];
} => {
  const isGiftVouchers = product.permanentShop?.id === 'gift-vouchers';

  const label = product.isClearanceSale
    ? 'Clearance Sale'
    : product.permanentShop
    ? product.permanentShop?.name
    : "Today's Deals";

  /**
   * Because we are using a permanent shop for gift vouchers, the "shop" is being used
   * as the category and therefore doesn't help with the scrollTo for a product, which we
   * require since there are no shops in gift-vouchers listing page.
   */
  const scrollTo = `#${
    product.shop?.id
      ? isGiftVouchers
        ? product.id
        : product.shop.id
      : product.id
  }`;

  const isTodaysDeal = !product.isClearanceSale && !product.permanentShop?.id;

  const linkProps = product.isClearanceSale
    ? { href: `/clearance-sale${scrollTo}` }
    : product.permanentShop?.id && !isGiftVouchers
    ? {
        dynamicUrl: '/shops/[id]',
        href: `/shops/${product.permanentShop.id}${scrollTo}`,
      }
    : isGiftVouchers
    ? { href: `/${product.permanentShop?.id}${scrollTo}` }
    : { href: `/${scrollTo}` };

  return {
    linkProps,
    label,
    ...(withBreadcrumbs && isTodaysDeal
      ? { breadcrumbs: catalogPageBreadcrumbs(product) }
      : {}),
  };
};
