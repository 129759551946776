import { css } from '@emotion/react';
import { Box, Flex } from 'components/box';
import { Text } from 'components/typography';
import NewsLetterSubscribeForm from 'components/newsletter/subscribe-form';
import { useCustomer } from 'hooks/customer';
import { ClientOnly } from 'components/_shared/widgets/render-control';

const NewsletterFooter = ({
  title,
  subtitle,
}: {
  title: string;
  subtitle: string;
}) => {
  const currentCustomer = useCustomer();
  const gridLayout = {
    desktop: 'auto auto auto auto',
    mobile: 'auto',
  };

  if (currentCustomer && !!currentCustomer.isSubscribed) {
    return null;
  }

  return (
    <Flex
      css={theme => css`
        background-image: linear-gradient(
          to right,
          ${theme.colors.turquoise},
          ${theme.colors.darkTurquoise}
        );
      `}
      flexDirection={['column', null, 'row']}
      justifyContent="center"
      alignItems="center"
      color="black"
      p={3}
    >
      <Box pr={[null, null, 10]}>
        <Text
          width="100%"
          color="lime"
          fontFamily="header"
          fontWeight="bolder"
          fontSize={[3, 4, 5]}
          textAlign="center"
          lineHeight="1em"
          paddingBottom="1"
          css={css`
            text-transform: uppercase;
          `}
        >
          {title}
        </Text>

        <Text
          width="100%"
          color="white"
          fontFamily="header"
          fontSize={1}
          lineHeight="1.35em"
          textAlign="center"
          mb={[3, null, 0]}
          css={css`
            text-transform: uppercase;
          `}
        >
          {subtitle}
        </Text>
      </Box>
      <Box width="100%">
        <NewsLetterSubscribeForm
          signupLocation="FOOTER"
          gridColumnLayout={gridLayout}
          buttonColor="lime"
          buttonText="send me deals"
        />
      </Box>
    </Flex>
  );
};

const Wrapper = props => (
  <ClientOnly>
    <NewsletterFooter {...props} />
  </ClientOnly>
);

export default Wrapper;
