import { useCallback, useContext, useState, useEffect, ReactNode } from 'react';
import { Box, Flex, Grid } from 'components/box';
import AppContext, { AppNotification, AppNotificationType } from 'contexts/app';
import { css } from '@emotion/react';
import { X as XIcon } from 'react-feather';
import Button, { ButtonVariants } from 'components/button';
import { useHasMounted } from 'components/_shared/widgets/render-control';
import { useRouter } from 'next/router';
import OutsideAlerter from 'components/_shared/elements/outside-alerter';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const SvgIcon = ({ svg, ...rest }: any) => {
  const Svg = svg;
  return <Svg {...rest} />;
};

const NotificationActionButton = ({
  idx,
  disabled,
  onClickCallback,
  dismiss,
  notifications,
  loadDismissOnClick,
  actionLabel,
  actionCallback,
  variant,
}: {
  idx: string | number;
  disabled?: boolean;
  onClickCallback: () => void;
  dismiss: (notifications: AppNotification[]) => void;
  notifications: AppNotification[];
  loadDismissOnClick?: boolean;
  actionLabel: string;
  actionCallback: () => void;
  variant?: ButtonVariants | undefined;
}) => {
  const [buttonLoadState, setButtonLoadState] = useState(false);
  const { events } = useRouter();

  useEffect(() => {
    events.on('routeChangeComplete', () => {
      dismiss(notifications);
    });
  }, [dismiss, notifications, events]);

  return (
    <Button
      variant={variant}
      loading={buttonLoadState}
      key={idx}
      width="100%"
      size="small"
      disabled={buttonLoadState || disabled}
      onClick={() => {
        actionCallback();
        onClickCallback();
        if (loadDismissOnClick) {
          setButtonLoadState(true);
        }
      }}
    >
      {actionLabel}
    </Button>
  );
};

export const NotificationImageBox = ({
  imageUrl,
  boxColor,
  boxSvg,
}: {
  imageUrl: string;
  boxColor: string;
  boxSvg: ReactNode;
}) => (
  <Box
    css={css`
      position: relative;
    `}
  >
    <Box
      p="3px"
      css={theme => css`
        border: 2px solid ${theme.colors[boxColor]};
        border-radius: 8px;
        overflow: hidden;
      `}
    >
      <SvgIcon
        svg={boxSvg}
        css={css`
          height: 23px;
          width: 23px;
          position: absolute;
          top: -9px;
          right: -9px;
        `}
      />
      <Box
        css={css`
          width: 40px;
          height: 40px;
          background: url(${imageUrl});
          background-position: cover;
          border-radius: 4px;
          overflow: hidden;
        `}
      />
    </Box>
  </Box>
);

interface NotificationGroup {
  type: AppNotificationType;
  positionBottom?: boolean;
  disableOutsideClose?: boolean;
  actions: {
    loadDismissOnClick?: boolean;
    actionLabel: string;
    actionCallback: () => void;
    variant?: ButtonVariants;
  }[];
  notifications: AppNotification[];
}

const NotificationBox = ({
  group,
  dismiss,
}: {
  group: NotificationGroup;
  dismiss: (notifications: AppNotification[]) => void;
}) => {
  const isMount = useHasMounted();
  const [allButtonsDisabled, setAllButtonsDisabled] = useState(false);
  const disableAllButtons = () => setAllButtonsDisabled(true);

  return (
    <OutsideAlerter
      callback={() => dismiss(group.notifications)}
      disabled={group.disableOutsideClose}
    >
      <Grid
        p={2}
        bg="white"
        width={['100%', '300px']}
        css={theme => css`
          position: fixed;
          z-index: 270;
          ${group.positionBottom
            ? css`
                right: 100px;
                bottom: ${isMount ? '40px' : '-2000px'};
                transition: bottom 650ms cubic-bezier(0.4, 0, 0.2, 1);
                max-height: 400px;
                @media ${theme.mediaQueries.mobileOnly} {
                  bottom: ${isMount ? '20px' : '-500px'};
                  right: 70px;
                  margin-left: 10px;
                  width: fit-content;
                }
              `
            : css`
                bottom: 0;
                left: 0;
                width: 100%;
                transition: right 650ms cubic-bezier(0.4, 0, 0.2, 1);
                @media ${theme.mediaQueries.tabletUp} {
                  right: ${isMount ? `${theme.space[3]}px` : '-300px'};
                  top: 50px;
                  bottom: unset;
                  left: unset;
                  width: 300px;
                }
              `}
          border-radius: ${theme.radii[2]}px;
          box-shadow: ${theme.shadows.large};
        `}
      >
        <Flex justifyContent="flex-end">
          <Button
            onClick={() => dismiss(group.notifications)}
            borderRadius="16px"
            fontColor="black"
            css={theme => css`
              border: none;
              ${group.positionBottom
                ? css`
                    width: 55px;
                    height: 55px;
                    position: fixed;
                    right: 32px;
                    top: unset;
                    bottom: 40px;
                    background: ${theme.colors.white};
                    box-shadow: 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
                    @media ${theme.mediaQueries.mobileOnly} {
                      right: 10px;
                      bottom: 20px;
                    }
                  `
                : css`
                    color: ${theme.colors.mediumGrey};
                    position: absolute;
                    right: -5px;
                    top: -2px;
                    background: none;
                  `}
              transition: transform 0.3s ease-in-out;
              &:hover {
                ${group.positionBottom
                  ? css`
                      background: ${theme.colors.white};
                    `
                  : css`
                      background: none;
                    `}
                transform: rotate(90deg);
              }
            `}
          >
            <XIcon size={group.positionBottom ? 24 : 16} strokeWidth={3} />
          </Button>
        </Flex>

        {group.notifications.map(({ body }, idx) => (
          <Flex key={idx} justifyContent="space-between" alignItems="center">
            {body}
          </Flex>
        ))}

        <Grid gridRowGap={2}>
          {group.actions.map((actionProps, idx) => (
            <NotificationActionButton
              dismiss={() => dismiss(group.notifications)}
              key={idx}
              idx={idx}
              disabled={allButtonsDisabled}
              onClickCallback={disableAllButtons}
              notifications={group.notifications}
              {...actionProps}
            />
          ))}
        </Grid>
      </Grid>
    </OutsideAlerter>
  );
};

const AppNotifications = () => {
  const { notifications, setNotifications } = useContext(AppContext);

  const dismiss = useCallback(
    (notifications: AppNotification[]) => {
      setNotifications(events =>
        events.filter(v => !notifications.includes(v))
      );
    },
    [setNotifications]
  );

  if (notifications.length === 0) {
    return null;
  }

  const groups: Record<string, NotificationGroup> = {};
  notifications.forEach(notification => {
    const { type, actions, positionBottom, disableOutsideClose } = notification;
    if (!(type in groups)) {
      groups[type] = {
        type,
        actions,
        notifications: [],
        positionBottom,
        disableOutsideClose,
      };
    }
    groups[type].notifications.push(notification);
  });

  return (
    <>
      {Object.values<NotificationGroup>(groups).map(group => (
        <NotificationBox key={group.type} group={group} dismiss={dismiss} />
      ))}
    </>
  );
};

export default AppNotifications;
