import { Cart, Customer } from 'types/types';

export enum BroadcastActionsEnum {
  SetCart = 'set-cart',
  Logout = 'logout',
  Login = 'login',
  SetCustomer = 'set-customer',
}

type PayloadType = Cart | Customer | string | undefined;

export interface BroadcastEvent {
  payload: PayloadType;
  actionType: BroadcastActionsEnum;
  tabId: string;
}

//Type guards

export const isCartBroadcastEvent = (payload: PayloadType): payload is Cart => {
  return (
    (payload as Cart).id !== undefined &&
    (payload as Cart).items !== undefined &&
    (payload as Cart).totals !== undefined &&
    (payload as Cart).quantity !== undefined
  );
};

export const isCustomerBroadcastEvent = (
  payload: PayloadType
): payload is Customer => {
  return (
    (payload as Customer).firstName !== undefined &&
    (payload as Customer).lastName !== undefined &&
    (payload as Customer).id !== undefined &&
    (payload as Customer).email !== undefined
  );
};

export const isLoginBroadcastEvent = (
  payload: PayloadType
): payload is string => {
  return typeof payload === 'string';
};
