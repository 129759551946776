import {
  Auth,
  CreateCustomerInput,
  Customer,
  MutationCreateCustomerArgs,
  MutationCreateCustomerOutput,
  MutationLoginArgs,
  MutationLoginOutput,
  MutationSocialLoginArgs,
  MutationSocialLoginOutput,
  MutationResetCustomerPasswordArgs,
  MutationResetCustomerPasswordOutput,
  QueryCustomerOutput,
  CustomerGroupEnum,
  MutationCreateCustomerAddressOutput,
  MutationCreateCustomerAddressArgs,
  CustomerAddressInput,
  ExternalAddressFormInput,
  MutationUpdateCustomerAddressArgs,
  MutationUpdateCustomerAddressOutput,
  ExternalAddress,
} from 'types/types';
import updateCustomerAddressMutation from 'mutations/updateCustomerAddress.graphql';
import { updateCustomerAddress as updateCustomerAddressAction } from '../redux/customer/actions';
import { addToast } from '../redux/global/actions';
import { RootState } from 'typesafe-actions';
import {
  login as loginAction,
  logout as logoutAction,
  setCustomer as setCustomerAction,
  setSessionExpiresAt as setSessionExpiresAtAction,
  setSessionExpired as setSessionExpiredAction,
  removeCustomerAddress as removeCustomerAddressAction,
  addCustomerAddress,
} from '../redux/customer/actions';
import {
  addToast as addToastAction,
  addLoginMessage as addLoginMessageAction,
  openDrawer as openDrawerAction,
} from '../redux/global/actions';
import {
  forgetCart as forgetCartAction,
  setCart as setCartAction,
} from '../redux/cart/actions';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApolloError, useMutation, useLazyQuery } from '@apollo/client';
import { useRouter } from 'next/router';
import { useGraphQLErrorHandler } from './handle-errors';
import createCustomerMutation from 'mutations/createCustomer.graphql';
import loginMutation from 'mutations/login.graphql';
import socialLoginMutation from 'mutations/socialLogin.graphql';
import resetCustomerPasswordMutation from 'mutations/resetCustomerPassword.graphql';
import deleteCustomerAddressMutation from 'mutations/deleteCustomerAddress.graphql';
import createCustomerAddressMutation from 'mutations/createCustomerAddress.graphql';
import customerQuery from 'queries/customer.graphql';
import { useSyncCart } from './cart';
import { trackAuthentication } from '../util/tag-manager';
import { EventNamesEnum } from 'types/third-party/gtm';
import { getCookie, setCookie } from '../util/cookies';
import {
  ORDER_COMPLETE_COOKIE_TTL_DAYS,
  ORDER_COMPLETE_COOKIE,
  ORDER_COMPLETE_COOKIE_VALUE,
} from '../constants';
import config from '../util/load-config';
import { externalToBaseAddress } from '../util/addresses';

// TODO: store token more securely.

/**
 * Simple token hook.
 */
export const useToken = () =>
  useSelector((state: RootState) => state.customer.token);

export const useIsLoggedIn = () => {
  const token = useToken();
  const [isLoggedIn, setIsLoggedIn] = useState(!!token);

  useEffect(() => {
    setIsLoggedIn(!!token);
  }, [token]);

  return isLoggedIn;
};

export const useCustomer = (): Customer | undefined =>
  useSelector((state: RootState) => state.customer.currentCustomer) ??
  undefined;

export const useIsStaffMember = () => {
  const customer = useCustomer();

  const [isStaffMember, setIsStaffMember] = useState(
    !!customer &&
      [CustomerGroupEnum.Staff, CustomerGroupEnum.Management].includes(
        customer.group.code
      )
  );

  useEffect(() => {
    setIsStaffMember(
      !!customer &&
        [CustomerGroupEnum.Staff, CustomerGroupEnum.Management].includes(
          customer.group.code
        )
    );
  }, [customer]);

  return isStaffMember;
};

interface CustomerContext {
  headers?: { Authorization: string };
}

export const useCustomerContext = () => {
  const token = useToken();
  const [context, setContext] = useState<CustomerContext>(
    token ? { headers: { Authorization: `Bearer ${token}` } } : {}
  );

  useEffect(() => {
    if (token) {
      setContext({ headers: { Authorization: `Bearer ${token}` } });
    } else {
      setContext({});
    }
  }, [token]);

  return context;
};

export const useSessionExpiry = () => {
  const dispatch = useDispatch();
  const sessionExpiresAt = useSelector(
    (state: RootState) => state.customer.sessionExpiresAt
  );
  const sessionExpired = useSelector(
    (state: RootState) => state.customer.sessionExpired
  );

  useEffect(() => {
    let warningTimeoutId;
    let expiredTimeoutId;

    if (sessionExpiresAt) {
      const timeUntil = sessionExpiresAt - Date.now();
      const warningMinutes = 5;
      const warnBefore = warningMinutes * 60000;
      const autoLogoutAfter = 7200000; // 2 hours

      const warningCallback = () => {
        dispatch(
          addToastAction({
            body: `Your session will expire in ${warningMinutes} minutes`,
            variant: 'warning',
            timeout: 15000,
          })
        );
      };

      const expiredCallback = () => {
        if (!sessionExpired) {
          dispatch(setSessionExpiredAction());
        }
      };

      const logoutCallback = () => {
        dispatch(logoutAction());
      };

      if (timeUntil > warnBefore) {
        warningTimeoutId = setTimeout(
          () => warningCallback(),
          timeUntil - warnBefore
        );
      }

      if (timeUntil > 0) {
        expiredTimeoutId = setTimeout(() => expiredCallback(), timeUntil);
      } else if (timeUntil + autoLogoutAfter < 0) {
        logoutCallback();
      } else {
        expiredCallback();
      }
    }

    return () => {
      warningTimeoutId && clearTimeout(warningTimeoutId);
      expiredTimeoutId && clearTimeout(expiredTimeoutId);
    };
  }, [dispatch, sessionExpiresAt, sessionExpired]);
};

interface ReauthenticateArgs {
  message?: string;
  showLogin?: boolean;
  logout?: boolean;
}

export const useReauthenticate = (): ((args: ReauthenticateArgs) => void) => {
  const dispatch = useDispatch();

  const reauthenticate = useCallback(
    ({ message, showLogin, logout }: ReauthenticateArgs) => {
      if (message) {
        dispatch(addLoginMessageAction(message));
      }
      if (showLogin) {
        dispatch(openDrawerAction('login'));
      }
      if (logout) {
        dispatch(logoutAction());
      }
    },
    [dispatch]
  );

  return reauthenticate;
};

export const useSetHasCompletedOrderFlag = () => {
  const callback = useCallback(({ tmp = false }: { tmp?: boolean } = {}) => {
    if (!getCookie(ORDER_COMPLETE_COOKIE)) {
      setCookie(
        ORDER_COMPLETE_COOKIE,
        ORDER_COMPLETE_COOKIE_VALUE,
        tmp ? 1 : ORDER_COMPLETE_COOKIE_TTL_DAYS,
        '/',
        config.security.cookies.domain,
        true
      );
    }
  }, []);

  return callback;
};

export const useSyncCustomer = (): [
  () => void,
  { loading: boolean; error: string; completed: boolean }
] => {
  const dispatch = useDispatch();
  const context = useCustomerContext();
  const setHasCompletedOrder = useSetHasCompletedOrderFlag();

  const [error, setError] = useState('');
  const [completed, setCompleted] = useState(false);

  const [
    customer,
    { loading, error: queryError, data },
  ] = useLazyQuery<QueryCustomerOutput>(customerQuery, {
    context,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'standby',
  });

  const callback = useCallback(() => {
    setCompleted(false);
    customer();
  }, [customer, setCompleted]);

  useEffect(() => {
    if (queryError) {
      const [firstError] = queryError.graphQLErrors;
      setError(firstError.message);
    }
  }, [queryError]);

  useEffect(() => {
    if (!completed && !loading && data?.customer) {
      dispatch(setCustomerAction(data.customer));

      // add has completed order flag where applicable
      if (data.customer.orderStats && data.customer.orderStats.count > 0) {
        setHasCompletedOrder();
      }

      setCompleted(true);
    }
  }, [
    data,
    data?.customer,
    dispatch,
    completed,
    loading,
    setHasCompletedOrder,
  ]);

  return [callback, { loading, error, completed }];
};

export const useStaffPreview = (
  preview: (args: { date: string; context: CustomerContext }) => void
) => {
  const {
    query: { when: date },
  } = useRouter();
  const context = useCustomerContext();
  const isStaffMember = useIsStaffMember();
  const [hasTriggeredPreview, setHasTriggeredPreview] = useState(false);

  useEffect(() => {
    if (!hasTriggeredPreview && date && context.headers && isStaffMember) {
      setHasTriggeredPreview(true);
      preview({ date: date as string, context });
    }
  }, [date, context, isStaffMember, hasTriggeredPreview, preview]);
};

export const useCustomerAuthenticated = () => {
  const dispatch = useDispatch();
  const setHasCompletedOrder = useSetHasCompletedOrderFlag();

  return useCallback(
    (auth: Auth) => {
      dispatch(loginAction(auth.authToken));
      dispatch(setCustomerAction(auth.customer));

      // add has completed order flag where applicable
      if (auth.customer.orderStats && auth.customer.orderStats.count > 0) {
        setHasCompletedOrder();
      }

      if (auth.authTokenExpiresIn) {
        dispatch(
          setSessionExpiresAtAction(Date.now() + auth.authTokenExpiresIn * 1000)
        );
      }

      if (auth.cart) {
        dispatch(setCartAction(auth.cart));
      } else {
        dispatch(forgetCartAction());
      }
    },
    [dispatch, setHasCompletedOrder]
  );
};

export const useLogin = (): [
  (variables: MutationLoginArgs) => void,
  {
    called: boolean;
    loading: boolean;
    error?: ApolloError;
    completed: boolean;
  }
] => {
  const customerAuthenticated = useCustomerAuthenticated();

  const [completed, setCompleted] = useState(false);

  const cartId = useSelector((state: RootState) => state.cart.cart.id);

  const [login, { called, loading, error, data }] = useMutation<
    MutationLoginOutput,
    MutationLoginArgs
  >(loginMutation, { errorPolicy: 'all' });

  const callback = useCallback(
    (variables: MutationLoginArgs) => {
      login({
        variables: {
          ...(cartId ? { guestCartId: cartId } : {}),
          ...variables,
        },
      })
        .then()
        .catch();
    },
    [cartId, login]
  );

  useEffect(() => {
    if (data?.login && data.login.authToken) {
      customerAuthenticated(data.login);
      setCompleted(true);
      trackAuthentication({
        event: EventNamesEnum.signInSuccess,
        user: {
          userID: data.login.customer.id,
          userStatus: 'signedIn',
          subscribed: data.login.customer.isSubscribed,
        },
      });
    }
  }, [data, customerAuthenticated]);

  return [callback, { called, loading, error, completed }];
};

export const useSocialLogin = (): [
  (variables: MutationSocialLoginArgs) => void,
  {
    called: boolean;
    loading: boolean;
    error: string;
    completed: boolean;
  }
] => {
  const customerAuthenticated = useCustomerAuthenticated();

  const [error, setError] = useState('');
  const [completed, setCompleted] = useState(false);

  const cartId = useSelector((state: RootState) => state.cart.cart.id);

  const [
    socialLogin,
    { called, loading, data, error: mutationError },
  ] = useMutation<MutationSocialLoginOutput, MutationSocialLoginArgs>(
    socialLoginMutation
  );

  const callback = useCallback(
    (variables: MutationSocialLoginArgs) => {
      setError('');
      setCompleted(false);

      socialLogin({
        variables: {
          ...(cartId ? { guestCartId: cartId } : {}),
          ...variables,
        },
      })
        .then()
        .catch();
    },
    [cartId, socialLogin]
  );

  useEffect(() => {
    if (mutationError) {
      const error = mutationError.graphQLErrors[0] || mutationError;
      setError(error.message);
    }
  }, [mutationError]);

  useEffect(() => {
    if (data?.socialLogin) {
      if (!data.socialLogin.authToken) {
        setError('Login failed, please try again later');
      } else {
        customerAuthenticated(data.socialLogin);
        setCompleted(true);
        trackAuthentication({
          event: EventNamesEnum.signInSuccess,
          ...(data.socialLogin
            ? {
                user: {
                  userID: data.socialLogin.customer.id,
                  userStatus: 'signedIn',
                  subscribed: data.socialLogin.customer.isSubscribed,
                },
              }
            : {}),
        });
      }
    }
  }, [data, customerAuthenticated]);

  return [callback, { called, loading, error, completed }];
};

export const useCreateCustomer = (): [
  (input: CreateCustomerInput) => void,
  {
    called: boolean;
    loading: boolean;
    errors: string[];
    completed: boolean;
  }
] => {
  const customerAuthenticated = useCustomerAuthenticated();

  const [errors, setErrors] = useState<string[]>([]);
  const [completed, setCompleted] = useState(false);

  const cartId = useSelector((state: RootState) => state.cart.cart.id);

  const [
    createCustomer,
    { called, loading, error: createCustomerError, data },
  ] = useMutation<MutationCreateCustomerOutput, MutationCreateCustomerArgs>(
    createCustomerMutation,
    { errorPolicy: 'all' }
  );

  const callback = useCallback(
    (input: CreateCustomerInput) => {
      createCustomer({
        variables: {
          input,
          ...(cartId ? { guestCartId: cartId } : {}),
        },
      })
        .then()
        .catch();
    },
    [cartId, createCustomer]
  );

  useEffect(() => {
    if (createCustomerError) {
      const errors = createCustomerError.graphQLErrors;
      setErrors(errors.map(error => error.message));
    }
  }, [createCustomerError]);

  useEffect(() => {
    if (data?.createCustomer && data.createCustomer.authToken) {
      customerAuthenticated(data.createCustomer);
      setCompleted(true);
      trackAuthentication({
        event: EventNamesEnum.signUpSuccess,
        user: {
          userID: data.createCustomer.customer.id,
          userStatus: 'signedIn',
          subscribed: data.createCustomer.customer.isSubscribed,
        },
      });
    }
  }, [data, customerAuthenticated]);

  return [callback, { called, loading, errors, completed }];
};

export const useCustomerResetPassword = (): [
  (variables: MutationResetCustomerPasswordArgs) => void,
  {
    called: boolean;
    loading: boolean;
    error: string;
    completed: boolean;
  }
] => {
  const dispatch = useDispatch();
  const customerAuthenticated = useCustomerAuthenticated();

  const [error, setError] = useState('');
  const [completed, setCompleted] = useState(false);

  const cartId = useSelector((state: RootState) => state.cart.cart.id);

  const [
    resetCustomerPassword,
    { called, loading, error: resetCustomerPasswordError, data },
  ] = useMutation<
    MutationResetCustomerPasswordOutput,
    MutationResetCustomerPasswordArgs
  >(resetCustomerPasswordMutation);

  const callback = useCallback(
    (variables: MutationResetCustomerPasswordArgs) =>
      resetCustomerPassword({
        variables: {
          ...(cartId ? { guestCartId: cartId } : {}),
          ...variables,
        },
      }),
    [cartId, resetCustomerPassword]
  );

  useEffect(() => {
    if (resetCustomerPasswordError) {
      const [firstError] = resetCustomerPasswordError.graphQLErrors;
      setError(firstError.message);
    }
  }, [resetCustomerPasswordError]);

  useEffect(() => {
    if (data?.resetCustomerPassword) {
      if (!data.resetCustomerPassword.authToken) {
        dispatch(openDrawerAction('login'));
      } else {
        customerAuthenticated(data.resetCustomerPassword);
        setCompleted(true);
      }
    }
  }, [dispatch, data, customerAuthenticated]);

  return [callback, { called, loading, error, completed }];
};

export const useAddExternalAddress = (): [
  (values: ExternalAddressFormInput, address: ExternalAddress) => void,
  { loading?: boolean; completed?: boolean; error?: ApolloError }
] => {
  const context = useCustomerContext();
  const dispatch = useDispatch();
  const gqlError = useGraphQLErrorHandler({
    showLogin: true,
  });

  const [createCustomerAddress, { loading, data, called, error }] = useMutation<
    MutationCreateCustomerAddressOutput,
    MutationCreateCustomerAddressArgs
  >(createCustomerAddressMutation, { context });

  const [completed, setCompleted] = useState(false);

  const callback = useCallback(
    (values: ExternalAddressFormInput, address: ExternalAddress) => {
      const transformedAddress = externalToBaseAddress(values, address);

      if (transformedAddress) {
        const externalAsCustomerAddress: CustomerAddressInput = transformedAddress;
        createCustomerAddress({
          variables: { input: externalAsCustomerAddress },
        })
          .then(() => setCompleted(false))
          .catch(gqlError);
      } else {
        dispatch(
          addToast({
            body: 'Address update failed. Please try again.',
            variant: 'error',
            timeout: 5000,
          })
        );
        setCompleted(true);
      }
    },
    [createCustomerAddress, dispatch, gqlError]
  );

  useEffect(() => {
    if (called && data && !error) {
      dispatch(addCustomerAddress(data.createCustomerAddress));
      setCompleted(true);
    }
  }, [called, data, dispatch, error]);

  return [callback, { loading, completed, error }];
};

export const useDeleteAddress = () => {
  const dispatch = useDispatch();
  const context = useCustomerContext();

  const [addressId, setAddressId] = useState<string | false>(false);
  const [confirming, setConfirming] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [isNewAddress, setIsNewAddress] = useState(false);

  const hasActiveCart =
    useSelector((state: RootState) => state.cart.cart.items).length > 0;

  const [
    syncCart,
    {
      completed: syncCartCompleted,
      loading: syncCartLoading,
      error: syncCartError,
    },
  ] = useSyncCart();

  const [
    deleteCustomerAddress,
    { loading: deleteAddressLoading, data, called, error },
  ] = useMutation(deleteCustomerAddressMutation, { context });

  const loading = deleteAddressLoading || syncCartLoading;

  const gqlError = useGraphQLErrorHandler({
    showLogin: true,
    authExpiredCallback: () => {
      setAddressId(false);
      setConfirming(false);
      setIsNewAddress(false);
      setCompleted(true);
    },
  });

  const requestConfirmation = useCallback((addressId: string) => {
    setAddressId(addressId);
    setIsNewAddress(true);
    setCompleted(false);
    setConfirming(true);
  }, []);

  const cancelDeleteAddress = useCallback(() => {
    setAddressId(false);
    setIsNewAddress(false);
    setCompleted(true);
    setConfirming(false);
  }, []);

  const confirmDeleteAddress = useCallback(() => {
    deleteCustomerAddress({ variables: { addressId } })
      .then(() => {
        setCompleted(false);
        setIsNewAddress(false);
      })
      .catch(gqlError);
  }, [addressId, deleteCustomerAddress, gqlError]);

  const deleteAddress = useCallback(
    (addressId: string) => {
      deleteCustomerAddress({ variables: { addressId } })
        .then(() => {
          setAddressId(addressId);
          setCompleted(false);
          setIsNewAddress(false);
        })
        .catch(gqlError);
    },
    [deleteCustomerAddress, gqlError]
  );

  useEffect(() => {
    if (called && !error && data && addressId && !isNewAddress && !completed) {
      dispatch(removeCustomerAddressAction(addressId));
      dispatch(
        addToastAction({
          body: 'Address deleted!',
          variant: 'success',
          timeout: 5000,
        })
      );

      if (hasActiveCart) {
        syncCart();
      } else {
        setCompleted(true);
        setConfirming(false);
      }
    }
  }, [
    addressId,
    called,
    completed,
    data,
    dispatch,
    error,
    hasActiveCart,
    isNewAddress,
    syncCart,
  ]);

  useEffect(() => {
    if (!isNewAddress && !completed && syncCartCompleted && !syncCartError) {
      setCompleted(true);
      setConfirming(false);
    }
  }, [completed, syncCartCompleted, confirming, isNewAddress, syncCartError]);

  return {
    loading,
    confirming,
    completed,
    requestConfirmation,
    cancelDeleteAddress,
    confirmDeleteAddress,
    deleteAddress,
  };
};

export const useUpdateAddress = (): [
  (variables: MutationUpdateCustomerAddressArgs) => void,
  {
    loading: boolean;
    error: ApolloError | undefined;
    data?: MutationUpdateCustomerAddressOutput | null;
    completed: boolean;
  }
] => {
  const dispatch = useDispatch();
  const context = useCustomerContext();
  const [completed, setCompleted] = useState(false);

  const [updateCustomerAddress, { loading, data, called, error }] = useMutation<
    MutationUpdateCustomerAddressOutput,
    MutationUpdateCustomerAddressArgs
  >(updateCustomerAddressMutation, { context });

  const callback = useCallback(
    (variables: MutationUpdateCustomerAddressArgs) => {
      setCompleted(false);
      updateCustomerAddress({ variables });
    },
    [updateCustomerAddress]
  );

  useEffect(() => {
    if (called && !error && data) {
      dispatch(updateCustomerAddressAction(data.updateCustomerAddress));
      dispatch(
        addToast({
          body: 'Address updated successfully!',
          variant: 'success',
          timeout: 5000,
        })
      );
      setCompleted(true);
    } else if (called && error) {
      dispatch(
        addToast({
          body: 'Address not updated successfully! Please try again.',
          variant: 'error',
          timeout: 5000,
        })
      );
      setCompleted(true);
    }
  }, [called, data, dispatch, error]);

  return [callback, { loading, error, data, completed }];
};

interface GTag {
  (
    action: 'get',
    target: string, // measurement ID
    field: 'client_id' | 'session_id',
    callback: (arg: string | unknown) => void
  );
}

type WindowGTag = GTag | unknown;

declare global {
  interface Window {
    gtag: WindowGTag;
  }
}

const isGTagValid = (gtag: WindowGTag): gtag is GTag =>
  typeof (gtag as GTag) === 'function';

export const useGoogleTagInfo = (gtagIsInitialized?: boolean) => {
  const [sessionId, setSessionId] = useState<string | undefined>();
  const [clientId, setClientId] = useState<string | undefined>();

  useEffect(() => {
    if (
      !config.gtm.gtagTarget ||
      !gtagIsInitialized ||
      typeof window === 'undefined' ||
      typeof window.gtag === 'undefined' ||
      !isGTagValid(window.gtag)
    ) {
      return;
    }

    window.gtag(
      'get',
      config.gtm.gtagTarget,
      'session_id',
      (sessionId: string | unknown) => {
        if (typeof sessionId === 'string') {
          setSessionId(sessionId);
        }
      }
    );

    window.gtag(
      'get',
      config.gtm.gtagTarget,
      'client_id',
      (clientId: string | unknown) => {
        if (typeof clientId === 'string') {
          setClientId(clientId);
        }
      }
    );
  }, [gtagIsInitialized]);

  const returnMemo = useMemo(
    () => ({
      sessionId,
      clientId,
    }),
    [clientId, sessionId]
  );

  return returnMemo;
};
