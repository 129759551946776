import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  loginFromBroadcast,
  logoutFromBroadcast,
  setCustomerFromBroadcast,
} from '../../../../redux/customer/actions';
import { setCartFromBroadcast } from '../../../../redux/cart/actions';
import {
  tabId,
  BROADCAST_CHANNEL_ID,
} from 'components/_global/broadcast-channel/constants';
import {
  BroadcastActionsEnum,
  BroadcastEvent,
  isCartBroadcastEvent,
  isCustomerBroadcastEvent,
  isLoginBroadcastEvent,
} from 'components/_global/broadcast-channel/types';

let receivingChannel;

const BroadcastChannelReceiver = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (typeof BroadcastChannel !== 'undefined') {
      receivingChannel = new BroadcastChannel(BROADCAST_CHANNEL_ID);
      receivingChannel.onmessage = event => {
        const eventData: BroadcastEvent = event.data;
        if (eventData.tabId === tabId) {
          return;
        }

        switch (eventData.actionType) {
          case BroadcastActionsEnum.SetCart:
            if (isCartBroadcastEvent(eventData.payload)) {
              dispatch(setCartFromBroadcast(eventData.payload));
            }
            break;
          case BroadcastActionsEnum.Login:
            if (isLoginBroadcastEvent(eventData.payload)) {
              dispatch(loginFromBroadcast(eventData.payload));
            }
            break;
          case BroadcastActionsEnum.SetCustomer:
            if (isCustomerBroadcastEvent(eventData.payload)) {
              dispatch(setCustomerFromBroadcast(eventData.payload));
            }
            break;
          case BroadcastActionsEnum.Logout:
            dispatch(logoutFromBroadcast());
            break;
        }
      };
      return () => receivingChannel.close();
    }
  }, [dispatch]);
  return null;
};

export default BroadcastChannelReceiver;
