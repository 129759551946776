import { createContext, Dispatch, ReactNode, SetStateAction } from 'react';
import { ButtonVariants } from 'components/button';
import { VodapayApi } from 'components/_global/vodapay';

export enum AppNotificationType {
  CartItemSoldOut = 'CartItemSoldOut',
  CartItemSoldOutEmpty = 'CartItemSoldOutEmpty',
  CartItemExpired = 'CartItemExpired',
  CartItemExpiringSoon = 'CartItemExpiringSoon',
  CartItemQuantityAdjusted = 'CartItemQuantityAdjusted',
  CartItemSellingFast = 'CartItemSellingFast',
  ProductAddedToCart = 'ProductAddedToCart',
  StaffProductNotification = 'StaffProductNotification',
}

export interface AppNotification {
  type: AppNotificationType;
  isSingular?: boolean;
  positionBottom?: boolean;
  disableOutsideClose?: boolean;
  actions: {
    disableAllOtherActions?: boolean;
    loadDismissOnClick?: boolean;
    actionLabel: string;
    actionCallback: () => void;
    variant?: ButtonVariants;
  }[];
  body: ReactNode;
}

export interface VodapayContext {
  api?: VodapayApi;
  isActive: boolean;
  debug: (data: any) => void;
  authInit: () => void;
  paymentInit: (url: string) => void;
}

export interface VodapayEventListener {
  id: string;
  event: string;
  callback: (data: any) => void;
}

export interface AppContextType {
  themeName: string | null;
  setThemeName: Dispatch<SetStateAction<string | null>>;
  setNotifications: Dispatch<SetStateAction<AppNotification[]>>;
  notifications: AppNotification[];
  vodapay: VodapayContext;
  vodapayEventListeners: VodapayEventListener[];
  setVodapayEventListeners: Dispatch<SetStateAction<VodapayEventListener[]>>;
  isNavigationBarActive: boolean;
  globalStatuses: string[];
  setGlobalStatuses: Dispatch<SetStateAction<string[]>>;
  gtagIsInitialized: boolean;
  setGtagIsInitialized: Dispatch<SetStateAction<boolean>>;
}

export const defaultVodapayContext = {
  api: undefined,
  isActive: false,
  debug: (_data: any) => null,
  authInit: () => null,
  paymentInit: (_url: string) => null,
};

const defaultContext: AppContextType = {
  themeName: null,
  setThemeName: _name => null,
  setNotifications: (_product: SetStateAction<AppNotification[]>) => null,
  notifications: [],
  vodapay: defaultVodapayContext,
  vodapayEventListeners: [],
  setVodapayEventListeners: (
    _listener: SetStateAction<VodapayEventListener[]>
  ) => null,
  isNavigationBarActive: false,
  globalStatuses: [],
  setGlobalStatuses: (_statuses: SetStateAction<string[]>) => null,
  gtagIsInitialized: false,
  setGtagIsInitialized: (_initialized: SetStateAction<boolean>) => null,
};

const AppContext = createContext<AppContextType>(defaultContext);

export default AppContext;
