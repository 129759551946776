import {
  connectRefinementList,
  connectCurrentRefinements,
  connectNumericMenu,
} from 'react-instantsearch-dom';
import { Box, Flex, Grid } from 'components/box';
import { css } from '@emotion/react';
import { Heading, Text } from 'components/typography';
import Checkbox from 'components/form/checkbox';
import {
  SearchRefinementItemInterface,
  SearchNumericMenuItemInterface,
} from 'types/types';
import asTitleCase from '../../../../util/as-title-case';
import Button from 'components/button';
import FilterSVG from 'shared/assets/icons/filter.svg';
import RadioButton from 'components/form/radio-button';
import { Accordion, AccordionItem } from 'components/_shared/widgets/accordion';
import { X as XIcon } from 'react-feather';
import OutsideAlerter from 'components/_shared/elements/outside-alerter';
import Input from 'components/form/input';

const PRICING_ITEMS = [
  { label: 'Under R100', end: 100 },
  { label: 'Up to R300', end: 300 },
  { label: 'Up to R500', end: 500 },
  { label: 'Up to R1,000', end: 1000 },
  { label: 'Up to R1,500', end: 1500 },
  { label: 'Up to R2,000', end: 2000 },
  { label: 'More than R2,000', start: 2000 },
];

const SAVINGS_ITEMS = [
  { label: '20% and more', start: 20 },
  { label: '50% and more', start: 50 },
  { label: '60% and more', start: 60 },
  { label: '70% and more', start: 70 },
  { label: '80% and more', start: 80 },
  { label: '90% and more', start: 90 },
];

const DRAWER_ANIMATION_TIME = '0.4s';

export const FilterIcon = () => (
  <FilterSVG
    css={theme => css`
      fill: ${theme.colors.darkestGrey};
      stroke: ${theme.colors.darkestGrey};
      @media ${theme.mediaQueries.mobileDown} {
        fill: ${theme.colors.blue};
        stroke: ${theme.colors.blue};
      }
    `}
  />
);

const RefinementListItem = ({
  item,
  onChange,
  isRefinementActive,
}: {
  item: SearchRefinementItemInterface;
  onChange: () => void;
  isRefinementActive: boolean;
}) => (
  <Box my={2}>
    <Checkbox
      label={
        <Text fontSize="0.84rem">
          {asTitleCase(`${item.label} (${item.count})`)}
        </Text>
      }
      onChange={onChange}
      checked={isRefinementActive}
      shouldFocus={false}
    />
  </Box>
);

const RefinementList = ({
  items,
  refine,
  attribute,
  searchForItems,
}: {
  items: SearchRefinementItemInterface[];
  refine: (value: string[]) => void;
  attribute: string;
  searchForItems: (event: string) => void;
}) => (
  <ul>
    {attribute === 'brand' && (
      <Input
        background="white"
        mb={1}
        type="search"
        placeholder="Search for a brand..."
        onChange={event => searchForItems(event.currentTarget.value)}
      />
    )}
    {items.length > 0 ? (
      items.map(item => (
        <li key={item.label}>
          <RefinementListItem
            onChange={() => refine(item.value)}
            item={item}
            isRefinementActive={item.isRefined}
          />
        </li>
      ))
    ) : (
      <Text fontSize="s" mt={2}>
        Sorry, no {attribute} filters match this search
      </Text>
    )}
  </ul>
);

const NumericMenuItem = ({
  item,
  onChange,
}: {
  item: SearchNumericMenuItemInterface;
  onChange: () => void;
}) => (
  <Box mb={1}>
    <RadioButton
      labelProps={{ fontSize: '0.84rem' }}
      label={item.label}
      onChange={onChange}
      checked={item.isRefined}
    />
  </Box>
);

const NumericMenu = ({
  items,
  refine,
}: {
  items: SearchNumericMenuItemInterface[];
  refine: (value: string[]) => void;
}) => (
  <ul>
    {items.map(item => (
      <li key={item.label}>
        <NumericMenuItem onChange={() => refine(item.value)} item={item} />
      </li>
    ))}
  </ul>
);

const ClearRefinements = ({
  items,
  refine,
}: {
  items: SearchRefinementItemInterface[];
  refine: (value: SearchRefinementItemInterface[]) => void;
}) => (
  <Button
    width={['100%', 'auto']}
    onClick={() => refine(items)}
    disabled={!items.length}
    variant="flat"
    textTransform="none"
    fontWeight="medium"
    p={[null, 0]}
    m={[null, 0]}
  >
    Clear All
  </Button>
);

const CheckboxFilters = connectRefinementList(RefinementList);
const RadioButtonFilters = connectNumericMenu(NumericMenu);
const ClearRefinementsButton = connectCurrentRefinements(ClearRefinements);

export const SearchFilters = () => (
  <Flex
    className="hide-for-mobile-only"
    flexDirection="column"
    px={3}
    pt={2}
    pb="28px"
    bg="lightestGrey"
    overflowY="scroll"
    height={['90vh', '75vh']}
  >
    <Flex
      justifyContent="space-between"
      mt={0}
      pt={2}
      pb="12px"
      css={theme => css`
        border-bottom: 1px solid ${theme.colors.lightishGrey};
      `}
    >
      <Flex alignItems="center">
        <FilterIcon />
        <Heading fontFamily="header" ml={2}>
          Filters
        </Heading>
      </Flex>

      <ClearRefinementsButton />
    </Flex>

    <Heading py={3} fontSize={2}>
      Category
    </Heading>
    <CheckboxFilters attribute="categories" />

    <Heading
      py={3}
      mt={3}
      fontSize={2}
      css={theme => css`
        border-top: 1px solid ${theme.colors.lightishGrey};
      `}
    >
      Pricing
    </Heading>
    <RadioButtonFilters attribute="price" items={PRICING_ITEMS} />

    <Heading
      py={3}
      mt={3}
      fontSize={2}
      css={theme => css`
        border-top: 1px solid ${theme.colors.lightishGrey};
      `}
    >
      Savings
    </Heading>
    <RadioButtonFilters attribute="savings.percent" items={SAVINGS_ITEMS} />

    <Heading
      mt={3}
      py={3}
      fontSize={2}
      css={theme => css`
        border-top: 1px solid ${theme.colors.lightishGrey};
      `}
    >
      Brand
    </Heading>
    <CheckboxFilters attribute="brand" limit={7} />
  </Flex>
);

export const SearchFiltersMobile = ({
  isMobileFiltersOpen,
  hideMobileFilters,
}: {
  isMobileFiltersOpen: boolean;
  hideMobileFilters: () => void;
}) => (
  <>
    <Box
      position="fixed"
      height="100vh"
      width="100vw"
      left="-100%"
      zIndex={290}
      top={0}
      bg="overlayBackground"
      opacity={0}
      css={css`
        transition: opacity ${DRAWER_ANIMATION_TIME} ease-in-out;
        ${isMobileFiltersOpen &&
        css`
          left: 0%;
          opacity: 1;
        `}
      `}
    />

    <OutsideAlerter callback={hideMobileFilters}>
      <Flex
        backgroundColor="black"
        p={1}
        top={0}
        justifyContent="space-between"
        alignItems="center"
        zIndex={301}
        right="-100%"
        position="absolute"
        width="80%"
        css={theme => css`
          transition: right ${DRAWER_ANIMATION_TIME} ease-in-out;
          box-shadow: ${theme.shadows.cards};

          ${isMobileFiltersOpen &&
          css`
            right: 0%;
          `}
        `}
      >
        <Heading color="white" pl={2}>
          Filter
        </Heading>
        <Button variant="flat" p={2} m={0} onClick={hideMobileFilters}>
          <XIcon color="white" size={15} />
        </Button>
      </Flex>

      <Box
        height="100vh"
        overflowX="hidden"
        position="absolute"
        right="-100%"
        top={0}
        zIndex={300}
        width="80%"
        bg="background"
        css={theme => css`
          transition: right ${DRAWER_ANIMATION_TIME} ease-in-out;
          box-shadow: ${theme.shadows.cards};

          ${isMobileFiltersOpen &&
          css`
            right: 0%;
          `}

          ::-webkit-scrollbar {
            /*Remove scrollbar on mobile devices*/
            width: 0;
            background: transparent;
          }
        `}
      >
        <Box>
          <Grid p={3} pt={5}>
            <Accordion multipleOpen>
              <AccordionItem title="Category" hideWhenClosed>
                <CheckboxFilters attribute="categories" />
              </AccordionItem>
              <AccordionItem title="Pricing" hideWhenClosed>
                <RadioButtonFilters attribute="price" items={PRICING_ITEMS} />
              </AccordionItem>
              <AccordionItem title="Savings" hideWhenClosed>
                <RadioButtonFilters
                  attribute="savings.percent"
                  items={SAVINGS_ITEMS}
                />
              </AccordionItem>
              <AccordionItem title="Brand" hideWhenClosed>
                <CheckboxFilters attribute="brand" limit={7} />
              </AccordionItem>
            </Accordion>
            <Flex width="100%" flexDirection="column" mb={5}>
              <Button onClick={hideMobileFilters} mb={0}>
                Apply
              </Button>
              <ClearRefinementsButton />
            </Flex>
          </Grid>
        </Box>
      </Box>
    </OutsideAlerter>
  </>
);
