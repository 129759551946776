import { useContext, useEffect } from 'react';
import { ClientOnly } from 'components/_shared/widgets/render-control';
import stickyMessagesQuery from 'queries/messages.graphql';
import { useQuery } from '@apollo/client';
import { QueryMessagesOutput } from 'types/types';
import { useRouter } from 'next/router';
import { HeaderContext } from 'contexts/header';

const StickyMessageLoader = () => {
  const { data } = useQuery<QueryMessagesOutput>(stickyMessagesQuery);
  const headerContext = useContext(HeaderContext);

  useEffect(() => {
    if (data?.messages) {
      headerContext?.setStickyMessages(data.messages);
    }
  }, [headerContext, data]);

  return null;
};

/**
 * NOTE: we load these messages from server-side for most pages,
 * but for the ones below there is no SSR, so we load them from client-side instead.
 */
const Wrapper = () => {
  const { pathname } = useRouter();
  const pathnames = ['/my-account', '/my-orders', '/checkout'];

  if (pathnames.includes(pathname)) {
    return (
      <ClientOnly>
        <StickyMessageLoader />
      </ClientOnly>
    );
  }

  return null;
};

export default Wrapper;
