import { useDispatch } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import { ApolloError, useMutation } from '@apollo/client';
import subscribeUserMutation from 'mutations/subscribeUser.graphql';
import { addToast } from '../redux/global/actions';
import {
  MutationSubscribeUserOutput,
  MutationSubscribeUserArgs,
} from 'types/types';
import { updateCustomerSubscription as updateCustomerSubscriptionAction } from '../redux/customer/actions';
import { trackNewsletterSubscribed } from '../util/tag-manager';

export const useSubscribeUser = (): [
  (variables: MutationSubscribeUserArgs) => void,
  {
    loading: boolean;
    error: ApolloError | undefined;
    data?: MutationSubscribeUserOutput | null;
    completed: boolean;
  }
] => {
  const [completed, setCompleted] = useState(false);
  const dispatch = useDispatch();
  const [subscribeUserRequest, { loading, error, data }] = useMutation<
    MutationSubscribeUserOutput,
    MutationSubscribeUserArgs
  >(subscribeUserMutation);

  const callback = useCallback(
    (variables: MutationSubscribeUserArgs) => {
      subscribeUserRequest({ variables });
    },
    [subscribeUserRequest]
  );

  useEffect(() => {
    if (data && data.subscribeUser) {
      dispatch(updateCustomerSubscriptionAction(true));
      dispatch(
        addToast({
          body: 'Thanks for subscribing',
          variant: 'success',
          timeout: 15000,
        })
      );
      setCompleted(true);
      trackNewsletterSubscribed();
    }
  }, [data, dispatch]);

  return [callback, { loading, error, data, completed }];
};
