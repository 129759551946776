import { Box, Flex, Grid } from 'components/box';
import { HeaderContext, HeaderContextType } from 'contexts/header';
import { LayoutContext } from 'contexts/layout';
import { useRouter } from 'next/router';
import { Text } from 'components/typography';
import { css } from '@emotion/react';
import { useSessionExpiry } from 'hooks/customer';
import { useContext, useState } from 'react';
import Toaster from 'components/_global/header/toaster';
import StickyMessage from 'components/sticky-message';
import { Category, StickyMessageInterface } from 'types/types';
import { ClientOnly } from 'components/_shared/widgets/render-control';
import DismissableMessages from 'components/dismissable-messages';
import StickyMessageLoader from 'components/_global/header/sticky-message-loader';
import AppDownloadBanner from 'components/app-download-banner';
import NewsLetterSubscribeForm from 'components/newsletter/subscribe-form';
import dynamic from 'next/dynamic';
import resourceUrl from '../../../util/make-url';
import { useVodapayActive } from 'hooks/app';
import { AlgoliaIndexEnum, HeaderDesignEnum } from 'types/interface';
import TopBar from 'components/_global/header/top-bar';
import config from '../../../util/load-config';

const Modal = dynamic(
  () => import('components/_shared/widgets/modal/with-close')
);

const NewsLetterModal = ({
  close,
  isOpen,
}: {
  close: () => void;
  isOpen: boolean;
}) => (
  <Modal
    close={close}
    isOpen={isOpen}
    iconColor="darkishGrey"
    iconSize={20}
    size="small"
    padding={0}
    removeContentPadding
  >
    <Grid gridTemplateColumns={['1fr', '1fr 1fr', '4fr 3fr']} gridColumnGap={0}>
      <Flex
        px="5px"
        py="14px"
        flexDirection="column"
        borderTopLeftRadius={[0, '8px']}
        borderBottomLeftRadius={[0, '8px']}
        justifyContent="center"
        css={theme => css`
          background-image: linear-gradient(
            to right,
            ${theme.colors.turquoise},
            ${theme.colors.darkTurquoise}
          );
        `}
      >
        <img
          src={resourceUrl('odo/newsletter-subscription-copy.png', {
            width: 600,
            isAssetUrl: true,
          })}
          alt="Sign up to our newsletter and get R59 free shipping on our unbeatable daily deals!"
          css={css`
            width: 100%;
          `}
        />
        <img
          src={resourceUrl('odo/newsletter-subscription-products.png', {
            width: 600,
            isAssetUrl: true,
          })}
          alt="Products - Ts and Cs apply"
          css={css`
            width: 100%;
          `}
        />
      </Flex>

      <NewsLetterSubscribeForm
        gridColumnLayout={{ mobile: '1fr', desktop: '1fr' }}
        subscriptionComplete={close}
        signupLocation="HEADER"
        buttonColor="lime"
        buttonText="Sign me up!"
      />
    </Grid>
  </Modal>
);

const defaultMessageColor = 'lightBlue';
const checkoutMessageColor = 'lightOrange';

const Header = ({
  messages,
  selectedTopTab,
  hideClock,
  showAdoHeader,
  searchIndexName,
  headerTaglineText,
  headerTaglineTitle,
  categories,
  disableHeaderShrinking,
}: {
  messages?: StickyMessageInterface[];
  selectedTopTab: string | null;
  hideClock?: boolean;
  showAdoHeader?: boolean;
  searchIndexName?: AlgoliaIndexEnum | string;
  headerTaglineTitle?: string;
  headerTaglineText?: string;
  categories?: Category[];
  disableHeaderShrinking?: boolean;
}) => {
  useSessionExpiry();

  const isVodapayActive = useVodapayActive();

  const { pathname } = useRouter();
  const isHomePage = pathname === '/';

  const [stickyMessages, setStickyMessages] = useState<
    StickyMessageInterface[]
  >(messages || []);

  const HeaderContextValues: HeaderContextType = {
    stickyMessages,
    setStickyMessages,
  };

  const { newsletterModalIsOpen, setNewsletterModalIsOpen } = useContext(
    LayoutContext
  );

  return (
    <HeaderContext.Provider value={HeaderContextValues}>
      <NewsLetterModal
        close={() => setNewsletterModalIsOpen(false)}
        isOpen={newsletterModalIsOpen}
      />

      <StickyMessageLoader />

      <Box
        css={css`
          position: fixed;
          top: 0;
          z-index: 210;
          width: 100%;
        `}
      >
        {!isVodapayActive && (
          <ClientOnly>
            <AppDownloadBanner />
          </ClientOnly>
        )}

        <TopBar
          searchIndexName={searchIndexName}
          selectedTopTab={selectedTopTab}
          hideClock={hideClock}
          showAdoHeader={showAdoHeader}
          headerTaglineText={headerTaglineText}
          headerTaglineTitle={headerTaglineTitle}
          categories={categories}
          disableHeaderShrinking={disableHeaderShrinking}
        />
      </Box>

      <Box
        className="spacer"
        height={
          // NOTE: these are hardcoded heights based on design,
          // will need to updated manually if the design height changes
          config.featureFlags.headerDesign === HeaderDesignEnum.Sleek
            ? ['75px', '85px']
            : ['40px', null, '108px']
        }
      />

      {stickyMessages.length > 0 && (
        <Box
          width="100%"
          backgroundColor={
            pathname === '/checkout'
              ? checkoutMessageColor
              : defaultMessageColor
          }
        >
          <StickyMessage
            messages={stickyMessages}
            path={pathname}
            isDismissible={isHomePage}
          />
        </Box>
      )}
    </HeaderContext.Provider>
  );
};

export default Header;
