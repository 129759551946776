/* eslint-disable @typescript-eslint/no-explicit-any */
export const BASE_FONT_SIZE = 14;

const pxToEm = px => `${px / BASE_FONT_SIZE}em`;
const pxToRem = px => `${px / BASE_FONT_SIZE}rem`;
const toPx = px => `${px}px`;

const greys = {
  1: '#5f6971',
  2: '#747e86',
  3: '#99abb9',
  4: '#b8c4cd',
  5: '#d4dce2',
  6: '#eff1f3',
  7: '#f6f8fa',
};

export const colorPalette = {
  blue: '#0093d0',
  lightBlue: '#e5f4fa',
  aqua: '#05be99',
  pink: '#e50e62',
  brightPink: '#FC0067',
  lightPink: '#fdecf3',
  orange: '#f36e21',
  lightOrange: '#fdf0e8',
  lime: '#b8d72f',
  lightLime: '#F7FBEA',
  darkLime: '#8ECC2D',
  yellow: '#ffc222',
  darkYellow: '#EAA900',
  teal: '#00b1b0',
  purple: '#ba1c8b',
  black: '#444444',
  blacker: '#272727',
  shopGrey: '#f2f2f2',
  lightestGrey: '#f6f8fa',
  lightGrey: '#eff1f3',
  lightishGrey: '#dfe5eb',
  mediumGrey: '#d4dce2',
  darkishGrey: '#99abb9',
  darkGrey: '#747e86',
  darkestGrey: '#5f6971',
  turquoise: '#00B1B0',
  darkTurquoise: '#00989A',
  white: '#fff',
  offWhite: '#f6f8fa',
  blueGrey: '#e3e8ec',
};

export const colors = {
  highlightedBackground: colorPalette.blueGrey,
  background: colorPalette.white,
  iconColor: colorPalette.white,
  cartBubble: colorPalette.darkishGrey,
  callOutBlue: colorPalette.blue,
  callOutYellow: colorPalette.yellow,
  text: colorPalette.black,
  subtleText: colorPalette.darkGrey,
  headerPrimary: colorPalette.blue,
  headerSecondary: colorPalette.pink,
  primary: colorPalette.blue,
  secondary: colorPalette.darkLime,
  success: colorPalette.darkLime,
  successBackground: colorPalette.lightLime,
  danger: colorPalette.pink,
  warning: colorPalette.orange,
  info: colorPalette.blue,
  logo: colorPalette.blue,
  link: colorPalette.blue,
  information: colorPalette.blue,
  accent: colorPalette.pink,
  menuDark: colorPalette.black,
  borderColor: colorPalette.mediumGrey,
  labelColor: colorPalette.darkGrey,
  facebookLight: '#4267b2',
  facebookDark: '#365899',
  googleLight: '#fff',
  vodapayRed: '#E60000',
  pillRed: '#e30f13',
  pastelBackground: colorPalette.lightBlue,
  pastelText: colorPalette.blue,
  transparent: '#FFFFFF00',
  footerDark: '#181818',
  overlayBackground: 'rgba(95, 105, 113, 0.4)',
  skeletonBackground: colorPalette.lightishGrey,
  ...colorPalette,
  greys,
  turquoise: colorPalette.turquoise,
  darkTurquoise: colorPalette.darkTurquoise,
};

export const gradientConfettiPalette = {
  default: {
    start: '#0F9EE5',
    middle: '#0078BB',
    end: '#014595',
    confetti: '#014595',
  },
  blue: {
    start: '#0F9EE5',
    middle: '#0078BB',
    end: '#014595',
    confetti: '#014595',
  },
  teal: {
    start: '#0DCDCC',
    middle: '#00B1B0',
    end: '#05848C',
    confetti: '#05848C',
  },
  lime: {
    start: '#B8D72F',
    middle: '#8BC52F',
    end: '#67BB0F',
    confetti: '#55AE09',
  },
  pink: {
    start: '#FF4776',
    middle: '#FA3473',
    end: '#C3044F',
    confetti: '#C3044F',
  },
  purple: {
    start: '#EB29B1',
    middle: '#B51399',
    end: '#6B0684',
    confetti: '#5E0574',
  },
  orange: {
    start: '#FFC225',
    middle: '#F99824',
    end: '#F36E24',
    confetti: '#F36E21',
  },
};

type FontSizeType = {
  xs: string;
  s: string;
  r: string;
  regular: string;
  m: string;
  l: string;
  xl: string;
  x2l: string;
  x3l: string;
  x4l: string;
  title1: string;
  title2: string;
  title3: string;
  title4: string;
  title5: string;
} & string[];

/**
 * The font sizes available below are part of our typescale for the entire site.
 *
 * For a better understanding of typescales:
 * @see http://spencermortensen.com/articles/typographic-scale/
 *
 * Currently we are using the scale: major Third
 * @see https://type-scale.com/
 *
 * To add a larger or smaller font size:
 * - Follow the above link
 * - Select major third typescale
 * - See what the new size would be if you went bigger/smaller
 * - Add our new size below
 *
 * *NB*: if you're adding a smaller size, you will need to find ALL usages
 * of theme.fontSizes, and increment the index
 */

const TYPESCALE = 1.25;
const TYPESCALE_START = -2;
const FONT_SIZES = 11;

const getPxScaleValue = scaleIdx =>
  BASE_FONT_SIZE * Math.pow(TYPESCALE, scaleIdx);

const fontSizes: FontSizeType = Array(FONT_SIZES)
  .fill(0)
  .map((_, idx) => idx + TYPESCALE_START)
  .map(getPxScaleValue)
  .map(Math.round) // Fonts render better when they don't span pixel boundaries
  .map(pxToRem) as any;

// Aliases
fontSizes['xs'] = fontSizes[0];
fontSizes['s'] = fontSizes[1];
fontSizes['r'] = fontSizes[2];
fontSizes['regular'] = fontSizes[2];
fontSizes['m'] = fontSizes[3];
fontSizes['l'] = fontSizes[4];
fontSizes['xl'] = fontSizes[5];
fontSizes['x2l'] = fontSizes[6];
fontSizes['x3l'] = fontSizes[7];
fontSizes['x4l'] = fontSizes[8];

fontSizes['title1'] = fontSizes[7];
fontSizes['title2'] = fontSizes[6];
fontSizes['title3'] = fontSizes[5];
fontSizes['title4'] = fontSizes[4];
fontSizes['title5'] = fontSizes[3];

type BreakpointsType = {
  mobile: string;
  tablet: string;
  desktop: string;
} & string[];

export const breakpointsPx = [768, 1024, 1350];
// Why EMs and not REMs: https://zellwk.com/blog/media-query-units/
const breakpoints: BreakpointsType = breakpointsPx.map(v => `${v}px`) as any;

// Aliases
breakpoints['mobile'] = breakpoints[0];
breakpoints['tablet'] = breakpoints[1];
breakpoints['desktop'] = breakpoints[2];

const theme = {
  name: 'onedayonly',
  breakpoints,
  mediaQueries: {
    tinyScreenOnly: `screen and (max-width: 359px)`,
    mobileOnly: `screen and (max-width: ${toPx(breakpointsPx[0] - 1)})`,
    tabletOnly: `screen and (min-width: ${toPx(
      breakpointsPx[0]
    )}) and (max-width: ${toPx(breakpointsPx[1] - 1)})`,
    desktopOnly: `screen and (min-width: ${toPx(breakpoints[1])})`,
    tabletUp: `screen and (min-width: ${toPx(breakpointsPx[0])})`,
    desktopUp: `screen and (min-width: ${toPx(breakpointsPx[1])})`,
    mobileDown: `screen and (max-width: ${toPx(breakpointsPx[0] - 1)})`,
    tabletDown: `screen and (max-width: ${toPx(breakpointsPx[1] - 1)})`,
    // commenting out for now so that it doesn't get used as it can cause issues
    // FIXME: get a better condition here
    // desktopDown: `screen and (max-width: ${toPx(breakpointsPx[2] - 1)})`,
  },
  colors,
  greys,
  fonts: {
    body: "'Open Sans', sans-serif",
    header: "'Montserrat', sans-serif",
  },
  radii: [0, 4, 8, 12],
  shadows: {
    orderImages: '0px 0px 1px 1px rgba(58,63,71,0.15)',
    orderImagesHover: '0px 0px 3px 1px rgba(58,63,71,0.2)',
    block: '0 2px 3px rgba(58, 63, 71, 0.2)',
    small: '0 1px 1px rgba(58, 63, 71, 0.1)',
    medium: '0 1px 10px rgba(58, 63, 71, 0.2)',
    large: '0 1px 15px rgba(58, 63, 71, 0.3)',
    extraLarge: '0 10px 50px rgba(58, 63, 71, 0.07)',
    cards: '0 0 7px 0 rgba(58, 63, 71, 0.15)',
    products: '0 5px 30px 0 rgba(58, 63, 71, 0.1)',
    productsHover: '1px 3px 20px 0 rgba(58, 63, 71, 0.13)',
    buttons: '0 0 7px 0 rgba(58, 63, 71, 0.15)',
    formControl: 'rgba(27, 31, 35, 0.075) 0px 1px 2px inset',
    formControlFocus: 'rgba(3, 102, 214, 0.3) 0px 0px 0px 0.2em',
    authButton: '0px 1px 4px rgba(0, 0, 0, 0.25)',
  },
  fontWeights: {
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
    bolder: 800,
    extraBold: 900,
  },
  iconSize: {
    small: '8px',
    medium: '16px',
    large: '26px',
    xLarge: '32px',
    panel: '45px',
  },
  space: [0, 4, 8, 16, 32, 64, 128, 256],
  transitions: {
    fade: '0.2s ease',
  },
  fontSizes,
  maxGridWidth: 1200,
  maxHeaderWidth: 1200,
  extendedMaxWidth: 1400,
  gradientConfettiPalette,
};

Object.freeze(theme);

export default theme;
