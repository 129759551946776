import { css } from '@emotion/react';
import { useEffect, useRef, ReactNode } from 'react';

const OutsideAlerter = ({
  children,
  callback,
  disabled,
}: {
  children: ReactNode;
  callback: () => void | undefined;
  disabled?: boolean;
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = event => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        callback();
      }
    };
    if (!disabled) {
      document.addEventListener('mousedown', handleClickOutside);
      document.addEventListener('touchstart', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
        document.removeEventListener('touchstart', handleClickOutside);
      };
    }
  }, [wrapperRef, callback, disabled]);

  return (
    <div
      ref={wrapperRef}
      css={css`
        height: 100%;
        width: 100%;
      `}
    >
      {children}
    </div>
  );
};

export default OutsideAlerter;
