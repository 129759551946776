/**
 * @see https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtm
 */
import {
  Cart,
  Customer,
  CustomerOrder,
  CustomerOrderItem,
  ProductInterface,
  SelectedCustomizableOptionValueInterface,
  SimpleCartItem,
} from 'types/types';
import config from './load-config';
import { isBrowserWindowAvailable } from './is-browser-window-available';
import {
  PayloadCart,
  CheckoutCartInterface,
  CheckoutEventStep,
  EventNamesEnum,
  GTMCallbackOptions,
  PageTitleEnum,
  Payload,
  ProductPayload,
  PromotionInterface,
  UserType,
  eventToActionMap,
  CheckoutOptions,
} from 'types/third-party/gtm';
import { CURRENCY_CODE } from '../constants';

export const PAGE_TRACKING_KEY = 'OdoTrackingLocationName';

const DATA_LAYER_NAME = config.gtm.dataLayerName;
const EVENT_TIMEOUT = 2000;

const productListName = (product: ProductPayload) =>
  product?.isClearanceSale
    ? 'Clearance'
    : product?.permanentShop || 'DailyDeal';

export const setGTMPageName = (pageName: string): void => {
  isBrowserWindowAvailable() && (window[PAGE_TRACKING_KEY] = pageName);
};

const pushToDataLayer = ({
  payload,
  dataLayerName = DATA_LAYER_NAME,
  callbackOptions,
}: {
  payload: Payload;
  dataLayerName?: string;
  callbackOptions?: GTMCallbackOptions;
}): void => {
  if (!isBrowserWindowAvailable()) return;
  window[dataLayerName] = window[dataLayerName] || [];

  /**
   * clear the previous ecommerce object to avoid conflicts and duplicates
   * This is actually wrong, tested this, having a none ecommerce event being pushed to the data layer
   * after pushing an ecommerce event, will keep the previous ecommerce event in the data layer for the
   * new event, so best to clear it out. Also checked the actual payload of the non-ecommerce event and
   * it has no ecommerce object, which means GTM just persists the last ecommerce object until it gets
   * overriden by a new ecommerce object. So best to have an ecommerce: null object in GTM than the
   * completely incorrect ecommerce object.
   * @see https://developers.google.com/analytics/devguides/collection/ua/gtm/enhanced-ecommerce#clear-ecommerce
   */
  window[dataLayerName].push({ ecommerce: null });

  if (
    (callbackOptions && callbackOptions.yieldCallbackToGTM) ||
    !callbackOptions
  ) {
    const trackingData: Payload = { ...payload };
    if (callbackOptions) {
      trackingData.eventCallback = callbackOptions.callback;
      trackingData.eventTimeout = callbackOptions.eventTimeout;
    }

    window[dataLayerName].push(trackingData);
  } else {
    window[dataLayerName].push(payload);

    if (callbackOptions.callback) {
      const timeout = setTimeout(() => {
        callbackOptions.callback?.apply(null);
        clearTimeout(timeout);
      }, 1000);
    }
  }
};

export const trackBackToHomePageClick = () => {
  try {
    pushToDataLayer({
      payload: { event: EventNamesEnum.backToHomePageFromPermShop },
    });
  } catch (error) {
    console.error(`GTM - Track back to homepage click: ${error}`);
  }
};

// TODO: seems unused, lets confirm with biz and remove eventually, but keeping for now.
const trackPromotionImpression = (promotion: PromotionInterface): void => {
  try {
    pushToDataLayer({
      payload: {
        event: EventNamesEnum.promotionClick,
        ecommerce: {
          currency: CURRENCY_CODE,
          promoView: {
            promotions: [promotion],
          },
        },
      },
    });
  } catch (error) {
    console.error(`GTM - Track promotion impression: ${error}`);
  }
};

export const trackProductDetailImpression = (product: ProductPayload): void => {
  try {
    !!product &&
      pushToDataLayer({
        payload: {
          event: EventNamesEnum.productImpression,
          ecommerce: {
            items: [{ ...product, item_list_name: productListName(product) }],
          },
        },
      });
  } catch (error) {
    console.error(`GTM - Track product impression: ${error}`);
  }
};

const transformCustomizableOptions = (
  options: SelectedCustomizableOptionValueInterface[]
) => {
  const returnString = options
    .map(
      ({ label, value }) =>
        `${label.replace(/ /g, '-')} ${value.replace(/ /g, '-')}`
    )
    .join(' ')
    .replace(/ /g, '_')
    .toLowerCase();

  return returnString;
};

export const trackCheckoutEvent = ({
  cart,
  stepNumber,
  event,
  user,
  callback,
  getGTMResponseBeforeFiringCallback = false,
  checkoutOptions,
}: {
  cart?: CheckoutCartInterface;
  stepNumber?: CheckoutEventStep;
  event: EventNamesEnum;
  user?: Customer;
  callback?: () => void;
  getGTMResponseBeforeFiringCallback?: boolean;
  checkoutOptions?: CheckoutOptions;
}): void => {
  try {
    const optionsPayload = { step: stepNumber, ...checkoutOptions };

    const paymentMethod = cart?.paymentMethods?.find(
      method => method.isSelected
    );

    pushToDataLayer({
      payload: {
        event,
        ecommerce: {
          currency: CURRENCY_CODE,
          ...(checkoutOptions || stepNumber
            ? { actionField: optionsPayload }
            : {}),
          ...(cart
            ? {
                value: cart.totals?.grandTotal?.value,
                ...(cart.purchase ? { purchase: cart.purchase } : {}),
                ...(cart.remove ? { remove: cart.remove } : {}),

                ...(paymentMethod ? { paymentMethod } : {}),
                items: cart.items,
              }
            : {}),
        },
        ...(user
          ? {
              user: {
                userID: user.id,
                userStatus: 'signedIn',
                subscribed: user.isSubscribed,
              },
            }
          : {
              user: {
                userID: undefined,
                userStatus: 'signedOut',
                subscribed: undefined,
              },
            }),
      },
      ...(callback
        ? {
            callbackOptions: {
              callback,
              yieldCallbackToGTM: getGTMResponseBeforeFiringCallback,
              eventTimeout: EVENT_TIMEOUT,
            },
          }
        : {}),
    });
  } catch (error) {
    console.error(`GTM - Track checkout: ${error}`);
  }
};

const transformFormattedCurrencyToNumericValue = (formattedValue: string) =>
  parseInt(formattedValue.slice(1).replaceAll(',', ''), 10);

export const trackOrderConfirmationEvent = ({
  items,
  orderInfo,
}: {
  items: ProductPayload[];
  orderInfo: CustomerOrder;
}): void => {
  try {
    pushToDataLayer({
      payload: {
        event: EventNamesEnum.successPurchase,
        ecommerce: {
          currency: CURRENCY_CODE,
          transaction_id: orderInfo.id,
          ...(orderInfo.totals.grandTotal.formattedValue
            ? {
                value: transformFormattedCurrencyToNumericValue(
                  orderInfo.totals.grandTotal.formattedValue
                ),
              }
            : {}),
          ...(orderInfo.totals.tax.total.formattedValue
            ? {
                tax: transformFormattedCurrencyToNumericValue(
                  orderInfo.totals.tax.total.formattedValue
                ),
              }
            : {}),
          ...(orderInfo.totals.shipping.formattedValue
            ? {
                shipping: transformFormattedCurrencyToNumericValue(
                  orderInfo.totals.shipping.formattedValue
                ),
              }
            : {}),
          items,
        },
      },
    });
  } catch (error) {
    console.error(`GTM - Track order confirmation: ${error}`);
  }
};

export const trackCartEvent = ({
  product,
  productQuantity,
  cartItems,
  event,
  user,
  callback,
  stepNumber,
  value,
  checkoutOptions,
}: {
  product?: ProductPayload;
  productQuantity?: number;
  cartItems?: ProductPayload[];
  event: EventNamesEnum;
  user?: Customer;
  callback?: () => void;
  stepNumber?: CheckoutEventStep;
  value?: number;
  /**
   * NOTE: this prop is only used on the payment/pay page.
   * that event is called "checkout". but no such event exists in GTM ref.
   * so I don't quite know what to call this, as it's actually the purchase info.
   * giving it a custom once off type for now to at least replace the "any".
   * TODO: figure out the actual type and maybe create a custom event specifically for this use-case.
   */
  checkoutOptions?: {
    transaction_id: string;
    tax: number;
    code: string;
    shipping: number;
  };
}) => {
  try {
    const actionName = eventToActionMap[event];

    if (typeof productQuantity === 'number' && product) {
      product = { ...product, quantity: productQuantity };
    }

    pushToDataLayer({
      payload: {
        event,
        ...(actionName
          ? {
              ecommerce: {
                ...(checkoutOptions
                  ? {
                      transaction_id: checkoutOptions.transaction_id,
                      tax: checkoutOptions.tax,
                      code: checkoutOptions.code,
                      shipping: checkoutOptions.shipping,
                    }
                  : {}),
                ...(value ? { value, currency: CURRENCY_CODE } : {}),
                ...(stepNumber
                  ? {
                      actionField: {
                        step: stepNumber,
                      },
                    }
                  : {}),
                items: product ? [product] : cartItems,
              },
            }
          : {}),
        ...(user
          ? {
              user: {
                userID: user.id,
                userStatus: 'signedIn',
                subscribed: user.isSubscribed,
              },
            }
          : {
              user: {
                userID: undefined,
                userStatus: 'signedOut',
                subscribed: undefined,
              },
            }),
      },
      callbackOptions: {
        callback,
        yieldCallbackToGTM: false,
        eventTimeout: EVENT_TIMEOUT,
      },
    });
  } catch (error) {
    console.error(`GTM - track cart event: ${error}`);
  }
};

export const trackProductClickEvent = ({
  product,
  callback,
  yieldCallbackToGTM = false,
}: {
  product: ProductPayload;
  callback?: () => void;
  yieldCallbackToGTM?: boolean;
}): void => {
  if (!product) return;

  try {
    pushToDataLayer({
      payload: {
        event: EventNamesEnum.productClick,
        ecommerce: {
          items: [{ ...product, item_list_name: productListName(product) }],
        },
      },
      callbackOptions: {
        callback,
        yieldCallbackToGTM,
        eventTimeout: EVENT_TIMEOUT,
      },
    });
  } catch (error) {
    console.error(`GTM - track product event: ${error}`);
  }
};

export const trackPaymentFailed = (message?: string) => {
  try {
    pushToDataLayer({
      payload: { event: EventNamesEnum.failedPurchase, reason: message },
    });
  } catch (error) {
    console.error(`GTM - track payment failed: ${error}`);
  }
};

export const trackNewsletterSubscribed = () => {
  try {
    pushToDataLayer({
      payload: {
        event: EventNamesEnum.newsletterSubscribed,
      },
    });
  } catch (error) {
    console.error(`GTM - track newsletter subscribe: ${error}`);
  }
};

const getFallbackPageTitle = (pathname: string) => {
  return pathname
    .replace('/', '')
    .replace('-', ' ')
    .replace(/(^\w{1})|(\s+\w{1})/g, (letter: string) => letter.toUpperCase());
};

export const trackAlwaysOnDataLayer = ({
  user,
  pageTitle,
}: {
  user?: Customer;
  pageTitle?: string;
}) => {
  try {
    pushToDataLayer({
      payload: {
        event: EventNamesEnum.pageLoad,
        pageLocation: window.location.href,
        pagePath: window.location.pathname,
        pageTitle:
          pageTitle ||
          PageTitleEnum[window.location.pathname] ||
          getFallbackPageTitle(window.location.pathname),
        ...(user
          ? {
              user: {
                userID: user.id,
                userStatus: user ? 'signedIn' : 'signedOut',
                subscribed: user.isSubscribed,
                ...(user.orderStats
                  ? {
                      LTV: {
                        count: user.orderStats.count,
                        total: user.orderStats.total.value,
                        average: user.orderStats.average.value,
                      },
                    }
                  : {}),
              },
            }
          : {
              user: {
                userID: undefined,
                userStatus: 'signedOut',
                subscribed: undefined,
              },
            }),
      },
    });
  } catch (error) {
    console.error(`GTM - Always on data layer: ${error}`);
  }
};

export const trackAuthentication = ({
  event,
  user,
}: {
  event: EventNamesEnum.signInSuccess | EventNamesEnum.signUpSuccess;
  user?: UserType;
}) => {
  try {
    pushToDataLayer({ payload: { event, user } });
  } catch (error) {
    console.error(`GTM - Track authentication: ${error}`);
  }
};

export const trackPaymentMethods = ({
  paymentEvent: event,
}: {
  paymentEvent: string;
}) => pushToDataLayer({ payload: { event } });

export const mapProductToPayload = (
  product: ProductInterface
): ProductPayload => ({
  item_name: product.name,
  item_id: product.realId.toString(),
  price: product.price.value,
  item_brand: product.brand,
  sku: product.sku,
  isClearanceSale: !!product.isClearanceSale,
  permanentShop: product.permanentShop?.name,
  currency: CURRENCY_CODE,
  category: (product?.topLevelCategories || []).find(Boolean)?.name,
});

export const mapCartItemToPayload = (
  cartItem: SimpleCartItem
): ProductPayload => ({
  item_name: cartItem.name,
  item_id: cartItem.product.realId.toString(),
  price: cartItem.price.value,
  item_brand: cartItem.brand,
  sku: cartItem.sku,
  isClearanceSale: !!cartItem.product.isClearanceSale,
  permanentShop: cartItem.product.permanentShop?.name,
  currency: CURRENCY_CODE,
  category: (cartItem.product?.topLevelCategories || []).find(Boolean)?.name,
  // cart specific props
  quantity: cartItem.quantity,
  ...(cartItem.customizableOptions && cartItem.customizableOptions.length > 0
    ? {
        variants: transformCustomizableOptions(cartItem.customizableOptions),
      }
    : {}),
});

export const mapCartToPayload = ({
  cart,
  stepNumber,
}: {
  cart: Cart;
  stepNumber?: CheckoutEventStep;
}): { transformedCart: PayloadCart; checkoutOptions: CheckoutOptions } => ({
  transformedCart: {
    ...cart,
    items: cart.items.map(mapCartItemToPayload),
  },
  checkoutOptions: {
    step: stepNumber,
    id: cart.id,
    coupon: cart.voucher,
    code: cart.paymentMethods?.find(method => method.isSelected)?.code,
    value: cart.totals?.grandTotal.value,
    ...(cart.totals?.tax?.total.formattedValue
      ? {
          tax: transformFormattedCurrencyToNumericValue(
            cart.totals.tax.total.formattedValue
          ),
        }
      : {}),
  },
});

// NOTE: cannot get the categories from an order as they're not available on the API
export const mapCustomerOrderItemsToPayload = (
  orderItems: CustomerOrderItem[]
): ProductPayload[] =>
  orderItems.map(orderItem => ({
    item_name: orderItem.name,
    item_id: orderItem.productRealId,
    price: orderItem.price.value,
    sku: orderItem.sku,
    item_brand: orderItem.brand,
    currency: CURRENCY_CODE,
    // order specific props
    quantity: orderItem.quantity.ordered,
    ...(orderItem.customizableOptions &&
    orderItem.customizableOptions.length > 0
      ? {
          variants: transformCustomizableOptions(orderItem.customizableOptions),
        }
      : {}),
  }));

export const mapProductToPageTitle = (product: ProductInterface) =>
  `PDP | ${product.brand} ${product.name}`;

export const trackAccount = () =>
  pushToDataLayer({ payload: { event: EventNamesEnum.account } });

export const trackChangeAddress = ({
  items,
  value,
  user,
}: {
  items?: ProductPayload[];
  value?: number;
  user?: Customer;
}) => {
  pushToDataLayer({
    payload: {
      event: EventNamesEnum.addShippingInfo,
      ecommerce: {
        currency: CURRENCY_CODE,
        value,
        items,
      },
      ...(user
        ? {
            user: {
              userID: user.id,
              userStatus: 'signedIn',
              subscribed: user.isSubscribed,
            },
          }
        : {
            user: {
              userID: undefined,
              userStatus: 'signedOut',
              subscribed: undefined,
            },
          }),
    },
  });
};

export const trackChangePaymentInfo = ({
  items,
  value,
  user,
}: {
  items?: ProductPayload[];
  value?: number;
  user?: Customer;
}) => {
  pushToDataLayer({
    payload: {
      event: EventNamesEnum.addPaymentInfo,
      ecommerce: {
        currency: CURRENCY_CODE,
        value,
        items,
      },
      ...(user
        ? {
            user: {
              userID: user.id,
              userStatus: 'signedIn',
              subscribed: user.isSubscribed,
            },
          }
        : {
            user: {
              userID: undefined,
              userStatus: 'signedOut',
              subscribed: undefined,
            },
          }),
    },
  });
};
